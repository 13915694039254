import { Box, Text, tokens } from '@kluein/klue-ui';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useCard } from 'hooks/use-card';
import {
  AnalyticsAction,
  AnalyticsCategory,
  useAnalytics,
} from 'lib/analytics';
import store from 'store';

import type { CardType } from 'api/api.types';

type EmptyDataType = {
  text?: string;
  cardId?: number;
};

const getViewContext = () => {
  const path = window.location.pathname;
  if (path.match(/^\/profile\/\d+\/battlecard\//)) {
    return 'battlecard';
  }

  if (path.match(/^\/search\//)) {
    return 'search';
  }

  return 'board';
};

const getAudienceType = (card: CardType) => {
  if (card.isDraft) return 'curators';
  if (card.allAccess) return 'everyone';
  if (card.visibilityGroups.length > 0) return 'visibility_groups';
  return 'full_access_users';
};

export default function EmptyData({
  text = 'dynamicContent.emptyData',
  cardId,
}: EmptyDataType) {
  const { logEvent } = useAnalytics();
  const { t } = useTranslation('Card');
  const { card, loadCard, failed } = useCard(cardId?.toString());

  const allFetchesCompleted = useSelector(
    store.select.dynamicData.allFetchesCompleted,
  );

  useEffect(() => {
    if (!cardId) {
      return;
    }
    if (!card && !failed) {
      return loadCard(cardId);
    }
    if (card) {
      const view = getViewContext();
      const { createdAt } = card;
      const cardCreatedDate = new Date(createdAt);
      const oneDayAgo = new Date();

      oneDayAgo.setDate(oneDayAgo.getDate() - 1);

      if (cardCreatedDate < oneDayAgo && allFetchesCompleted) {
        logEvent({
          event: {
            category: AnalyticsCategory.impression,
            action: AnalyticsAction.card,
            provider: 'sp',
          },
          data: {
            view,
            entities: ['card'],
            card: {
              id: card.id,
              dynamicBlockHasData: false,
              audiences: getAudienceType(card),
            },
          },
        });
      }
    }
  }, [logEvent, card, cardId, loadCard, failed, allFetchesCompleted]);

  return (
    <Box background={tokens.color.neutral.rosegrey.main} pad="xsmall">
      <Text size="small">
        <Trans
          i18nKey={t(text)}
          components={{
            strong: <Text fontWeight="medium" />,
          }}
        />
      </Text>
    </Box>
  );
}
