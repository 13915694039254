import { createModel } from '@rematch/core';

import { fetchUserById, fetchUsers } from 'api/endpoints/users/users';
import { mergeMapValues } from 'store/utils';

import type { UserType } from 'api/api.types';
import type { UserFilterType } from 'api/endpoints';
import type { RootModel } from 'store/models';

type UserMapType = Map<string, UserType>;
type PopulatePayload = {
  users: Record<number, UserType>;
  fetchedAllCurators?: boolean;
  fetchedAllConsumers?: boolean;
};

export type StateType = {
  byId: UserMapType;
  allIds: Set<string>;
  isLoading: boolean;
  fetchedAllCurators: boolean;
  fetchedAllConsumers: boolean;
};

export const initialState = {
  byId: new Map(),
  allIds: new Set(),
  isLoading: false,
  fetchedAllCurators: false,
  fetchedAllConsumers: false,
} as StateType;

const pendingUsers = new Map<number, Promise<UserType>>();

export const users = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (
      state: StateType,
      { users, fetchedAllCurators, fetchedAllConsumers }: PopulatePayload,
    ) => {
      return {
        ...state,
        byId: mergeMapValues(state.byId, users),
        allIds: new Set([...state.allIds, ...Object.keys(users)]),
        fetchedAllCurators: fetchedAllCurators || false,
        fetchedAllConsumers: fetchedAllConsumers || false,
        isLoading: false,
      };
    },
    setIsLoading: (state, value: boolean) => {
      return {
        ...state,
        isLoading: value,
      };
    },
    resetUsers: (state) => {
      return {
        ...state,
        byId: new Map(),
        allIds: new Set(),
      };
    },
  },
  selectors: (slice) => ({
    byId: () => slice(({ byId }) => byId),
    isLoading() {
      return slice(({ isLoading }) => isLoading);
    },
  }),
  effects: (dispatch) => ({
    async loadOrFetchUserById(id: number, rootState): Promise<UserType | null> {
      // first check if user is present in global state
      const existingUser = rootState.users.byId.get(id?.toString());
      if (existingUser?.id) {
        return existingUser;
      }

      if (!id) {
        return null;
      }

      const existingPendingUser = pendingUsers.get(id);
      if (existingPendingUser) {
        return existingPendingUser;
      }

      pendingUsers.set(
        id,
        fetchUserById({
          path: {
            id,
          },
        })
          .then(({ data }) => data)
          .finally(() => pendingUsers.delete(id)),
      );

      const user = await pendingUsers.get(id);

      if (!user?.id) {
        return null;
      }

      dispatch.users.populate({ users: { [user.id]: user } });

      return user;
    },
    async loadOrFetchAllCurators(_, rootState): Promise<void> {
      if (rootState.users.fetchedAllCurators) {
        return;
      }
      dispatch.users.setIsLoading(true);
      const { data } = await fetchUsers({
        query: {
          typeFilter: 'curators',
        },
      });
      dispatch.users.populate({ users: data, fetchedAllCurators: true });
    },
    async loadOrFetchAllConsumers(_, rootState): Promise<void> {
      if (rootState.users.fetchedAllConsumers) {
        return;
      }
      dispatch.users.setIsLoading(true);
      const { data } = await fetchUsers({
        query: {
          page: 1,
          limit: 10,
          typeFilter: 'consumers',
          order: 'alpha',
        },
      });
      dispatch.users.populate({ users: data, fetchedAllConsumers: true });
    },
    async fetchUserByName(
      {
        name,
        typeFilter,
        limit,
      }: { name: string; typeFilter: UserFilterType; limit: number },
      rootState,
    ): Promise<void> {
      if (name) {
        dispatch.users.setIsLoading(true);
        const { data } = await fetchUsers({
          query: { query: name, typeFilter, order: 'alpha', limit },
        });
        dispatch.users.populate({ users: data });
      }
    },
  }),
});
