import queryString from 'query-string';

import { api } from '../api';

import type {
  GetHighlightContentRequestQuery,
  GetHighlightContentRequestResponse,
  GetMonitorByUrlQuery,
  GetMonitorHighlightsRequestQuery,
  GetMonitorHighlightsResponse,
  GetMonitorRequestQuery,
  GetMonitorResponse,
  GetMonitorsRequestQuery,
  GetMonitorsResponse,
  DeleteMonitorRequestQuery,
  DeleteMonitorResponse,
  PostMonitorRequestQuery,
  PostMonitorResponse,
} from 'api/endpoints';

export const {
  useGetMonitorByUrlQuery,
  useGetMonitorQuery,
  useGetMonitorsQuery,
  useDeleteMonitorMutation,
  useGetMonitorHighlightsQuery,
  useGetMonitorHighlightQuery,
  usePostMonitorMutation,
} = api.injectEndpoints({
  endpoints: (build) => ({
    getMonitors: build.query<GetMonitorsResponse, GetMonitorsRequestQuery>({
      query: (params) => {
        return `monitors.json?${queryString.stringify(
          { ...params },
          {
            arrayFormat: 'bracket',
          },
        )}`;
      },
    }),
    getMonitor: build.query<GetMonitorResponse, GetMonitorRequestQuery>({
      query: (params) => {
        return `monitor/${params.path.monitorId}.json?${queryString.stringify(
          { ...params.query },
          {
            arrayFormat: 'bracket',
          },
        )}`;
      },
    }),
    deleteMonitor: build.mutation<
      DeleteMonitorResponse,
      DeleteMonitorRequestQuery
    >({
      query: (id) => ({
        url: `monitor/${id}.json`,
        method: 'DELETE',
      }),
    }),
    postMonitor: build.mutation<PostMonitorResponse, PostMonitorRequestQuery>({
      query: (body) => ({
        url: 'monitor.json',
        method: 'POST',
        body,
      }),
    }),
    getMonitorHighlights: build.query<
      GetMonitorHighlightsResponse,
      GetMonitorHighlightsRequestQuery
    >({
      query: (params) => {
        return `monitor/${
          params.path.monitorId
        }/highlight_contents.json?${queryString.stringify(
          { ...params.query },
          {
            arrayFormat: 'bracket',
          },
        )}`;
      },
    }),
    getMonitorHighlight: build.query<
      GetHighlightContentRequestResponse,
      GetHighlightContentRequestQuery
    >({
      query: (params) => {
        return `highlight_contents/${params.path.highlightId}.json`;
      },
    }),
    getMonitorByUrl: build.query<GetMonitorResponse, GetMonitorByUrlQuery>({
      query: (params) => {
        return `monitor.json?${queryString.stringify(
          { ...params },
          {
            arrayFormat: 'bracket',
          },
        )}`;
      },
    }),
  }),
});
