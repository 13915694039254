import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'contexts/auth';
import { APP_V1_BASEURL } from 'lib/urls';

import type { RootState } from 'store';

export const useRedirect = () => {
  const { isCurator, isNewCardEditorEnabled } = useAuth();
  const navigate = useNavigate();
  const currentProfileId = useSelector<RootState, number | undefined>(
    (state) => state.profiles.currentProfileId,
  );

  const redirectToV1 = () => {
    return window.open(
      `${APP_V1_BASEURL}${window.location.pathname}${window.location.search}`,
      '_self',
    );
  };

  const redirectToProfile = (profileId?: number, boardId?: number) => {
    const id = profileId ?? currentProfileId;

    if (isCurator) {
      redirectToV1Profile(id);
    }

    return navigate(`/profile/${id}${boardId ? `?lanes=${boardId}` : ''}`);
  };

  const redirectToBattlecards = (profileId?: number) => {
    const id = profileId ?? currentProfileId;
    if (isCurator) {
      return window.open(
        `${APP_V1_BASEURL}/profile/${id}/battlecard/view`,
        '_self',
      );
    }

    return navigate(`/profile/${profileId}/battlecard/view`);
  };

  const redirectToLaneCardWithHighlight = (
    {
      profileId,
      laneId,
      cardId,
    }: {
      profileId: number;
      laneId: number;
      cardId: number;
    },
    options?: { target?: string },
  ) => {
    const { target = '_self' } = options ?? {};
    return window.open(
      `${APP_V1_BASEURL}/profile/${profileId}/edit#${laneId}/${cardId}`,
      target,
    );
  };

  const redirectToCard = ({
    profileId,
    cardId,
  }: {
    profileId: number;
    cardId: number;
  }) => {
    return window.open(
      `${APP_V1_BASEURL}/profile/${profileId}/edit/card/${cardId}`,
    );
  };

  const redirectToCardEditor = ({
    profileId,
    cardId,
  }: {
    profileId: number;
    cardId: number;
  }) => {
    const cardEditorPath = `/profile/${profileId}/edit/card/${cardId}/edit`;

    if (isNewCardEditorEnabled) {
      return navigate(cardEditorPath);
    }
    return window.open(`${APP_V1_BASEURL}${cardEditorPath}`);
  };

  const redirectToV1Profile = (profileId?: number) => {
    const id = profileId ?? currentProfileId;
    return window.open(`${APP_V1_BASEURL}/profile/${id}`, '_self');
  };

  const redirectToV1Search = (queryParams?: string) => {
    return window.open(`${APP_V1_BASEURL}/search${queryParams || ''}`, '_self');
  };

  const redirectToV1Settings = () =>
    window.open(`${APP_V1_BASEURL}/settings/alerts`, '_self');

  const redirectToV1Admin = () =>
    window.open(`${APP_V1_BASEURL}/admin`, '_self');

  const redirectToBattlecard = (
    profileId?: number,
    battlecardId?: number,
    options?: { target?: string },
  ) => {
    const { target = '_self' } = options ?? {};
    const id = profileId ?? currentProfileId;
    if (isCurator) {
      return window.open(
        `${APP_V1_BASEURL}/profile/${id}/battlecard/view/${battlecardId}`,
        target,
      );
    }

    return navigate(`/profile/${id}/battlecard/view/${battlecardId}`);
  };

  const redirectToBattlecardsCard = (
    currentProfileId?: number,
    currentBattlecardId?: number,
    queryCardId?: number,
  ) => {
    if (isCurator) {
      return window.open(
        `${APP_V1_BASEURL}/profile/${currentProfileId}/battlecard/view/${currentBattlecardId}&cardId=${queryCardId}`,
        '_self',
      );
    }
    return navigate(
      `/profile/${currentProfileId}/battlecard/view/${currentBattlecardId}?cardId=${queryCardId}`,
    );
  };

  const redirectToNews = (profileId?: number) => {
    return navigate(`/profile/${profileId}/news`);
  };

  return {
    redirectToProfile,
    redirectToBattlecards,
    redirectToLaneCardWithHighlight,
    redirectToCard,
    redirectToCardEditor,
    redirectToV1Profile,
    redirectToV1Search,
    redirectToV1,
    redirectToV1Settings,
    redirectToV1Admin,
    redirectToBattlecard,
    redirectToBattlecardsCard,
    redirectToNews,
  };
};
