type Props = {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
};

function TelegramLogoIcon({ width = '16', height = '16', style }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M15.305 1.63688C15.2275 1.56999 15.1333 1.5255 15.0324 1.50821C14.9315 1.49092 14.8278 1.50148 14.7325 1.53876L2.06622 6.49563C1.88672 6.56543 1.73474 6.69166 1.63318 6.85531C1.53162 7.01896 1.48596 7.21118 1.5031 7.40302C1.52023 7.59486 1.59922 7.77594 1.72817 7.919C1.85712 8.06207 2.02906 8.15937 2.2181 8.19626L5.49997 8.84063V12.5C5.49933 12.6993 5.55856 12.8943 5.67 13.0595C5.78144 13.2248 5.93994 13.3528 6.12497 13.4269C6.30972 13.5023 6.51289 13.5204 6.70806 13.4789C6.90324 13.4373 7.08139 13.3379 7.21935 13.1938L8.80185 11.5525L11.3125 13.75C11.4936 13.9107 11.7272 13.9996 11.9693 14C12.0755 13.9999 12.1809 13.9833 12.2818 13.9506C12.4468 13.8983 12.5952 13.8036 12.7122 13.6761C12.8292 13.5486 12.9108 13.3926 12.9487 13.2238L15.4856 2.18751C15.5083 2.08798 15.5035 1.98414 15.4717 1.88715C15.4398 1.79015 15.3822 1.70363 15.305 1.63688ZM11.4837 3.88688L5.88435 7.89688L2.78435 7.28876L11.4837 3.88688ZM6.49997 12.5V9.53251L8.04935 10.8913L6.49997 12.5ZM11.9706 13L6.8031 8.46876L14.2406 3.13813L11.9706 13Z"
        fill="#343330"
      />
    </svg>
  );
}

export default TelegramLogoIcon;
