type Props = {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
};

function CardsIcon({ width = '16', height = '16', style }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M12 4.5H3C2.73478 4.5 2.48043 4.60536 2.29289 4.79289C2.10536 4.98043 2 5.23478 2 5.5V12.5C2 12.7652 2.10536 13.0196 2.29289 13.2071C2.48043 13.3946 2.73478 13.5 3 13.5H12C12.2652 13.5 12.5196 13.3946 12.7071 13.2071C12.8946 13.0196 13 12.7652 13 12.5V5.5C13 5.23478 12.8946 4.98043 12.7071 4.79289C12.5196 4.60536 12.2652 4.5 12 4.5ZM12 12.5H3V5.5H12V12.5ZM15 3.5V11C15 11.1326 14.9473 11.2598 14.8536 11.3536C14.7598 11.4473 14.6326 11.5 14.5 11.5C14.3674 11.5 14.2402 11.4473 14.1464 11.3536C14.0527 11.2598 14 11.1326 14 11V3.5H4.5C4.36739 3.5 4.24021 3.44732 4.14645 3.35355C4.05268 3.25979 4 3.13261 4 3C4 2.86739 4.05268 2.74021 4.14645 2.64645C4.24021 2.55268 4.36739 2.5 4.5 2.5H14C14.2652 2.5 14.5196 2.60536 14.7071 2.79289C14.8946 2.98043 15 3.23478 15 3.5Z"
        fill="#343330"
      />
    </svg>
  );
}

export default CardsIcon;
