/* eslint-disable no-console */
import { Button } from '@kluein/klue-ui';
import {
  isBefore,
  differenceInMinutes,
  differenceInSeconds,
  parseISO,
  formatDistanceToNow,
  isValid,
} from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { NOTIFICATIONS_FETCH_INTERVAL } from 'api/constants';
import { fetchNotificationsCounts } from 'api/endpoints/notifications';
import { fetchUserMe } from 'api/endpoints/users';
import { useAppUIContext } from 'contexts/ui';
import {
  ONE_SECOND_IN_MS,
  ONE_MINUTE_IN_MS,
  ONE_MINUTE_IN_SECONDS,
  TIMEOUT_MINIMUM_MINUTES_REMAINING_TO_START_SILENT_COUNTER,
  TIMEOUT_MINUTES_REMAINING_TO_SHOW_ALERT,
} from 'lib/constants';
import { APP_V1_BASEURL } from 'lib/urls';
import store, { type Dispatch } from 'store';

import type { ToastPropType } from '@kluein/klue-ui';

export enum Timeouts {
  idle = 'idle',
  absolute = 'absolute',
}

export type TimeoutType = {
  idle: Date | number | null;
  absolute: Date | number | null;
};

const initialState = {
  idle: null,
  absolute: null,
};

const useTimeoutSessionManager = ({
  onSignOut,
}: {
  onSignOut: VoidFunction;
}) => {
  const [timeoutsDate, setTimeoutsDate] = useState<TimeoutType>(initialState);
  const { toast } = useAppUIContext();
  const { t } = useTranslation(['Session']);
  const dispatch = useDispatch<Dispatch>();

  const getUser = () => store.getState().auth.user;

  let timerInterval: NodeJS.Timeout;

  const fetchAndUpdateTimeouts = async () => {
    try {
      if (isEmpty(getUser())) {
        clearInterval(timerInterval);
        return;
      }

      const { data } = await fetchNotificationsCounts({
        query: { ownProfiles: true },
      });
      const absolute = parseISO(data.sessionAbsoluteTimeoutAt as string);
      const idle = parseISO(data.sessionIdleTimeoutAt as string);

      dispatch.auth.setNotificationsCounts(data);

      setTimeoutsDate({
        absolute: isValid(absolute) ? absolute : null,
        idle: isValid(idle) ? idle : null,
      });
    } catch (err: any) {
      console.error(
        err.data?.error ||
          `Failed fetching notifications counts: ${JSON.stringify(err)}`,
      );
    } finally {
      clearInterval(timerInterval);
    }
  };

  const refreshIdleTimeout = async () => {
    await fetchUserMe();
    toast.close();

    fetchAndUpdateTimeouts();
  };

  const forceSignOut = () => {
    onSignOut();
    clearInterval(timerInterval);
    const redirect = encodeURIComponent(window.location.href);
    window.location.href = `${APP_V1_BASEURL}/account/signin?redirectTo=${redirect}`;
  };

  const startSessionTimer = () => {
    const { idle, absolute } = timeoutsDate;
    const isAllDatesInvalid = !idle && !absolute;
    const isAllDatesValid = !!idle && !!absolute;

    if (isAllDatesInvalid) {
      return;
    }

    const now = new Date();

    let whichTimeoutExpiresFirst;

    if (isAllDatesValid) {
      whichTimeoutExpiresFirst = isBefore(idle as Date, absolute as Date)
        ? Timeouts.idle
        : Timeouts.absolute;
    } else {
      whichTimeoutExpiresFirst = isValid(idle)
        ? Timeouts.idle
        : Timeouts.absolute;
    }

    if (!!whichTimeoutExpiresFirst) {
      const remainingMinutes = differenceInMinutes(
        timeoutsDate[whichTimeoutExpiresFirst] as Date,
        now,
      );

      const remainingSeconds = differenceInSeconds(
        timeoutsDate[whichTimeoutExpiresFirst] as Date,
        now,
      );

      const expiresAtSeconds = 0;

      const shouldNotStartCounter =
        remainingMinutes >
        TIMEOUT_MINIMUM_MINUTES_REMAINING_TO_START_SILENT_COUNTER;

      if (shouldNotStartCounter) {
        return;
      }

      const isExpired = remainingSeconds <= expiresAtSeconds;

      if (isExpired) {
        if (import.meta.env.DEV) {
          console.log(
            '%c [TIMEOUT SESSION] SESSION EXPIRED!',
            'background: red; color: white',
          );
        }

        toast.close();
        forceSignOut();
        return;
      }

      const shouldShowAlert =
        remainingMinutes < TIMEOUT_MINUTES_REMAINING_TO_SHOW_ALERT;
      const isLessThanOneMinute = remainingSeconds < ONE_MINUTE_IN_SECONDS;
      const intervalTimer = isLessThanOneMinute
        ? ONE_SECOND_IN_MS
        : ONE_MINUTE_IN_MS;

      if (shouldShowAlert) {
        const textToShow = formatDistanceToNow(
          timeoutsDate[whichTimeoutExpiresFirst] as Date,
          {
            includeSeconds: true,
          },
        );

        const onClickHandler = {
          idle: refreshIdleTimeout,
          absolute: forceSignOut,
        };

        const toastData = {
          idle: {
            message: t('Session:timeout.idle.message', {
              remainingTime: textToShow,
            }),
            action: (
              <Button
                alignSelf="end"
                variant="flat-outlined"
                onClick={onClickHandler[whichTimeoutExpiresFirst]}
                label={t('Session:timeout.idle.button')}
              />
            ),
            onClose: onClickHandler[whichTimeoutExpiresFirst],
          },
          absolute: {
            message: t('Session:timeout.absolute.message', {
              remainingTime: textToShow,
            }),
            action: (
              <Button
                alignSelf="end"
                variant="flat-outlined"
                onClick={onClickHandler[whichTimeoutExpiresFirst]}
                label={t('Session:timeout.absolute.button')}
              />
            ),
            onClose: onClickHandler[whichTimeoutExpiresFirst],
          },
        } as Record<Timeouts, ToastPropType>;

        toast.open({
          title: t('Session:timeout.title'),
          ...toastData[whichTimeoutExpiresFirst],
        });

        if (import.meta.env.DEV) {
          const debuggerText = `%c [TIMEOUT SESSION] ${whichTimeoutExpiresFirst} Session will expire in ${textToShow}`;
          console.log(debuggerText, 'background: red; color: white');
        }
      }

      clearInterval(timerInterval);

      timerInterval = setTimeout(() => {
        startSessionTimer();
      }, intervalTimer);
    }
  };

  const startTimeoutSessionManager = () => {
    if (getUser()) {
      fetchAndUpdateTimeouts();
      setInterval(() => {
        fetchAndUpdateTimeouts();
      }, NOTIFICATIONS_FETCH_INTERVAL);
    }
  };

  useEffect(() => {
    if (isValid(timeoutsDate.idle) || isValid(timeoutsDate.absolute)) {
      startSessionTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutsDate]);

  return {
    startTimeoutSessionManager,
    timeoutsDate,
    setTimeoutsDate,
    fetchAndUpdateTimeouts,
  };
};

export default useTimeoutSessionManager;
