import { setEndpoint } from '../../../configure';

import type {
  AspectsParamsType,
  AspectsQueryType,
  AspectsReturnType,
  UpdateAspectReturnType,
  UpdateAspectParamsType,
  UpdateAspectPathType,
} from './aspects.type';

export const [fetchAspects] = setEndpoint<
  any,
  AspectsParamsType,
  AspectsQueryType,
  AspectsReturnType
>({
  path: '/review_insights/:supportedRivalId/aspects',
  id: 'fetchAspects',
  method: 'GET',
  namespace: 'aspects',
});

export const [updateAspect] = setEndpoint<
  UpdateAspectPathType,
  UpdateAspectParamsType,
  any,
  UpdateAspectReturnType
>({
  path: '/review_insights/:supportedRivalId/aspects/:aspectId',
  id: 'updateAspect',
  method: 'PATCH',
  namespace: 'aspects',
});
