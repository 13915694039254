import { useState, useContext, createContext, type ReactChild } from 'react';

type BreadcrumbContextType = {
  portalNode?: HTMLOListElement;
  setPortalNode(portalNode?: HTMLOListElement | null): void;
};
type BreadcrumbProviderPropsType = {
  children: ReactChild;
};

const initialValue = {
  portalNode: undefined,
  setPortalNode: () => null,
};

const Context = createContext<BreadcrumbContextType>(initialValue);

export const useBreadcrumbContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('Missing BreadcrumbProvider.');
  }

  return context;
};

export const BreadcrumbProvider = ({
  children,
}: BreadcrumbProviderPropsType) => {
  const [portalNode, setPortalNode] = useState<HTMLOListElement>();

  const breadcrumbState = {
    portalNode: portalNode,
    setPortalNode: (portalNode?: HTMLOListElement) => {
      setPortalNode(portalNode);
    },
  };

  return (
    <Context.Provider value={breadcrumbState}>{children}</Context.Provider>
  );
};
