type Props = {
  width?: string;
  height?: string;
  color?: string;
  style?: React.CSSProperties;
};

function WinLossIcon({
  width = '16',
  height = '16',
  color = '#34330',
  style,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M13.8538 5.14625L10.3538 1.64625C10.3073 1.59983 10.2521 1.56303 10.1914 1.53793C10.1307 1.51284 10.0657 1.49995 10 1.5H4C3.73478 1.5 3.48043 1.60536 3.29289 1.79289C3.10536 1.98043 3 2.23478 3 2.5V13.5C3 13.7652 3.10536 14.0196 3.29289 14.2071C3.48043 14.3946 3.73478 14.5 4 14.5H13C13.2652 14.5 13.5196 14.3946 13.7071 14.2071C13.8946 14.0196 14 13.7652 14 13.5V5.5C14.0001 5.43432 13.9872 5.36927 13.9621 5.30858C13.937 5.24788 13.9002 5.19272 13.8538 5.14625ZM10.5 3.20688L12.2931 5H10.5V3.20688ZM13 13.5H4V2.5H9.5V5.5C9.5 5.63261 9.55268 5.75979 9.64645 5.85355C9.74021 5.94732 9.86739 6 10 6H13V13.5Z"
        fill={color}
      />
      <path
        d="M7.16667 9.49988H4.94444C4.82657 9.49988 4.71352 9.44721 4.63017 9.35347C4.54683 9.25972 4.5 9.13258 4.5 9C4.5 8.86742 4.54683 8.74028 4.63017 8.64653C4.71352 8.55279 4.82657 8.50012 4.94444 8.50012H6.92889L7.68556 7.22293C7.72613 7.15437 7.78113 7.09814 7.84568 7.05924C7.91023 7.02035 7.98233 7 8.05556 7C8.12878 7 8.20088 7.02035 8.26543 7.05924C8.32998 7.09814 8.38499 7.15437 8.42556 7.22293L9.83333 9.59735L10.3522 8.72257C10.3928 8.65409 10.4479 8.59796 10.5124 8.55915C10.577 8.52034 10.649 8.50007 10.7222 8.50012H12.0556C12.1734 8.50012 12.2865 8.55279 12.3698 8.64653C12.4532 8.74028 12.5 8.86742 12.5 9C12.5 9.13258 12.4532 9.25972 12.3698 9.35347C12.2865 9.44721 12.1734 9.49988 12.0556 9.49988H10.96L10.2033 10.7771C10.1628 10.8456 10.1078 10.9019 10.0432 10.9408C9.97866 10.9796 9.90656 11 9.83333 11C9.7601 11 9.68801 10.9796 9.62346 10.9408C9.55891 10.9019 9.5039 10.8456 9.46333 10.7771L8.05556 8.4014L7.53667 9.27618C7.49617 9.34489 7.4412 9.40127 7.37665 9.4403C7.31209 9.47933 7.23996 9.49979 7.16667 9.49988Z"
        fill={color}
      />
    </svg>
  );
}

export default WinLossIcon;
