import { KlueUIProvider } from '@kluein/klue-ui';

import ProvideAuth from 'contexts/auth/ProvideAuth';
import {
  AppUIProvider,
  BreadcrumbProvider,
  BulkEditProvider,
  EmbeddedProvider,
} from 'contexts/ui';
import { AnalyticsProvider } from 'lib/analytics';

import { ReportLoadingProvider } from './reports/loadingContext';
import { SearchProvider } from './ui/searchContext';

import type { ReactNode, PropsWithChildren, ElementType } from 'react';

type ProviderProps = {
  children?: ReactNode;
};

const composeProviders = (
  ...providers: ElementType[]
): ElementType<ProviderProps> => {
  return providers.reduce(
    (AccumulatedProviders: ElementType, CurrentProvider: ElementType) =>
      (props: PropsWithChildren<object>) =>
        (
          <AccumulatedProviders {...props}>
            <CurrentProvider {...props}>{props.children}</CurrentProvider>
          </AccumulatedProviders>
        ),
    (props: PropsWithChildren<object>) => <>{props.children}</>,
  );
};

const providers = [
  KlueUIProvider,
  AppUIProvider,
  ProvideAuth,
  BreadcrumbProvider,
  AnalyticsProvider,
  BulkEditProvider,
  EmbeddedProvider,
  SearchProvider,
  ReportLoadingProvider,
];

export const AppContextProvider = composeProviders(...providers);
