import React from 'react';

import type {
  UserType as UserTypeFromApi,
  CompanyType as CompanyTypeFromApi,
} from 'api/api.types';

type UserType = UserTypeFromApi | null;
type CompanyType = CompanyTypeFromApi | null;

export type AuthContextType = {
  loading: boolean;
  user: UserType;
  company: CompanyType;
  signOut: VoidFunction;
  updateUser: (data: Partial<UserTypeFromApi>) => void;
  fetchUser: VoidFunction;
  homeRoute: string;
  showCardInfo: boolean;
  isReportsEnabled: boolean;
  isTagVisible: boolean;
  isCurator: boolean;
  isAdmin: boolean;
  isDigestAvailable?: boolean;
  isImpersonating?: boolean;
  revertImpersonatingUser: VoidFunction;
  isCardViewerUpdateEnabled: boolean;
  isDashboardNewsEnabled: boolean;
  isCardFeedbackDisabled: boolean;
  isCardSentimentEnabled: boolean;
  isCardSentimentAndFeedbackOnHoverDisabled: boolean;
  isAlertsRecommendedTagEnabled: boolean;
  isAlertsAutoSummarizeOnTriageEnabled: boolean;
  isWinLossEnabled: boolean;
  isWinLossExclusive: boolean;
  isWinLossAnalyticsEnabled: boolean;
  isBattlecardLayoutLabelDisabled: boolean;
  isCompetitiveAnalyticsOverviewReportEnabled: boolean;
  isCompetitiveAnalyticsThreatAnalysisReportEnabled: boolean;
  isCompetitiveAnalyticsRepPerformanceReportEnabled: boolean;
  isCompetitiveAnalyticsDataHygieneReportEnabled: boolean;
  isCompetitiveAnalyticsImpactReportEnabled: boolean;
  isCompetitiveAnalyticsProgramImpactReportEnabled: boolean;
  isConsumerReportEnabledForCurators: boolean;
  isCuratorReportEnabledForCurators: boolean;
  isCRAReportEnabledForCurators: boolean;
  isCompetitiveDashboardEnabledForConsumers: boolean;
  isDashboardAskKlueEnabled: boolean;
  isDashboardNewsToggleDefaultOff: boolean;
  isSearchResultsAskKlueEnabled: boolean;
  isCompanyOverviewEnabled: boolean;
  isGAEnabled: boolean;
  isSFDCDataSyncOpportunityContactRoleEnabled: boolean;
  isSFDCDataSyncUserRoleEnabled: boolean;
  isSFDCDataSyncContactsEnabled: boolean;
  isGongCallInsightsTrackerSelectEnabled: boolean;
  isReviewInsightsCreateCardEnabled?: boolean;
  isProfileSideNavNewsDisabled: boolean;
  isResearchEnabled: boolean;
  isResearchDevEnabled: boolean;
  isResearchChatEnabled: boolean;
  isComposerEnabled: boolean;
  isAlertsTitleEditingDisabled: boolean;
  isNewCuratorNavEnabled: boolean;
  isFeedHiddenInConsumerNavEnabled: boolean;
  isInsightsWhyWeWinLoseEnabled: boolean;
  isInsightsNewCreateCardEnabled: boolean;
  isCallInsightsDemoReplacementEnabled?: boolean;
  isNewCardEditorEnabled: boolean;
  isV2CuratorDashboardEnabled: boolean;
  isSearchSuggestionBattlecardsEnabled: boolean;
  isGenAISearchResultsDisabled?: boolean;
  isChatEnabledForAskKlue?: boolean;
};

const initialValue = {
  loading: true,
  user: null,
  company: null,
  signOut: () => null,
  updateUser: () => null,
  fetchUser: () => null,
  homeRoute: '/dashboard',
  showCardInfo: true,
  isReportsEnabled: false,
  isTagVisible: false,
  isCurator: false,
  isAdmin: false,
  isDigestAvailable: false,
  isImpersonating: false,
  revertImpersonatingUser: () => null,
  isCardViewerUpdateEnabled: false,
  isDashboardNewsEnabled: false,
  isCardFeedbackDisabled: true,
  isCardSentimentEnabled: false,
  isCardSentimentAndFeedbackOnHoverDisabled: false,
  isAlertsRecommendedTagEnabled: false,
  isAlertsAutoSummarizeOnTriageEnabled: true,
  isWinLossEnabled: false,
  isWinLossExclusive: false,
  isWinLossAnalyticsEnabled: false,
  isBattlecardLayoutLabelDisabled: false,
  isCompetitiveAnalyticsOverviewReportEnabled: false,
  isCompetitiveAnalyticsThreatAnalysisReportEnabled: false,
  isCompetitiveAnalyticsRepPerformanceReportEnabled: false,
  isCompetitiveAnalyticsDataHygieneReportEnabled: false,
  isCompetitiveAnalyticsImpactReportEnabled: false,
  isCompetitiveAnalyticsProgramImpactReportEnabled: false,
  isConsumerReportEnabledForCurators: false,
  isCuratorReportEnabledForCurators: false,
  isCRAReportEnabledForCurators: false,
  isCompetitiveDashboardEnabledForConsumers: false,
  isDashboardAskKlueEnabled: false,
  isDashboardNewsToggleDefaultOff: false,
  isSearchResultsAskKlueEnabled: false,
  isCompanyOverviewEnabled: false,
  isGAEnabled: false,
  isSFDCDataSyncContactsEnabled: false,
  isSFDCDataSyncOpportunityContactRoleEnabled: false,
  isSFDCDataSyncUserRoleEnabled: false,
  isGongCallInsightsTrackerSelectEnabled: false,
  isReviewInsightsCreateCardEnabled: false,
  isProfileSideNavNewsDisabled: false,
  isResearchEnabled: false,
  isComposerEnabled: false,
  isResearchDevEnabled: false,
  isResearchChatEnabled: false,
  isAlertsTitleEditingDisabled: false,
  isNewCuratorNavEnabled: false,
  isFeedHiddenInConsumerNavEnabled: false,
  isInsightsWhyWeWinLoseEnabled: false,
  isInsightsNewCreateCardEnabled: false,
  isCallInsightsDemoReplacementEnabled: false,
  isNewCardEditorEnabled: false,
  isV2CuratorDashboardEnabled: false,
  isSearchSuggestionBattlecardsEnabled: false,
  isGenAISearchResultsDisabled: false,
  isChatEnabledForAskKlue: false,
};

const authContext = React.createContext<AuthContextType>(initialValue);

export default authContext;
