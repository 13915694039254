import queryString from 'query-string';

import { APP_BASEURL } from 'lib/urls';

import { api } from '../api';

import type {
  GetPageSelectionsRequestQuery,
  GetPageSelectionsResponse,
  GetPageMonitorByIdRequestQuery,
  GetPageMonitorByIdResponse,
  GetPageSelectionContentsByPageSelectionIdRequestQuery,
  GetPageSelectionContentsByPageSelectionIdResponse,
  GetPageSelectionContentsByIdRequestQuery,
  GetPageSelectionContentsByIdResponse,
  PostPageMonitorRequestBody,
  PostPageMonitorResponse,
  DeletePageMonitorRequestQuery,
  DeletePageMonitorResponse,
  GetPageMonitorByUrlResponse,
  GetPageMonitorByUrlRequestQuery,
  PostPinPageSelectionResponse,
  PostPinPageSelectionRequest,
  PostUnpinPageSelectionResponse,
  PostUnpinPageSelectionRequest,
  PostAddRivalToPageMonitorResponse,
  PostAddRivalToPageMonitorRequest,
  PostRemoveRivalFromPageMonitorRequest,
  PostRemoveRivalFromPageMonitorResponse,
} from 'api/endpoints';

export const {
  useGetPageSelectionsQuery,
  usePostPinPageSelectionMutation,
  usePostUnpinPageSelectionMutation,
  useGetPageMonitorByIdQuery,
  useGetPageMonitorByUrlQuery,
  usePostAddRivalToPageMonitorMutation,
  usePostRemoveRivalFromPageMonitorMutation,
  useGetPageSelectionContentsByPageSelectionIdQuery,
  useGetPageSelectionContentsByIdQuery,
  usePostPageMonitorMutation,
  useDeletePageMonitorByIdMutation,
} = api.injectEndpoints({
  endpoints: (build) => ({
    getPageSelections: build.query<
      GetPageSelectionsResponse,
      GetPageSelectionsRequestQuery
    >({
      query: (params) =>
        `${APP_BASEURL}/monitors/api/v0/page_selections?${queryString.stringify(
          { ...params },
        )}`,
    }),
    postPinPageSelection: build.mutation<
      PostPinPageSelectionResponse,
      PostPinPageSelectionRequest
    >({
      query: ({ pageSelectionId }) => ({
        url: `${APP_BASEURL}/monitors/api/v0/page_selections/${pageSelectionId}:pin`,
        method: 'POST',
      }),
    }),
    postUnpinPageSelection: build.mutation<
      PostUnpinPageSelectionResponse,
      PostUnpinPageSelectionRequest
    >({
      query: ({ pageSelectionId }) => ({
        url: `${APP_BASEURL}/monitors/api/v0/page_selections/${pageSelectionId}:unpin`,
        method: 'POST',
      }),
    }),
    getPageMonitorById: build.query<
      GetPageMonitorByIdResponse,
      GetPageMonitorByIdRequestQuery
    >({
      query: ({ id }) => `${APP_BASEURL}/monitors/api/v0/page_monitors/${id}`,
    }),
    getPageMonitorByUrl: build.query<
      GetPageMonitorByUrlResponse,
      GetPageMonitorByUrlRequestQuery
    >({
      query: (params) =>
        `${APP_BASEURL}/monitors/api/v0/page_monitor?${queryString.stringify({
          ...params,
        })}`,
    }),
    postAddRivalToPageMonitor: build.mutation<
      PostAddRivalToPageMonitorResponse,
      PostAddRivalToPageMonitorRequest
    >({
      query: ({ pageMonitorId, rivalId }) => ({
        url: `${APP_BASEURL}/monitors/api/v0/page_monitors/${pageMonitorId}:addRival?rival_id=${rivalId}`,
        method: 'POST',
      }),
    }),
    postRemoveRivalFromPageMonitor: build.mutation<
      PostRemoveRivalFromPageMonitorResponse,
      PostRemoveRivalFromPageMonitorRequest
    >({
      query: ({ pageMonitorId, rivalId }) => ({
        url: `${APP_BASEURL}/monitors/api/v0/page_monitors/${pageMonitorId}:removeRival?rival_id=${rivalId}`,
        method: 'POST',
      }),
    }),
    getPageSelectionContentsByPageSelectionId: build.query<
      GetPageSelectionContentsByPageSelectionIdResponse,
      GetPageSelectionContentsByPageSelectionIdRequestQuery
    >({
      query: ({ pageSelectionId }) =>
        `${APP_BASEURL}/monitors/api/v0/page_selections/${pageSelectionId}/page_selection_contents?include_first_n_contents=0`,
    }),
    getPageSelectionContentsById: build.query<
      GetPageSelectionContentsByIdResponse,
      GetPageSelectionContentsByIdRequestQuery
    >({
      query: ({ pageSelectionContentId }) =>
        `${APP_BASEURL}/monitors/api/v0/page_selection_contents/${pageSelectionContentId}`,
    }),
    postPageMonitor: build.mutation<
      PostPageMonitorResponse,
      PostPageMonitorRequestBody
    >({
      query: (body) => ({
        url: `${APP_BASEURL}/monitors/api/v0/page_monitors`,
        method: 'POST',
        body,
      }),
    }),
    deletePageMonitorById: build.mutation<
      DeletePageMonitorResponse,
      DeletePageMonitorRequestQuery
    >({
      query: ({ id }) => ({
        url: `${APP_BASEURL}/monitors/api/v0/page_monitors/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});
