import { createModel } from '@rematch/core';

import { fetchSearch } from 'api/endpoints/search';
import { addVisibilityGroupIdToQuery } from 'store/utils';

import type { BoardsType, TagType } from 'api/api.types';
import type { SearchQueryType } from 'api/endpoints/search.types';
import type { StoreSelectors } from 'store';
import type { RootModel } from 'store/models';

export type Sort = 'name' | 'count';
export type VisibleFilters = 'lanes' | 'tags' | null;

export type StateType = {
  filters: Set<string>;
  boardFilters: Set<number>;
  boardFilterCounts: Map<number, number>;
  boardFilterSort: Sort;
  tagFilters: Set<number>;
  tagFilterCounts: Map<number, number>;
  tagFilteredBoards: Set<number> | undefined;
  tagFilterSort: Sort;
  visibleFilters: VisibleFilters;
  showEmptyBoards: boolean;
  isLoading: boolean;
};

export const initialState: StateType = {
  filters: new Set(),
  boardFilters: new Set(),
  boardFilterCounts: new Map(),
  boardFilterSort: 'name',
  tagFilters: new Set(),
  tagFilterCounts: new Map(),
  tagFilterSort: 'name',
  tagFilteredBoards: undefined,
  visibleFilters: null,
  showEmptyBoards: false,
  isLoading: false,
};

export const profileFilters = createModel<RootModel>()({
  state: initialState,
  reducers: {
    reset: () => initialState,
    setLoading: (state: StateType, isLoading: boolean) => {
      return {
        ...state,
        isLoading,
      };
    },
    setBoardFilters: (state: StateType, boards: Set<number>) => {
      return setFilters(state, boards, 'board');
    },
    setTagFilters: (state: StateType, tags: Set<number>) => {
      const tagFilteredBoards = !tags.size
        ? undefined
        : state.tagFilteredBoards;

      return {
        ...setFilters(state, tags, 'tag'),
        tagFilteredBoards,
      };
    },
    setBoardFilterCounts: (state: StateType, boards: Map<number, number>) => ({
      ...state,
      boardFilterCounts: boards,
    }),
    setTagFilterCounts: (state: StateType, tags: Map<number, number>) => ({
      ...state,
      tagFilterCounts: tags,
    }),
    toggleBoardSort: (state) => {
      return {
        ...state,
        boardFilterSort: state.boardFilterSort === 'name' ? 'count' : 'name',
      };
    },
    toggleTagSort: (state) => {
      return {
        ...state,
        tagFilterSort: state.tagFilterSort === 'name' ? 'count' : 'name',
      };
    },
    setTagFilteredBoards: (state: StateType, boards: Set<number>) => {
      return {
        ...state,
        tagFilteredBoards: boards,
      };
    },
    setVisibleFilters: (state: StateType, visibleFilters: VisibleFilters) => ({
      ...state,
      visibleFilters,
    }),
    setShowEmptyBoards: (state: StateType, showEmptyBoards: boolean) => ({
      ...state,
      showEmptyBoards,
    }),
  },
  selectors: (slice, createSelector) => ({
    allFilters() {
      return slice(({ filters }) =>
        [...filters].map((filter) => {
          const [type, id] = filter.split('-');
          return {
            type: type as 'board' | 'tag',
            id: Number(id),
          };
        }),
      );
    },
    allBoards(models: StoreSelectors) {
      return createSelector(
        models.boards.currentBoards as any,
        this.boardFilters as any,
        this.boardFilterCounts as any,
        this.boardFilterSort as any,
        (
          boards: BoardsType[],
          boardFilters: Set<number>,
          boardFilterCounts: Map<number, number>,
          boardFilterSort: Sort,
        ) =>
          boards
            ?.map((board) => ({
              id: board.id,
              label: board.name,
              checked: boardFilters.has(board.id),
              count: boardFilterCounts.get(board.id) || 0,
            }))
            .sort(
              boardFilterSort === 'name'
                ? (a, b) => a.label.localeCompare(b.label)
                : (a, b) => b.count - a.count,
            ) || [],
      );
    },
    allTags(models: StoreSelectors) {
      return createSelector(
        models.tags.all as any,
        this.tagFilters as any,
        this.tagFilterCounts as any,
        this.tagFilterSort as any,
        (
          tags: TagType[],
          tagFilters: Set<number>,
          tagFilterCounts: Map<number, number>,
          tagFilterSort: Sort,
        ) =>
          tags
            ?.map((tag) => ({
              id: tag.id,
              label: tag.name,
              checked: tagFilters.has(tag.id),
              count: tagFilterCounts.get(tag.id) || 0,
            }))
            .sort(
              tagFilterSort === 'name'
                ? (a, b) => a.label.localeCompare(b.label)
                : (a, b) => b.count - a.count,
            ) || [],
      );
    },
    boardFilters() {
      return slice(({ boardFilters }) => boardFilters);
    },
    boardFilterCounts(models: StoreSelectors) {
      return createSelector(
        this.tagFilters as any,
        slice(({ boardFilterCounts }) => boardFilterCounts) as any,
        models.boards.currentBoards as any,
        (
          tagFilters: StateType['tagFilters'],
          boardFilterCountsFromSearch: StateType['boardFilterCounts'],
          currentBoards: BoardsType[] | undefined,
        ) => {
          if (tagFilters.size) {
            return boardFilterCountsFromSearch;
          }
          return new Map(
            currentBoards?.map(({ id, cardsCount }) => [id, cardsCount]),
          );
        },
      );
    },
    boardFilterSort() {
      return slice(({ boardFilterSort }) => boardFilterSort);
    },
    tagFilters() {
      return slice(({ tagFilters }) => tagFilters);
    },
    tagFilterCounts() {
      return slice(({ tagFilterCounts }) => tagFilterCounts);
    },
    tagFilterSort() {
      return slice(({ tagFilterSort }) => tagFilterSort);
    },
    boardFilteredBoardIds(models: StoreSelectors) {
      return createSelector(
        models.profiles.currentProfileBoardIds as any,
        this.boardFilters as any,
        (
          currentBoardIds: number[] | undefined,
          boardFilters: StateType['boardFilters'],
        ) => {
          if (!boardFilters.size) {
            return currentBoardIds;
          }
          return currentBoardIds?.filter((board) => boardFilters.has(board));
        },
      );
    },
    tagFilteredBoardIds() {
      return slice(({ tagFilteredBoards }) => tagFilteredBoards);
    },
    showEmptyBoards() {
      return slice(({ showEmptyBoards }) => showEmptyBoards);
    },
    filteredBoardIds() {
      return createSelector(
        this.boardFilteredBoardIds as any,
        this.tagFilters as any,
        this.tagFilteredBoardIds as any,
        this.showEmptyBoards as any,
        (
          boards: number[] | undefined,
          tagFilters: StateType['tagFilters'],
          tagFilteredBoards: StateType['tagFilteredBoards'],
          showEmptyBoards: StateType['showEmptyBoards'],
        ) => {
          if (!tagFilters.size || showEmptyBoards || !tagFilteredBoards) {
            return boards;
          }
          return boards?.filter((board) => tagFilteredBoards.has(board));
        },
      );
    },
    isLoading() {
      return slice(({ isLoading }) => isLoading);
    },
  }),
  effects: () => ({
    async _fetchBoardFilterCounts(_, rootState) {
      const {
        profiles: { currentRivalId },
        profileFilters: { tagFilters },
      } = rootState;
      if (tagFilters.size) {
        const query: SearchQueryType = {
          index: 'cards',
          size: 0,
          rivals: currentRivalId?.toString(),
          allTags: Array.from(tagFilters).join(),
          countersFor: ['board.id'],
        };
        addVisibilityGroupIdToQuery(rootState, query);
        const {
          data: {
            results: {
              field_counters: { 'board.id': boardCounters },
            },
          },
        } = await fetchSearch({ query });

        const boardCounts = new Map(
          boardCounters.map(({ key, doc_count }) => [Number(key), doc_count]),
        );
        this.setBoardFilterCounts(boardCounts);
      }
    },
    async _fetchTagFilterCounts(_, rootState) {
      const {
        profiles: { currentRivalId },
        profileFilters: { boardFilters, tagFilters },
      } = rootState;
      const query: SearchQueryType = {
        index: 'cards',
        size: 0,
        rivals: currentRivalId?.toString(),
        lanes: Array.from(boardFilters).join(),
        allTags: Array.from(tagFilters).join(),
        countersFor: ['tags.id'],
      };
      addVisibilityGroupIdToQuery(rootState, query);
      const {
        data: {
          results: {
            field_counters: { 'tags.id': tagCounters },
          },
        },
      } = await fetchSearch({ query });
      const tagCounts = new Map(
        tagCounters.map(({ key, doc_count }) => [Number(key), doc_count]),
      );
      this.setTagFilterCounts(tagCounts);
    },
    async setTagFilters(_, rootState) {
      const {
        profiles: { currentRivalId },
        profileFilters: { tagFilters, visibleFilters },
      } = rootState;

      const promises = [];

      if (visibleFilters === 'tags') {
        promises.push(this._fetchTagFilterCounts());
      }

      if (tagFilters.size) {
        this.setLoading(true);
        const query: SearchQueryType = {
          index: 'cards',
          rivals: currentRivalId?.toString(),
          allTags: Array.from(tagFilters).join(),
          countersFor: ['board.id'],
        };
        addVisibilityGroupIdToQuery(rootState, query);
        const fetchTagFilteredBoards = fetchSearch({ query })
          .then((res) => {
            const boardCounters = res.data.results.field_counters['board.id'];
            const boards = new Set(boardCounters.map(({ key }) => Number(key)));
            this.setTagFilteredBoards(boards);
          })
          .finally(() => {
            this.setLoading(false);
          });
        promises.push(fetchTagFilteredBoards);
      }
      await Promise.all(promises);
    },
    setVisibleFilters(_, rootState) {
      const { visibleFilters } = rootState.profileFilters;

      switch (visibleFilters) {
        case 'lanes':
          return this._fetchBoardFilterCounts();
        case 'tags':
          return this._fetchTagFilterCounts();
        default:
          this.setBoardFilterCounts(new Map());
          this.setTagFilterCounts(new Map());
          break;
      }
    },
  }),
});

function setFilters(
  state: StateType,
  filtersOfType: Set<number>,
  type: 'board' | 'tag',
) {
  const filters = new Set<string>();
  state.filters.forEach((filter) => {
    const [filterType, filterId] = filter.split('-');
    if (filterType !== type || filtersOfType.has(+filterId)) {
      filters.add(filter);
    }
  });
  filtersOfType.forEach((id) => {
    filters.add(`${type}-${id}`);
  });
  return {
    ...state,
    [`${type}Filters`]: filtersOfType,
    filters,
  };
}
