import { onVisibilityChange } from 'lib/utils';

import type { Dispatch, RootState } from 'store';

type CardDimensionType = Record<string, { width: number; height: number }>;

type CardsDimensionsType = Record<string, CardDimensionType>;

export const CARDS_DIMENSIONS_STORE_NAME = 'cardsDimensionsByProfileMap';
const REMOVE_OBSERVER_CUSTOM_EVENT = 'REMOVE_OBSERVER';
const DEFAULT_PROFILE_ID = 0;

export const getCardsDimensionsSaved = (): CardsDimensionsType => {
  return JSON.parse(localStorage.getItem(CARDS_DIMENSIONS_STORE_NAME) ?? '{}');
};

export const saveCardsDimensionsToLocalStorage = (
  cardsDimensions: CardsDimensionsType,
) => {
  localStorage.setItem(
    CARDS_DIMENSIONS_STORE_NAME,
    JSON.stringify(cardsDimensions),
  );
};

export const getCardsDimensionsByBoardLaneId = (
  boardLaneId = DEFAULT_PROFILE_ID,
) => {
  return getCardsDimensionsSaved()[boardLaneId] ?? {};
};

export const getTotalCardsByProfileId = ({
  profileId,
  rootState,
}: {
  profileId: string;
  rootState: RootState;
}) => {
  const defaultValue = 0;

  const profileBoards =
    rootState.profiles.byId.get(profileId.toString())?.boards ?? [];

  return profileBoards.reduce((prev: number, next: number) => {
    const cardsCount =
      rootState.boards.byId.get(next.toString())?.cardsCount ?? defaultValue;

    return prev + cardsCount;
  }, defaultValue);
};

export const getRenderedCardsDimensions = () => {
  const attributeName = 'data-card-id';
  const cards = document.querySelectorAll<HTMLDivElement>(`[${attributeName}]`);

  return Array.from(cards).reduce(
    (prevCards: CardDimensionType, card: HTMLDivElement) => {
      const { width, height } = card.getBoundingClientRect();
      const id = card.attributes.getNamedItem(attributeName)?.value as string;

      return {
        ...prevCards,
        [id]: {
          width,
          height,
        },
      };
    },
    {},
  );
};

export const saveCardsDimensions = ({
  profileId,
  rootState,
}: {
  profileId: number;
  rootState: RootState;
}) => {
  const oneSecond = 1000;
  setTimeout(() => {
    const cardsDimensionsByProfileMap = getCardsDimensionsSaved();

    const totalCardsByProfileId = getTotalCardsByProfileId({
      profileId: profileId.toString(),
      rootState,
    });

    const savedCardsDimensions = cardsDimensionsByProfileMap[profileId] ?? {};
    const totalSavedCards = Object.keys(savedCardsDimensions).length;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (totalSavedCards >= totalCardsByProfileId) {
      return;
    }

    const renderedCardsDimensions = getRenderedCardsDimensions();

    cardsDimensionsByProfileMap[profileId] = {
      ...savedCardsDimensions,
      ...renderedCardsDimensions,
    };

    saveCardsDimensionsToLocalStorage(cardsDimensionsByProfileMap);
  }, oneSecond);
};

export const observeBoardsUpdates = ({
  profileId,
  dispatch,
}: {
  profileId: number;
  dispatch: Dispatch;
}) => {
  const SET_INTERVAL_MS = 120000;
  let intervalInstance: any;

  const startInterval = () => {
    intervalInstance = setInterval(() => {
      dispatch.boards.checkBoardUpdates(profileId);
    }, SET_INTERVAL_MS);
  };

  startInterval();

  const removeOnVisibilityChangeEvent = onVisibilityChange({
    onActive: () => {
      clearInterval(intervalInstance as NodeJS.Timeout);
      startInterval();
    },
    onHidden: () => {
      clearInterval(intervalInstance as NodeJS.Timeout);
    },
  });

  const handleRemoveEvents = () => {
    clearInterval(intervalInstance as NodeJS.Timeout);
    removeOnVisibilityChangeEvent();
    window.removeEventListener(
      REMOVE_OBSERVER_CUSTOM_EVENT,
      handleRemoveEvents,
      true,
    );
  };

  window.addEventListener(
    REMOVE_OBSERVER_CUSTOM_EVENT,
    handleRemoveEvents,
    true,
  );
};

export const removeObserveBoardsUpdates = () => {
  window.dispatchEvent(new CustomEvent(REMOVE_OBSERVER_CUSTOM_EVENT));
};
