import { createModel } from '@rematch/core';

import {
  type SearchRivalBattlecardType,
  fetchSearchRivals,
} from 'api/endpoints/search-rivals';
import { fetchSuggestions } from 'api/endpoints/suggest';
import { fetchInstanceTags } from 'api/endpoints/tags';

import sortFuzzy from './searchSuggestions.utils';

import type { RootModel } from '..';
import type { TagType } from 'api/api.types';

type SearchRivalBattlecardTypeEnhanced = SearchRivalBattlecardType & {
  rivalName: string;
  rivalProfileId: number;
};

type StateType = {
  isLoading: boolean;
  searchTerms: string;
  visibilityGroup: number | null;
  completionSuggestions: string[];
  tagSuggestions: TagType[];
  battlecardSuggestions: SearchRivalBattlecardTypeEnhanced[];
};

type SearchParamsType = {
  searchTerms: string;
  visibilityGroup: number | null;
};

type PopulatePayloadType = {
  completions: string[];
  tags: TagType[];
  battlecards: SearchRivalBattlecardTypeEnhanced[];
};

export enum Suggestion {
  COMPLETION = 'completion',
  TAG = 'tag',
  BATTLECARD = 'battlecard',
}

export type TagSuggestionType = {
  type: Suggestion.TAG;
  value: Omit<TagType, 'synonyms'> & { synonym?: string };
};

export type BattlecardSuggestionType = {
  type: Suggestion.BATTLECARD;
  value: SearchRivalBattlecardTypeEnhanced;
};

export type SuggestionType =
  | {
      type: Suggestion.COMPLETION;
      value: string;
    }
  | TagSuggestionType
  | BattlecardSuggestionType;

export const initialState = {
  isLoading: false,
  searchTerms: '',
  visibilityGroup: null,
  completionSuggestions: [],
  tagSuggestions: [],
  battlecardSuggestions: [],
} as StateType;

export const searchSuggestions = createModel<RootModel>()({
  state: initialState,
  reducers: {
    reset: (_state) => initialState,
    setLoading: (state, isLoading) => ({
      ...state,
      isLoading,
    }),
    populate: (
      state,
      {
        completions: completionSuggestions,
        battlecards: battlecardSuggestions,
        tags: tagSuggestions,
      }: PopulatePayloadType,
    ) =>
      state.isLoading
        ? {
            ...state,
            completionSuggestions,
            battlecardSuggestions,
            tagSuggestions,
          }
        : state,
    getSuggestions: (
      state,
      { searchTerms, visibilityGroup }: SearchParamsType,
    ) => {
      return {
        ...state,
        searchTerms,
        visibilityGroup,
      };
    },
  },
  selectors: (slice, createSelector) => ({
    completionSuggestions() {
      return slice(({ completionSuggestions }) => completionSuggestions);
    },
    battlecardSuggestions() {
      return slice(({ battlecardSuggestions }) => battlecardSuggestions);
    },
    tagSuggestions() {
      return slice(({ tagSuggestions }) => tagSuggestions);
    },
    suggestions() {
      return slice(
        ({ completionSuggestions, battlecardSuggestions, tagSuggestions }) => [
          ...completionSuggestions.map(
            (suggestion) =>
              ({
                type: Suggestion.COMPLETION,
                value: suggestion,
              } as SuggestionType),
          ),
          ...battlecardSuggestions.map(
            (battlecard) =>
              ({
                type: Suggestion.BATTLECARD,
                value: battlecard,
              } as SuggestionType),
          ),
          ...tagSuggestions.flatMap((tag) => {
            if (tag.synonyms.length) {
              return tag.synonyms.map(
                (synonym) =>
                  ({
                    type: Suggestion.TAG,
                    value: { id: tag.id, name: tag.name, synonym },
                  } as SuggestionType),
              );
            }
            return {
              type: Suggestion.TAG,
              value: { id: tag.id, name: tag.name },
            } as SuggestionType;
          }),
        ],
      );
    },
  }),
  effects: () => ({
    async getSuggestions(_payload, rootState) {
      this.setLoading(true);

      const { searchTerms, visibilityGroup } = rootState.searchSuggestions;
      const completionsReq = fetchSuggestions({
        query: { query: searchTerms, mode: 'completions' },
      });
      const tagsReq = fetchInstanceTags({
        query: { filter: 'assigned,active', search: searchTerms },
      });
      const rivalReq = fetchSearchRivals({
        query: {
          query: searchTerms,
          ...(typeof visibilityGroup === 'number'
            ? { visibilityGroupId: visibilityGroup }
            : {}),
        },
      });

      const [completions, tags, rivals] = await Promise.allSettled([
        completionsReq,
        tagsReq,
        rivalReq,
      ]);

      let sortedRivals: string[] = [];
      if (rivals.status === 'fulfilled') {
        sortedRivals = sortFuzzy(
          rivals.value.data.map((rival) => rival.name),
          searchTerms,
        );
      }

      const battlecards =
        rivals.status === 'fulfilled'
          ? rivals.value.data
              ?.sort((a, b) => {
                const indexA = sortedRivals.indexOf(a.name);
                const indexB = sortedRivals.indexOf(b.name);

                if (indexA !== -1 && indexB !== -1) {
                  return indexA - indexB;
                }

                if (indexA === -1 && indexB !== -1) return 1;
                if (indexB === -1 && indexA !== -1) return -1;

                return a.name.localeCompare(b.name);
              })
              .flatMap(
                (rival) =>
                  rival.battlecards?.map((battlecard) => ({
                    ...battlecard,
                    rivalName: rival.name,
                    rivalProfileId: rival.profile.id,
                  })) || [],
              )
          : [];

      this.populate({
        completions:
          completions.status === 'fulfilled'
            ? completions.value.data.suggestions
            : [],
        tags: tags.status === 'fulfilled' ? tags.value.data.items : [],
        battlecards,
      });
      this.setLoading(false);
    },
  }),
});
