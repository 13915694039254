import { Box, fontWeight, letterSpacing, tokens } from '@kluein/klue-ui';
import styled, { css } from 'styled-components';

const underlineStyle = css`
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledButton = styled(Box)<{ isActive?: boolean }>`
  button {
    align-items: center;
    display: flex;
    height: 100%;
    text-overflow: ellipsis;
    transition: background-color 250ms ease-in-out,
      color 250ms 125ms ease-in-out;
    white-space: nowrap;
    box-shadow: none;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;

    label {
      color: ${tokens.color.neutral.black.main};
      font-weight: ${fontWeight.bold};
      letter-spacing: ${letterSpacing.small};
      text-transform: uppercase;
    }

    &.active {
      box-shadow: none;
      background-color: transparent;
    }

    &:link,
    &:hover,
    &:visited,
    &:active {
      &,
      label {
        background-color: transparent;
        text-decoration: none;
        border-color: transparent;
        box-shadow: none;
      }
    }

    &:focus-visible {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &:after {
        ${underlineStyle}
        background-color: ${tokens.color.primitives.grey[1000]};
      }
    `}

  &:hover:after {
    ${underlineStyle}
    background-color: ${tokens.color.primitives.grey[800]};
  }
`;

const isActiveStyle = css`
  background-color: ${tokens.color.neutral.lightgrey.main};
`;

export const StyledResponsiveBox = styled(Box)<{ isActive?: boolean }>`
  cursor: pointer;

  ${({ isActive }) => isActive && isActiveStyle}

  &:hover {
    ${isActiveStyle};
  }
`;
