import { useState, useEffect } from 'react';

import { useAuth } from 'contexts/auth';
import { useEmbedded } from 'contexts/ui';
import loadPendo from 'lib/pendo/loadPendo';

type PendoInstalled = {
  pendo?: PendoType;
};

type PendoType = {
  visitorId: string;
  initialize: (obj: {
    visitor: PendoUserData;
    account: PendoCompanyData;
    disableGuides: boolean;
  }) => void;
};

type PendoUserData = {
  id: number;
  name: string;
  email: string;
  roles: string[];
  isStaff: boolean;
  isInternal: boolean;
  isImpersonating: boolean;
  emailDigest: boolean;
  createdAt: string;
  lastSeenAt: string;
};

type PendoCompanyData = {
  id: number;
  name: string;
  url: string;
  createdAt: string;
  reviewFrequency: string;
};

const Pendo = ({ apiKey }: { apiKey: string }) => {
  const { user, company } = useAuth();
  const isEmbedded = useEmbedded();
  const isInsideIframe = window.top !== window.self;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!user?.id || !company?.id || !apiKey || isLoaded) return;

    loadPendo(apiKey);
    setIsLoaded(true);

    const { pendo } = window as PendoInstalled;
    const {
      id,
      name,
      email,
      roles,
      isStaff,
      isInternal,
      isImpersonating,
      emailDigest,
      createdAt,
      lastSeenAt,
    } = user;

    const {
      id: accountId,
      name: accountName,
      url,
      createdAt: accountCreatedAt,
      reviewFrequency,
    } = company;

    if (!pendo?.visitorId) {
      pendo?.initialize({
        visitor: {
          id,
          name,
          email,
          roles,
          isStaff,
          isInternal,
          isImpersonating,
          emailDigest,
          createdAt,
          lastSeenAt,
        },
        account: {
          id: accountId,
          name: accountName,
          url,
          createdAt: accountCreatedAt,
          reviewFrequency,
        },
        disableGuides: isEmbedded || isInsideIframe,
      });
      // @TODO Should we be calling identify after initialize to keep visitor info in sync?
      // https://developers.pendo.io/docs/?bash#initialization-and-identification
    }
  }, [apiKey, company, isEmbedded, isInsideIframe, isLoaded, user]);

  return null;
};

export default Pendo;
