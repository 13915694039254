import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { useFiltersFromURL } from 'hooks/alerts';
import { isInAlerts } from 'pages/alerts/utils';

import type { NavigateFunction } from 'react-router-dom';

export type ContextualSearchType = 'cards' | 'alerts';

export type SearchOptionsType = {
  tagId?: number;
  tagOp?: 'add' | 'toggle';
  terms?: string;
  reset?: boolean;

  shouldOpenInNewTab?: boolean;
};

export type SearchCallbackType = (options?: SearchOptionsType) => void;

export type SearchContextType = {
  search: SearchCallbackType;
  context: ContextualSearchType;
  setContext: (context: ContextualSearchType) => void;
};

export type SearchType = null | SearchCallbackType;

export const SearchContext = createContext<undefined | SearchContextType>(
  undefined,
);

export const SearchProviderContext = createContext<
  undefined | ((search: SearchType) => void)
>(undefined);

const openSearchInV2 = (
  navigate: NavigateFunction,
  path: string,
  options: Pick<SearchOptionsType, 'shouldOpenInNewTab'> = {},
) =>
  options?.shouldOpenInNewTab ? window.open(path, '_blank') : navigate(path);

const searchFactory =
  (navigate: NavigateFunction) => (options?: SearchOptionsType) => {
    const { terms, tagId, shouldOpenInNewTab } = options || {};
    let path = terms ? `/search/${encodeURIComponent(terms)}` : '/search';
    if (tagId) {
      path += '?tags=' + tagId;
    }
    openSearchInV2(navigate, path, { shouldOpenInNewTab });
  };

export const SearchProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const [searchCards, setSearchCards] = useState(() => searchFactory(navigate));
  const { setFiltersOnURL } = useFiltersFromURL();

  const [context, setContext] = useState<ContextualSearchType>(
    isInAlerts() ? 'alerts' : 'cards',
  );

  const provideSearch = useCallback(
    (search: SearchType) => {
      if (search) {
        setSearchCards(() => search);
      } else {
        setSearchCards(() => searchFactory(navigate));
      }
    },
    [navigate],
  );

  const searchAlerts = (options?: SearchOptionsType) => {
    if (isInAlerts()) {
      setFiltersOnURL(
        {
          add: {
            q: options?.terms,
            view: 'search',
          },
        },
        { forceRehydrate: true, shouldRehydrate: true, shouldFetch: true },
      );

      return;
    }

    navigate(`/alerts?q=${options?.terms}`);
  };

  const searchByContext = {
    cards: searchCards,
    alerts: searchAlerts,
  };

  return (
    <SearchContext.Provider
      value={{ search: searchByContext[context], context, setContext }}
    >
      <SearchProviderContext.Provider
        value={provideSearch}
        children={children}
      />
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const search = useContext(SearchContext);
  if (search === undefined) {
    throw new Error('useSearch must be used within SearchProvider');
  }
  return search;
};

export const useProvideSearch = () => {
  const provideSearch = useContext(SearchProviderContext);
  if (provideSearch === undefined) {
    throw new Error('useProvideSearch must be used within SearchProvider');
  }
  return provideSearch;
};
