import { setEndpoint } from '../../configure';

import type { AccessToken, AccessTokenCreateArgs } from './access-tokens.types';

export const [fetchAccessTokens] = setEndpoint<
  any,
  any,
  any,
  { tokens: AccessToken[] }
>({
  path: '/users/tokens.json',
  id: 'fetchAccessTokens',
  method: 'GET',
  namespace: 'accessTokens',
});

export const [createAccessToken] = setEndpoint<
  any,
  AccessTokenCreateArgs,
  any,
  AccessToken & { token: string }
>({
  path: `/users/tokens.json`,
  id: 'createAccessToken',
  method: 'POST',
  namespace: 'accessTokens',
});

export const [fetchAccessTokenById] = setEndpoint<
  { id: number },
  any,
  any,
  AccessToken
>({
  path: '/users/tokens/:id.json',
  id: 'fetchAccessTokenById',
  method: 'GET',
  namespace: 'accessTokens',
});

export const [revokeAccessToken] = setEndpoint<{ id: number }, any, any, any>({
  path: '/users/tokens/:id.json',
  id: 'revokeAccessToken',
  method: 'DELETE',
  namespace: 'accessTokens',
});

export const [getMaxTokens] = setEndpoint<
  any,
  any,
  any,
  { maxPATCount: number }
>({
  path: '/users/tokens/max',
  id: 'getMaxTokens',
  method: 'GET',
  namespace: 'accessTokens',
});
