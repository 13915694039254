import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import mergeWith from 'lodash/mergeWith';
import omit from 'lodash/omit';

import { fetchIntel, State } from 'api/endpoints';
import { THREE_SECOND_IN_MS } from 'lib/constants';
import { getBrowserTimezone } from 'lib/utils';
import {
  ALL_INTEL_STATES,
  dateRangeFilterKeys,
  DEFAULT_STATES_IF_EMPTY,
} from 'pages/alerts/constants';
import { getMyNewIntelPresetData } from 'pages/alerts/utils';

import type { PresetsCountersType } from './intelCards.model';
import type {
  IntelCountersType,
  IntelListItemType,
  UpdateIntelBodyType,
} from 'api/endpoints';

export const getPriorityTag = (
  intel: IntelListItemType & UpdateIntelBodyType,
) => {
  const shouldRemovePriorityTag =
    intel.removePriorityTag && intel.priority === intel.removePriorityTag;

  if (shouldRemovePriorityTag) {
    return undefined;
  }

  return intel.updatePriorityTag || intel.priority;
};

export const parseUpdatedIntel = (
  intel: IntelListItemType & UpdateIntelBodyType,
): IntelListItemType => {
  if (intel.removePriorityTag || intel.updatePriorityTag) {
    intel.priority = getPriorityTag(intel);
    delete intel.updatePriorityTag;
    delete intel.removePriorityTag;
  }

  if (intel.addTopics) {
    intel.topics = [...new Set([...intel.topics, ...intel.addTopics])];
    delete intel.addTopics;
  }

  if (intel.addRivals) {
    intel.rivals = [...new Set([...intel.rivals, ...intel.addRivals])];
    delete intel.addRivals;
  }

  if (intel.removeTopics) {
    intel.topics = intel.topics.filter(
      (topic) => !intel.removeTopics?.includes(topic),
    );
    delete intel.addTopics;
  }

  return intel;
};

export const getPresetCounters = async (options?: {
  delay?: boolean;
  relatedAlerts?: boolean | string;
}): Promise<Partial<PresetsCountersType>> => {
  /**
   * GET MY NEW INTEL PRESET COUNTERS
   */
  const myNewIntelPreset = getMyNewIntelPresetData();

  const commonParams = {
    offset: 0,
    size: 0,
    tz: getBrowserTimezone(),
  };

  const {
    data: {
      pagination: { total: myNewIntelCounters },
    },
  } = await fetchIntel({
    query: {
      ...omit(myNewIntelPreset, 'dateRange'),
      ...commonParams,
      relatedAlerts: options?.relatedAlerts,
      aggregate: false,
    },
  });

  /**
   * GET PRESET COUNTERS FOR LEFT MENU FILTERING FOR DEFAULT STATES (DOESN'T INCLUDE DELETED)
   */
  const {
    data: { aggregations: countersResponse },
  } = await fetchIntel({
    query: {
      ...commonParams,
      states: DEFAULT_STATES_IF_EMPTY,
      relatedAlerts: options?.relatedAlerts,
      aggregate: true,
    },
  });

  /**
   * GET PRESET COUNTERS FOR DELETED
   */
  const {
    data: {
      pagination: { total: deletedCounters },
    },
  } = await fetchIntel({
    query: {
      ...commonParams,
      states: [State.Deleted],
      relatedAlerts: options?.relatedAlerts,
    },
  });

  /**
   * GET COUNTER FOR "ALL INTEL" PRESET
   */
  const allIntelCounters = Object.entries(
    countersResponse?.stateCount || {},
  ).reduce(
    (acc, [key, value]) => (ALL_INTEL_STATES.includes(key) ? acc + value : acc),
    0,
  );

  const { stateCount, priorityCount } = countersResponse || {};
  const { publishedCount } = countersResponse || {};

  return new Promise((resolve) =>
    setTimeout(
      () =>
        resolve({
          ...stateCount,
          deleted: deletedCounters,
          ...priorityCount,
          myNewIntel: myNewIntelCounters,
          allIntel: allIntelCounters,
          published: publishedCount?.true,
        }),
      /**
       * Seems that sometimes the API doesn't update the counters too fast. A delay was added
       * to re-fetch counters after X seconds, useful for when an intel gets updated.
       * Not the best solution though.
       */
      options?.delay ? THREE_SECOND_IN_MS : 0,
    ),
  );
};

export const getMergedCounters = ({
  aggregationsCounter,
  generalCounters,
}: {
  aggregationsCounter?: IntelCountersType;
  generalCounters: IntelCountersType;
}): IntelCountersType => {
  const counters: IntelCountersType = mergeWith(
    aggregationsCounter,
    generalCounters,
    (aggregationsCount = 0, count = 0, key) => {
      if (isObject(aggregationsCount) || isArray(aggregationsCount)) return;

      const isNotDateRange = !dateRangeFilterKeys.includes(key);

      if (
        aggregationsCount > 0 &&
        count > 0 &&
        aggregationsCount !== count &&
        isNotDateRange
      ) {
        return aggregationsCount + count;
      }

      return Math.max(aggregationsCount, count);
    },
  );

  return counters;
};
