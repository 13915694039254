import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { KlueCardSentimentEnum } from 'api/api.types';
import { useCardInteractionLog } from 'components/common/klueCard/hooks';
import { AnalyticsAction } from 'lib/analytics';
import store, { type Dispatch } from 'store';

import type { KlueCardMenuProps } from 'components/common/klueCard/partials/klue-card-menu';

export type UseCardSentimentProps = Pick<KlueCardMenuProps, 'cardId'> & {
  interactionLabel: 'toolbar' | 'card-viewer';
  rank?: number | null;
};

export default function useCardSentiment(props: UseCardSentimentProps) {
  const { cardId, interactionLabel, rank } = props;
  const userData = useSelector(store.select.auth.userData);
  const logCardInteraction = useCardInteractionLog();
  const [isSentimentButtonLoading, setIsSentimentButtonLoading] = useState<{
    [KlueCardSentimentEnum.POSITIVE]?: boolean;
    [KlueCardSentimentEnum.NEGATIVE]?: boolean;
  }>({
    [KlueCardSentimentEnum.POSITIVE]: false,
    [KlueCardSentimentEnum.NEGATIVE]: false,
  });
  const isSentimentActive = (sentimentType: KlueCardSentimentEnum) => {
    return sentimentSelected === sentimentType;
  };

  const [sentimentSelected, setSentimentSelected] =
    useState<KlueCardSentimentEnum | null>(() => {
      if (userData?.cardSentiment && cardId) {
        return userData.cardSentiment[cardId];
      }
      return null;
    });
  const {
    auth: { updateUserCardSentiment, removeUserCardSentiment },
  } = useDispatch<Dispatch>();

  const trackEvent = (
    feedbackType: KlueCardSentimentEnum | 'removed_feedback',
  ) => {
    if (!cardId) return;
    const actionObject = {
      [KlueCardSentimentEnum.POSITIVE]: AnalyticsAction.positiveFeedback,
      [KlueCardSentimentEnum.NEGATIVE]: AnalyticsAction.negativeFeedback,
      removed_feedback: AnalyticsAction.removedFeedback,
    };

    logCardInteraction({
      cardId,
      action: actionObject[feedbackType],
      label: interactionLabel,
      rank,
    });
  };

  const onSentimentButtonClick = async (
    sentimentType: KlueCardSentimentEnum,
  ) => {
    setIsSentimentButtonLoading({ [sentimentType]: true });
    try {
      if (!cardId) return;
      if (sentimentType === sentimentSelected) {
        setSentimentSelected(null);
        await removeUserCardSentiment({ cardId });
        trackEvent('removed_feedback');
        return;
      }

      setSentimentSelected(sentimentType);
      await updateUserCardSentiment({ cardId, sentimentType });
      trackEvent(sentimentType);
    } catch (error) {
      setSentimentSelected(null);
    } finally {
      setIsSentimentButtonLoading({ [sentimentType]: false });
    }
  };

  useEffect(() => {
    if (userData?.cardSentiment && cardId) {
      setSentimentSelected(userData.cardSentiment[cardId]);
    }
  }, [cardId, userData?.cardSentiment]);

  return {
    isSentimentButtonLoading,
    isSentimentActive,
    onSentimentButtonClick,
  };
}
