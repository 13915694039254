/* eslint-disable max-lines */
import { createModel } from '@rematch/core';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import {
  fetchIntel,
  removeIntelRival,
  updateIntelCardData,
  updateIntelCardsData,
  unpublishIntel,
  fetchIntelCounters,
  fetchIntelSummary,
  State,
  fetchIntelByJobId,
  cancelIntelByJobId,
} from 'api/endpoints';
import { isRetryable } from 'lib/api/utils';
import { getBrowserTimezone } from 'lib/utils';
import { FETCH_PRESETS_COUNTERS_INTERVAL } from 'pages/alerts/constants';
import store, { type StoreSelectors } from 'store';
import { initialState as initialFilterState } from 'store/models/intel-filters/intelFilters.model';
import { mergeArrayValues } from 'store/utils';
import { sendExtensionReloadAlertMessage } from 'utils/extension/sendMessageToExtension';

import { getMergedCounters, getPresetCounters } from './intelCards.utils';

import type {
  Topic,
  IntelListItemType,
  UpdateIntelBodyType,
  SummarizeIntelBodyType,
  PaginationShapeType,
  IntelQueryParamsType,
  IntelCountersType,
} from 'api/endpoints';
import type { ApiErrorType } from 'lib/api';
import type { RootModel } from 'store/models';

export type StateType = {
  byId: Map<string, IntelListItemType>;
  allIds: Set<string>;
  isLoading: boolean;
  loadingCurrentView?: string | null;
  errors: any;
  bulkEditIds: Set<string>;
  publishingIds: Set<string>;
  isLoadingPresetCounters: boolean;
  counters: IntelModelCountersType;
  retryQueue: RetryQueueItemType[];
  isBulkEditByQuery: boolean;
  bulkActionsJob: BulkActionsJobType;
  blocklistedDomains: { domain: string }[];
  isLoadingWim: boolean;
};

export type BulkActionsJobType = {
  job: JobType | null;
  jobDetails: JobDetailsType | null;
  jobDetailsError: any;
  data: UpdateIntelBodyType;
};

export type JobType = { jobId: string; completedNow: null | number };

export type JobDetailsType = {
  completedAt: string | null;
  tasksCompleted: number;
  tasksTotal: number | null;
};

export type IntelModelCountersType = {
  presets: PresetsCountersType;
  filters: IntelCountersType;
};

export type PresetsCountersType = {
  myNewIntel: number | null;
  allIntel: number | null;
  unprocessed: number | null;
  working: number | null;
  archived: number | null;
  deleted: number | null;
  important: number | null;
  interesting: number | null;
  published: number | null;
};

export type TopicPayload = {
  intelId: string;
  topic: Topic;
};

export type FetchIntelListPayloadType = {
  pagination?: Partial<PaginationShapeType>;
  states?: string | null;
  priorities?: string | null;
  createdAfter?: string | null;
  createdBefore?: string | null;
  published?: string | null;
  rehydrate?: boolean;
};

export type PopulatePayload = {
  cards: IntelListItemType[];
  rehydrate?: boolean;
};

export type RetryQueueItemType = {
  action: any;
  data: any;
};

export type SideEffectOptionsType = {
  shouldUpdateCard?: boolean;
};

export const initialState = {
  byId: new Map(),
  allIds: new Set(),
  isLoading: false,
  bulkEditIds: new Set(),
  isBulkEditByQuery: false,
  publishingIds: new Set(),
  isLoadingPresetCounters: false,
  loadingCurrentView: null,
  counters: {
    presets: {
      myNewIntel: null,
      allIntel: null,
      unprocessed: null,
      working: null,
      archived: null,
      deleted: null,
      important: null,
      interesting: null,
      published: null,
    },
    filters: {},
  },
  retryQueue: [],
  bulkActionsJob: {
    job: null,
    jobDetails: null,
    jobDetailsError: null,
    data: null,
  },
  blocklistedDomains: [],
  isLoadingWim: false,
} as any as StateType;

type CardStateManagementPayload = {
  intelId: string;
  data: UpdateIntelBodyType;
  sideEffectOptions?: SideEffectOptionsType;
};

type CardSummaryManagementPayload = {
  intelId: string;
  data: SummarizeIntelBodyType;
  sideEffectOptions?: SideEffectOptionsType;
};

type CardsStateManagementPayload = {
  intelIds?: string[];
  data: UpdateIntelBodyType;
  sideEffectOptions?: SideEffectOptionsType;
  filters?: IntelQueryParamsType;
};

export const intelCards = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (state: StateType, { cards, rehydrate }: PopulatePayload) => {
      if (rehydrate) {
        return {
          ...state,
          byId: mergeArrayValues(new Map(), cards, 'id'),
          allIds: new Set([...cards.map((card) => card.id)]),
        };
      }

      return {
        ...state,
        byId: mergeArrayValues(state.byId, cards, 'id'),
        allIds: new Set([...state.allIds, ...cards.map((card) => card.id)]),
      };
    },
    setIsLoading: (state, value: boolean) => {
      return {
        ...state,
        isLoading: value,
      };
    },
    setData: (state, value: any) => {
      return {
        ...state,
        bulkActionsJob: { ...state.bulkActionsJob, data: value },
      };
    },
    setJobDetailsError: (state, value: any) => {
      return {
        ...state,
        bulkActionsJob: { ...state.bulkActionsJob, jobDetailsError: value },
      };
    },
    setJob: (
      state,
      { jobId, completedNow }: { jobId: string; completedNow: number | null },
    ) => {
      return {
        ...state,
        bulkActionsJob: {
          ...state.bulkActionsJob,
          job: { jobId, completedNow },
        },
      };
    },
    setJobDetails: (
      state,
      {
        completedAt,
        tasksCompleted,
        tasksTotal,
      }: {
        completedAt: string | null;
        tasksCompleted: number;
        tasksTotal: number | null;
      },
    ) => {
      return {
        ...state,
        bulkActionsJob: {
          ...state.bulkActionsJob,
          jobDetails: { completedAt, tasksCompleted, tasksTotal },
        },
      };
    },
    setIntelListError: (state, error: ApiErrorType) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          intelList: error,
        },
      };
    },
    setIsLoadingPresetCounters: (state, value: boolean) => {
      return {
        ...state,
        isLoadingPresetCounters: value,
      };
    },
    setBlocklistedDomains: (state: StateType, value) => ({
      ...state,
      blocklistedDomains: value,
    }),
    setPublishingIds: (
      state,
      { add, remove }: { add?: { id: string }; remove?: { id: string } },
    ) => {
      const newSet = new Set(state.publishingIds);

      add?.id && newSet.add(add.id);
      remove?.id && newSet.delete(remove.id);

      return {
        ...state,
        publishingIds: newSet,
      };
    },
    setBulkEditIds: (
      state,
      { add, remove }: { add?: { id: string }; remove?: { id: string } },
    ) => {
      const newSet = new Set(state.bulkEditIds);

      add?.id && newSet.add(add.id);
      remove?.id && newSet.delete(remove.id);

      return {
        ...state,
        bulkEditIds: newSet,
      };
    },
    setBulkEditAllIds: (state) => {
      const newSet = new Set(state.allIds);

      return {
        ...state,
        bulkEditIds: newSet,
      };
    },
    removeAllBulkEditIds: (state) => {
      const newSet = new Set([]);

      return {
        ...state,
        bulkEditIds: newSet,
        isBulkEditByQuery: false,
      };
    },
    setBulkEditByQuery: (state, value: boolean) => {
      return {
        ...state,
        isBulkEditByQuery: value,
      };
    },
    resetState: (state) => {
      return { ...initialState, counters: { ...state.counters } };
    },
    removeIntelFromState: (state, intelId: string) => {
      const byId = new Map(state.byId);
      const allIds = new Set(state.allIds);

      byId.delete(intelId);
      allIds.delete(intelId);

      return {
        ...state,
        byId,
        allIds,
      };
    },
    updateCounters: (
      state,
      {
        type,
        counters,
      }: {
        type: 'filters' | 'presets';
        counters: Partial<IntelCountersType> | Partial<PresetsCountersType>;
      },
    ) => {
      return {
        ...state,
        counters: {
          ...state.counters,
          [type]: {
            ...state.counters[type],
            ...counters,
          },
        },
      };
    },
    addToRetryQueue: (state, { action, data }: RetryQueueItemType) => ({
      ...state,
      retryQueue: [...state.retryQueue, { action, data }],
    }),
    resetRetryQueue: (state: StateType) => ({
      ...state,
      retryQueue: [],
    }),
    resetFiltersCounters: (state: StateType) => ({
      ...state,
      counters: {
        ...state.counters,
        filters: { ...initialState.counters.filters },
      },
    }),
    resetJobDetails: (state: StateType) => ({
      ...state,
      bulkActionsJob: {
        ...state.bulkActionsJob,
        job: null,
        jobDetails: null,
        jobDetailsError: null,
      },
    }),
    setLoadingCurrentView: (
      state: StateType,
      value: typeof initialState.loadingCurrentView,
    ) => ({
      ...state,
      loadingCurrentView: value,
    }),
    setIsLoadingWim: (state, value: boolean) => {
      return {
        ...state,
        isLoadingWim: value,
      };
    },
  },
  selectors: (slice, createSelector) => ({
    byId() {
      return slice(({ byId }) => byId);
    },
    bulkEditIds() {
      return slice(({ bulkEditIds }) => bulkEditIds);
    },
    allIds() {
      return slice(({ allIds }) => allIds);
    },
    getNewIntelList() {
      return createSelector(
        this.allIds as any,
        (allIds): string[] => Array.from(allIds) as string[],
      );
    },
    getTriageListByIds(models: StoreSelectors) {
      return createSelector(
        this.byId as any, // @TODO Can we fix TypeScript?
        models.triage.cardIds as any,
        models.triage.getActionedCardsIds as any,
        (
          byId: Map<string, IntelListItemType>,
          triageCardIds: string[],
          actionedCardsIds: string[],
        ): string[] => {
          const cards: string[] = [];

          triageCardIds.forEach((id) => {
            if (actionedCardsIds.includes(id)) return;

            cards.push(id);
          });

          return cards;
        },
      );
    },
    isBulkEditByQuery() {
      return slice(({ isBulkEditByQuery }) => isBulkEditByQuery);
    },
  }),
  effects: (dispatch) => ({
    async fetchIntelList(
      {
        rehydrate = false,
        isInTriageMode = false,
        filters,
        pagination,
      }: {
        filters?: IntelQueryParamsType;
        pagination?: Partial<PaginationShapeType>;
        rehydrate?: boolean;
        isInTriageMode?: boolean;
      },
      rootState,
    ) {
      const { view: currentView } = filters || {};

      try {
        if (rehydrate) {
          this.resetState();
          dispatch.intelFilters?.resetPagination();
          dispatch.triage.resetTriageMode();
        }

        dispatch.intelCards.setLoadingCurrentView(currentView);

        const paginationData = rehydrate
          ? { ...initialFilterState.pagination }
          : pagination;

        const validFiltersValues = {
          ...omitBy(filters, isUndefined),
        } as IntelQueryParamsType;

        const hasCountersPresets =
          Object.values(rootState.intelCards.counters.presets).filter(
            (counter) => (counter ?? 0) > 0,
          ).length > 0;

        if (!hasCountersPresets && currentView) {
          const fetchPresetsCounters = async () => {
            this.setIsLoadingPresetCounters(true);
            const presetCounters = await getPresetCounters({
              relatedAlerts:
                rootState.intelFilters.filters.relatedAlerts ?? false,
            });
            this.updateCounters({
              type: 'presets',
              counters: {
                ...presetCounters,
              },
            });
            this.setIsLoadingPresetCounters(false);
          };

          fetchPresetsCounters();

          setInterval(() => {
            fetchPresetsCounters();
          }, FETCH_PRESETS_COUNTERS_INTERVAL);
        }

        if (pagination?.offset === 0) {
          this.setIsLoading(true);
        }

        if (currentView) {
          const [
            {
              data: { hits, pagination: responsePagination, aggregations },
            },
            { data: intelFilterCounters },
          ] = await Promise.all([
            fetchIntel({
              query: {
                ...validFiltersValues,
                offset: paginationData?.offset,
                size:
                  paginationData?.size ||
                  rootState.intelFilters.pagination.size,
                tz: getBrowserTimezone(),
                aggregate: true,
              },
            }).catch((error) => {
              if (isRetryable(error)) {
                this.addToRetryQueue({
                  action: this.fetchIntelList,
                  data: {
                    rehydrate,
                    filters,
                    pagination,
                  },
                });
              }

              throw error;
            }),
            fetchIntelCounters({
              query: {
                ...validFiltersValues,
                tz: getBrowserTimezone(),
              },
            }),
          ]);

          dispatch.intelFilters?.setPaginationInfo({
            ...responsePagination,
            nextOffset:
              responsePagination.nextOffset ?? responsePagination.total,
          });

          const filterView = filters?.view;
          const { loadingCurrentView } = store.getState().intelCards;

          if (filterView === loadingCurrentView) {
            this.populate({ cards: hits, rehydrate });
          }

          if (isInTriageMode) {
            dispatch.triage.setTriageCardIds(hits.map((card) => card.id));
          }

          const updatedCounters = getMergedCounters({
            aggregationsCounter: aggregations,
            generalCounters: intelFilterCounters,
          });

          this.updateCounters({
            type: 'filters',
            counters: {
              ...updatedCounters,
              createdAtCount: {
                ...(updatedCounters.createdAtCount as any),
                all: intelFilterCounters.createdAtCount.all,
                yesterday:
                  Number(updatedCounters.createdAtCount?.['now-1d/d']) -
                  Number(updatedCounters.createdAtCount?.['now-0d/d']),
              },
            },
          });
        }
      } catch (error) {
        this.setIsLoading(true);
        throw error;
      } finally {
        if (store.getState().intelCards.loadingCurrentView === currentView) {
          this.setIsLoading(false);
          dispatch.intelCards.setLoadingCurrentView(null);
        }

        dispatch.intelFilters.setIsFetchingNextPage(false);
      }
    },
    rehydrate() {
      this.resetState();
      dispatch.intelFilters?.resetPagination();
      dispatch.triage.resetTriageMode();
    },
    removeRival(
      { intelId, rivalId }: { intelId: string; rivalId: number },
      rootState,
    ) {
      try {
        const { intelCards } = rootState;
        const currentIntelCard = intelCards.byId.get(intelId);

        if (!currentIntelCard) return;

        currentIntelCard.rivals = [
          ...currentIntelCard.rivals.filter((rival) => rival.id !== rivalId),
        ];

        removeIntelRival({
          path: {
            intelId: encodeURIComponent(intelId),
            rivalId: rivalId,
          },
        }).catch(() => {
          this.addToRetryQueue({
            action: this.removeRival,
            data: {
              intelId,
              rivalId,
            },
          });
        });

        this.populate({ cards: [currentIntelCard] });
        sendExtensionReloadAlertMessage(intelId);
      } catch (error) {
        throw error;
      }
    },
    async fetchCounts(_, rootState) {
      const presetCounters = await getPresetCounters({
        delay: true,
        relatedAlerts: rootState.intelFilters.filters.relatedAlerts ?? false,
      });

      this.updateCounters({
        type: 'presets',
        counters: {
          ...presetCounters,
        },
      });

      const validFiltersValues = {
        ...omitBy(rootState.intelFilters.filters, isUndefined),
      };

      const [
        {
          data: { pagination: responsePagination, aggregations },
        },
        { data: intelFilterCounters },
      ] = await Promise.all([
        fetchIntel({
          query: {
            ...validFiltersValues,
            tz: getBrowserTimezone(),
            aggregate: true,
          },
        }),
        fetchIntelCounters({
          query: {
            ...validFiltersValues,
            tz: getBrowserTimezone(),
          },
        }),
      ]);

      dispatch.intelFilters?.setPaginationInfo({
        total: responsePagination.total,
        nextOffset: Number(responsePagination.nextOffset),
      });

      const updatedCounters = getMergedCounters({
        aggregationsCounter: aggregations,
        generalCounters: intelFilterCounters,
      });

      this.updateCounters({
        type: 'filters',
        counters: {
          ...updatedCounters,
        },
      });
    },
    async updateIntelCard(
      { intelId, data, sideEffectOptions }: CardStateManagementPayload,
      rootState,
    ) {
      try {
        const { data: updatedIntel } = await updateIntelCardData({
          path: {
            intelId: encodeURIComponent(intelId),
          },
          params: {
            ...data,
          },
        }).catch((error) => {
          this.addToRetryQueue({
            action: this.updateIntelCard,
            data: {
              intelId,
              data,
            },
          });
          throw error;
        });
        const currentIntelCard = store.getState().intelCards.byId.get(intelId);
        if (!currentIntelCard) return;
        const willChangeState = updatedIntel.state !== currentIntelCard?.state;
        const updateDataKeys = Object.keys(data);
        const isOnlyUpdatingState =
          updateDataKeys.length === 1 &&
          updateDataKeys[0] === 'state' &&
          willChangeState;
        updatedIntel.rank = currentIntelCard.rank;
        updatedIntel.digests = currentIntelCard.digests;

        if (!isOnlyUpdatingState || sideEffectOptions?.shouldUpdateCard) {
          this.populate({
            cards: [updatedIntel],
          });
        }
        this.setIsLoadingWim(false);
        sendExtensionReloadAlertMessage(intelId);
      } catch (error) {
        throw error;
      }
    },
    updateIntelCardTitle({
      intelId,
      curatedTitle,
    }: {
      intelId: string;
      curatedTitle: string;
    }) {
      const currentIntelCard = store.getState().intelCards.byId.get(intelId);
      if (!currentIntelCard) return;
      const updatedIntel = {
        ...currentIntelCard,
        curatedTitle: curatedTitle,
      };

      this.populate({
        cards: [updatedIntel],
      });
    },
    async fetchIntelJobId(jobId) {
      try {
        const response = await fetchIntelByJobId({
          path: {
            jobId,
          },
        }).catch((error) => {
          if (error.name !== 'AbortError') {
            this.setJobDetailsError(error);
          }
          throw error;
        });
        this.setJobDetails({
          completedAt: response.data.completedAt,
          tasksCompleted: response.data.tasksCompleted,
          tasksTotal: response.data.tasksTotal,
        });
      } catch (error) {
        throw error;
      }
    },
    async cancelIntelByJobId(jobId) {
      try {
        await cancelIntelByJobId({
          path: {
            jobId,
          },
        }).catch((error) => {
          throw error;
        });
      } catch (error) {
        throw error;
      }
    },
    async updateIntelCards({ intelIds, data }: CardsStateManagementPayload) {
      try {
        const { name, ...rest } = data;
        this.setData(data);
        const response = await updateIntelCardsData({
          params: {
            intelIds,
            update: rest,
          },
        }).catch((error) => {
          this.setJobDetailsError(error);
          this.addToRetryQueue({
            action: this.updateIntelCards,
            data: {
              intelIds,
              data,
            },
          });
          throw error;
        });
        this.setJob({
          jobId: response.data.jobId,
          completedNow: response.data.completedNow,
        });
      } catch (error) {
        throw error;
      }
    },
    async fetchIntelCardSummary(
      { intelId, data }: CardSummaryManagementPayload,
      rootState,
    ) {
      try {
        const { intelCards } = rootState;
        const currentIntelCard = intelCards.byId.get(intelId);
        if (!currentIntelCard) return;
        const { data: updatedIntel } = await fetchIntelSummary({
          path: {
            intelId: encodeURIComponent(intelId),
          },
          params: data,
        }).catch((error) => {
          this.addToRetryQueue({
            action: this.fetchIntelCardSummary,
            data: {
              intelId,
              data,
            },
          });
          throw error;
        });
        this.populate({ cards: [updatedIntel] });
      } catch (error) {
        throw error;
      }
    },
    async updateIntelCardsByQuery({
      filters,
      data,
    }: CardsStateManagementPayload) {
      try {
        const validFiltersValues = {
          ...omitBy(filters, isUndefined),
        } as IntelQueryParamsType;
        const { name, ...rest } = data;
        this.setData(data);

        const response = await updateIntelCardsData({
          params: {
            updateAll: true,
            update: rest,
          },
          query: validFiltersValues,
        }).catch((error) => {
          this.setJobDetailsError(error);
          this.addToRetryQueue({
            action: this.updateIntelCardsByQuery,
            data: {
              data,
              updateAll: true,
              filters,
            },
          });
          throw error;
        });
        this.setJob({
          jobId: response.data.jobId,
          completedNow: response.data.completedNow,
        });
      } catch (error) {
        throw error;
      }
    },
    async unpublish({ intelId }: { intelId: string }, rootState) {
      try {
        const { intelCards } = rootState;
        const currentIntelCard = intelCards.byId.get(intelId);
        if (!currentIntelCard) return;

        if (currentIntelCard.publishedAt) {
          const { data: updatedIntel } = await unpublishIntel({
            path: {
              intelId: encodeURIComponent(intelId),
            },
          }).catch((error) => {
            this.addToRetryQueue({
              action: this.unpublish,
              data: {
                intelId,
              },
            });

            throw error;
          });
          updatedIntel.rank = currentIntelCard.rank;
          this.populate({ cards: [updatedIntel] });
        }
        sendExtensionReloadAlertMessage(intelId);
      } catch (error) {
        throw error;
      }
    },
    async publishToFeed({ intelId }: { intelId: string }, rootState) {
      dispatch.intelCards.setPublishingIds({
        add: {
          id: intelId,
        },
      });

      try {
        const currentIntelCard = rootState.intelCards.byId.get(intelId);

        const updateIntelData: Partial<UpdateIntelBodyType> = {
          state: State.Archived,
          publishIntel: true,
        };
        const isPublished =
          currentIntelCard?.publishedAt && currentIntelCard?.postId;
        const isArchived = currentIntelCard?.state === State.Archived;

        if (isPublished && !isArchived) {
          delete updateIntelData.publishIntel;
        }

        const { data: updatedIntel } = await updateIntelCardData({
          path: {
            intelId: encodeURIComponent(intelId),
          },
          params: updateIntelData,
        }).catch((error) => {
          this.addToRetryQueue({
            action: this.updateIntelCard,
            data: {
              intelId,
              data: updateIntelData,
            },
          });
          throw error;
        });

        this.populate({
          cards: [{ ...updatedIntel, rank: currentIntelCard?.rank }],
        });
        sendExtensionReloadAlertMessage(intelId);
      } catch (err) {
        throw err;
      } finally {
        dispatch.intelCards.setPublishingIds({
          remove: {
            id: intelId,
          },
        });
      }
    },
    async publishAllToFeed({ intelIds }: { intelIds: string[] }) {
      try {
        await Promise.all(
          intelIds.map(async (intelId: string) => {
            await dispatch.intelCards.publishToFeed({ intelId });
          }),
        );
      } catch (error) {
        throw error;
      }
    },
    retryFailedActions(_: any, rootState) {
      const { retryQueue } = rootState.intelCards;
      retryQueue.forEach(({ action, data }) => {
        action(data);
      });
      this.resetRetryQueue();
    },
    async deleteRelatedAlert(
      {
        intelId,
        relatedAlertIntelId,
      }: { intelId: string; relatedAlertIntelId: string },
      rootState,
    ) {
      try {
        const { data: updatedRelatedIntel } = await updateIntelCardData({
          path: {
            intelId: encodeURIComponent(relatedAlertIntelId),
          },
          params: {
            state: State.Deleted,
          },
        }).catch((error) => {
          this.addToRetryQueue({
            action: this.deleteRelatedAlert,
            data: {
              intelId,
              relatedAlertIntelId,
            },
          });
          throw error;
        });

        const { intelCards } = rootState;
        const currentIntelCard = intelCards.byId.get(intelId);
        if (!currentIntelCard) return;
        currentIntelCard.relatedAlerts =
          currentIntelCard?.relatedAlerts?.filter(
            (relatedAlert) => relatedAlert.id !== relatedAlertIntelId,
          );
        this.populate({
          cards: [updatedRelatedIntel],
        });
      } catch (error) {
        throw error;
      }
    },
  }),
});
