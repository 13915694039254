type Props = {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
};

function PulseIcon({ width = '16', height = '16', style }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M15.5 8.00001C15.5 8.13262 15.4473 8.25979 15.3536 8.35356C15.2598 8.44733 15.1326 8.50001 15 8.50001H13.3088L10.9475 13.2238C10.9059 13.3068 10.842 13.3767 10.763 13.4255C10.684 13.4743 10.5929 13.5001 10.5 13.5H10.475C10.378 13.4952 10.2845 13.4623 10.2059 13.4052C10.1274 13.3482 10.0671 13.2695 10.0325 13.1788L6.45937 3.79751L4.455 8.20688C4.41529 8.29426 4.35127 8.36836 4.27059 8.42034C4.18991 8.47231 4.09597 8.49997 4 8.50001H2C1.86739 8.50001 1.74021 8.44733 1.64645 8.35356C1.55268 8.25979 1.5 8.13262 1.5 8.00001C1.5 7.8674 1.55268 7.74022 1.64645 7.64646C1.74021 7.55269 1.86739 7.50001 2 7.50001H3.67812L6.045 2.29313C6.0858 2.20328 6.15229 2.12751 6.23607 2.07537C6.31985 2.02323 6.41719 1.99705 6.51583 2.00012C6.61447 2.00319 6.70999 2.03539 6.79036 2.09265C6.87074 2.1499 6.93237 2.22966 6.9675 2.32188L10.5619 11.7594L12.5525 7.77751C12.5939 7.69421 12.6577 7.6241 12.7368 7.57508C12.8158 7.52606 12.907 7.50006 13 7.50001H15C15.1326 7.50001 15.2598 7.55269 15.3536 7.64646C15.4473 7.74022 15.5 7.8674 15.5 8.00001Z"
        fill="#343330"
      />
    </svg>
  );
}

export default PulseIcon;
