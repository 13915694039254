import {
  Box,
  Banner,
  Button,
  Icon,
  List,
  Text,
  fontWeight,
  tokens,
  type BannerProps,
  type ListItemType,
} from '@kluein/klue-ui';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';

import store, { type Dispatch, type RootState } from 'store';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import { PREVIEWING_BANNER_HEIGHT } from './constants';

import { PreviewExitInfoDialog } from '../preview-exit-info-dialog';

function PreviewingBanner() {
  const [, setPreviewing] = useQueryParam('previewing', NumberParam);
  const navigate = useNavigate();
  const [hideDropState, setHideDropState] = useState<boolean>(false);

  const { t } = useTranslation();
  const fullAccessGroup = {
    id: 0,
    name: t('Common:visibilityTypeTitles.fullAccessUsers'),
  };
  const visibilityGroup =
    useSelector(store.select.auth.visibilityGroup) || fullAccessGroup;
  const groupName = visibilityGroup.name;

  const {
    auth: { setVisibilityGroup },
    visibilityGroups: { fetchVisibilityGroupsFromApi },
  } = useDispatch<Dispatch>();
  useEffect(() => {
    fetchVisibilityGroupsFromApi();
  }, [fetchVisibilityGroupsFromApi]);

  const visibilityGroups = useSelector<RootState, ListItemType[]>((state) =>
    (state.visibilityGroups.sorted as { id: number; name: string }[])
      .concat([fullAccessGroup])
      .filter(({ id }) => visibilityGroup.id !== id)
      .map(({ id, name }) => ({ key: id.toString(), label: name })),
  );
  const setPreviewingAndReloadPage = (previewing: number | null = null) => {
    setVisibilityGroup(previewing);
    setPreviewing(previewing, 'replaceIn');
    // Reload
    navigate(0);
  };

  const dynamicBannerProps: Partial<BannerProps> = {};
  const dropContent = (
    <Box
      background={tokens.color.neutral.white.main}
      gap="hair"
      pad="small"
      data-test-id={TEST_IDS.dashboard.filterNavBar.group.dropContent}
      style={{ display: hideDropState ? 'none' : 'block' }}
    >
      <List
        a11yTitle={t('Common:visibilityGroups')}
        truncateLength={40}
        itemProps={{
          size: 'medium',
        }}
        onClickItem={(item: ListItemType) => {
          // "Full Access Users" gets appended to list too quickly after
          // clicking a real visibility group, before page reloads.
          // Hide the List as soon as an item is clicked.
          setHideDropState(true);
          setPreviewingAndReloadPage(Number(item.key));
        }}
        data={visibilityGroups}
      />
      {visibilityGroups.length > 0 && (
        <Box
          flex={{ grow: 1 }}
          background={tokens.color.neutral.lightgrey.main}
          height="hair"
          margin={{ vertical: 'xxsmall' }}
        />
      )}
      <Button
        fill
        variant="flat-menu"
        onClick={() => setPreviewingAndReloadPage()}
        leftSlot={<Icon.Logout />}
        label={t('Common:notification.previewing.exitPreview')}
        data-test-id={TEST_IDS.layout.previewBanner.resetButton}
        data-tracking-id={TRACKING_IDS.global.previewBanner.reset}
      />
    </Box>
  );

  dynamicBannerProps.children = (
    <Box gap="xxsmall" direction="row" align="center">
      <Text
        color={tokens.color.neutral.white.main}
        size="large"
        fontWeight="semibold"
      >
        {t('Common:notification.previewing.prefix')}:
      </Text>
      <Button
        variant="inverted-plain"
        label={groupName}
        size="large"
        dropContent={dropContent}
        dropAlign="right"
        style={{ fontWeight: fontWeight.semibold }}
        icon={<Icon.Down size="small" />}
        data-test-id={TEST_IDS.layout.previewBanner.groupSwitcher}
        data-tracking-id={TRACKING_IDS.dashboard.filters.groupDrop.button}
      />
    </Box>
  );

  return (
    <Banner
      dataTestId={TEST_IDS.layout.previewBanner.container}
      variant="global"
      showIcon
      show
      height={{ height: PREVIEWING_BANNER_HEIGHT }}
      pad="none"
      {...dynamicBannerProps}
    />
  );
}

function PreviewBanner() {
  // Previewing Banner
  const visibilityGroupId = useSelector(store.select.auth.visibilityGroupId);

  if (visibilityGroupId === null) {
    return null;
  }
  return (
    <>
      <PreviewExitInfoDialog />
      <PreviewingBanner />
    </>
  );
}

export default memo(PreviewBanner);
