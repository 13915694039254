import type { RootState } from 'store';

/* eslint-disable @typescript-eslint/no-unused-expressions */
type PopulateEntitiesFromType = {
  entities: Record<any, any>;
  dispatch: any;
};

export const populateEntitiesFrom = ({
  entities,
  dispatch,
}: PopulateEntitiesFromType) => {
  Object.entries(entities).forEach(([moduleName, values]) => {
    dispatch[moduleName]?.populate({
      [moduleName]: values,
    });
  });
};

export const mergeArrayValues = (
  currentMap: Map<any, any>,
  newData: Array<any>,
  key: string,
) => {
  const byId = new Map(currentMap);
  newData.forEach((item) => {
    const keyValue = item[key];
    if (keyValue) {
      byId.set(keyValue, item);
    }
  });

  return byId;
};

export const mergeMapValues = (
  currentMap: Map<any, any>,
  newData: Record<any, { [key: string]: any }>,
) => {
  const byId = new Map(currentMap);

  Object.entries(newData).forEach(([key, newValues]) => {
    const item = byId.get(key.toString());

    byId.set(key, item ? { ...item, ...newValues } : newValues);
  });

  return byId;
};

export const toggleValueFromSetList = (
  list: Set<string>,
  key: string,
  nextValue: boolean,
) => {
  const listCopy = new Set(list);

  nextValue ? listCopy.add(key) : listCopy.delete(key);

  return listCopy;
};

export const addVisibilityGroupIdToQuery = (
  rootState: RootState,
  query: { visibilityGroupId?: number },
) => {
  const visibilityGroup = rootState.auth.visibilityGroup;
  if (visibilityGroup !== null) {
    query.visibilityGroupId = visibilityGroup;
  }
};
