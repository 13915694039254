import { Box } from '@kluein/klue-ui';
import { useSelector } from 'react-redux';

import { useAuth } from 'contexts/auth';
import TEST_IDS from 'test-ids';

import RivalItem from './RivalItem';

import type { RecentlyViewedType } from 'api/api.types';
import type { RootState } from 'store';
import type { StateType } from 'store/models/rivals/rivals.model';

type RecentlyViewedProps = {
  currentProfileId?: number;
  onNavigate: VoidFunction;
};

const RecentlyViewed = (props: RecentlyViewedProps) => {
  const { user } = useAuth();
  const recentlyViewed: RecentlyViewedType | undefined =
    user?.userData?.recentlyViewed;

  const rivals = useSelector<RootState, StateType>((state) => state.rivals);

  const recentlyViewedToDisplay =
    recentlyViewed?.profile.filter(({ id }) => {
      const rivalsArray = [...rivals.byId];

      return rivalsArray.some((item) => item[1].profileId === id);
    }) || [];

  if (recentlyViewedToDisplay.length) {
    return (
      <Box
        gap="xsmall"
        data-test-id={TEST_IDS.layout.quickSwitcher.recentlyViewed}
      >
        {recentlyViewedToDisplay.slice(0, 5).map((item) => (
          <RivalItem
            onNavigate={props.onNavigate}
            key={item.id}
            id={item.id}
            profileId={item.id}
            name={item.name}
            iconUrl={item.logo}
            currentProfileId={props.currentProfileId}
            profileButtonTestId={
              TEST_IDS.layout.quickSwitcher.recentlyViewedProfileButton
            }
            battlecardButtonTestId={
              TEST_IDS.layout.quickSwitcher.recentlyViewedBattlecardButton
            }
          />
        ))}
      </Box>
    );
  }

  return null;
};

export default RecentlyViewed;
