import { setEndpoint } from '../../../configure';

import type {
  ReviewsParamsType,
  ReviewsQueryType,
  ReviewsReturnType,
  UpdateReviewParamsType,
  UpdateReviewPathType,
  UpdateReviewReturnType,
} from './reviews.type';

export const [fetchReviews] = setEndpoint<
  any,
  ReviewsParamsType,
  ReviewsQueryType,
  ReviewsReturnType
>({
  path: '/review_insights/:supportedRivalId/reviews',
  id: 'fetchReviews',
  method: 'GET',
  namespace: 'reviewInsights',
});

export const [updateReview] = setEndpoint<
  UpdateReviewPathType,
  UpdateReviewParamsType,
  any,
  UpdateReviewReturnType
>({
  path: '/review_insights/:supportedRivalId/reviews/:reviewId',
  id: 'updateReview',
  method: 'PATCH',
  namespace: 'reviewInsights',
});
