type Props = {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
};

function PhoneIcon({ width = '16', height = '16', style }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M14.3981 10.4038L11.4538 9.08438L11.4456 9.08063C11.2928 9.01525 11.126 8.98901 10.9605 9.00429C10.7949 9.01957 10.6358 9.07588 10.4975 9.16813C10.4812 9.17888 10.4656 9.19057 10.4506 9.20313L8.92938 10.5C7.96563 10.0319 6.97063 9.04438 6.50251 8.09313L7.80126 6.54875C7.81376 6.53313 7.82563 6.5175 7.83688 6.50063C7.92715 6.36269 7.98192 6.20458 7.99631 6.04036C8.0107 5.87614 7.98428 5.71091 7.91938 5.55938V5.55188L6.59626 2.6025C6.51047 2.40454 6.36296 2.23964 6.17575 2.1324C5.98854 2.02517 5.77166 1.98135 5.55751 2.0075C4.71061 2.11894 3.93324 2.53486 3.37059 3.17756C2.80794 3.82027 2.49847 4.64581 2.50001 5.5C2.50001 10.4625 6.53751 14.5 11.5 14.5C12.3542 14.5015 13.1797 14.1921 13.8224 13.6294C14.4651 13.0668 14.8811 12.2894 14.9925 11.4425C15.0187 11.2284 14.975 11.0116 14.8679 10.8244C14.7607 10.6372 14.596 10.4896 14.3981 10.4038ZM11.5 13.5C9.37898 13.4977 7.3455 12.6541 5.84571 11.1543C4.34592 9.65451 3.50232 7.62102 3.50001 5.5C3.49765 4.88968 3.71754 4.29938 4.11859 3.83932C4.51964 3.37926 5.07444 3.08092 5.67938 3C5.67913 3.0025 5.67913 3.00501 5.67938 3.0075L6.99188 5.945L5.70001 7.49125C5.68689 7.50634 5.67498 7.52243 5.66438 7.53938C5.57033 7.6837 5.51515 7.84987 5.5042 8.02179C5.49325 8.19371 5.5269 8.36554 5.60188 8.52063C6.16813 9.67875 7.33501 10.8369 8.50563 11.4025C8.66186 11.4768 8.83468 11.5093 9.00722 11.4968C9.17976 11.4843 9.3461 11.4273 9.49001 11.3313C9.50605 11.3204 9.52149 11.3088 9.53626 11.2963L11.0556 10L13.9931 11.3156C13.9931 11.3156 13.9981 11.3156 14 11.3156C13.9201 11.9214 13.6222 12.4773 13.162 12.8794C12.7019 13.2814 12.111 13.5021 11.5 13.5Z"
        fill="#343330"
      />
    </svg>
  );
}

export default PhoneIcon;
