import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCardPermissionCardsData } from 'pages/profile/partials/sideAction/bulkEdit/cardPermissions/CardPermissions.utils';

import type { VisibilityGroupType } from 'api/api.types';
import type { RootState, Dispatch } from 'store';
import type { ToggleSelectedPayload } from 'store/models/profile/visibilityGroups.model';

const useVisibilityGroups = () => {
  const dispatch = useDispatch<Dispatch>();
  const visibilityGroups = useSelector<RootState, Array<VisibilityGroupType>>(
    (state) => state.visibilityGroups.sorted,
  );
  const visibleTo = useSelector<RootState, number>(
    (state) => state.visibilityGroups.visibleTo,
  );
  const selectedGroups = useSelector<RootState, Set<number>>(
    (state) => state.visibilityGroups.selectedGroups,
  );

  const isSelectedGroup = (id: number) =>
    Boolean(selectedGroups) && selectedGroups.has(id);

  const deselectAllGroups = useCallback(() => {
    dispatch.visibilityGroups.deselectAllGroups();
  }, [dispatch]);

  const toggleSelectedGroup = useCallback(
    (payload: ToggleSelectedPayload) => {
      dispatch.visibilityGroups.toggleSelectedGroup(payload);
    },
    [dispatch],
  );

  const setVisibleTo = useCallback(
    (id: number) => {
      dispatch.visibilityGroups.setVisibleTo({ id });
    },
    [dispatch],
  );

  const setSelectGroups = useCallback(
    (groups: Array<number>) => {
      deselectAllGroups();
      groups.forEach((id) => {
        toggleSelectedGroup({ id });
      });
    },
    [deselectAllGroups, toggleSelectedGroup],
  );

  useEffect(() => {
    dispatch.visibilityGroups.fetchVisibilityGroupsFromApi();
  }, [dispatch]);

  return {
    visibilityGroups,
    selectedGroups,
    visibleTo,
    isSelectedGroup,
    setVisibleTo,
    deselectAllGroups,
    toggleSelectedGroup,
    setSelectGroups,
    getCardPermissionCardsData,
  };
};

export default useVisibilityGroups;
