import { useSelector } from 'react-redux';

import TEST_IDS from 'test-ids';

import RivalItem from './RivalItem';

import type { RivalGroupRivalItemType } from 'api/api.types';
import type { RootState } from 'store';

type RivalConnectorType = {
  id: number;
  currentProfileId: number;
  onNavigate: VoidFunction;
};

const RivalConnector = (props: RivalConnectorType) => {
  const { id, currentProfileId, onNavigate } = props;

  const rival = useSelector<RootState, RivalGroupRivalItemType>((state) => {
    return (state as any).rivals.byId.get(id?.toString());
  });

  if (!rival?.id) {
    return null;
  }

  return (
    <RivalItem
      plain
      {...rival}
      currentProfileId={currentProfileId}
      profileButtonTestId={
        TEST_IDS.layout.quickSwitcher.rivalConnectorProfileButton
      }
      battlecardButtonTestId={
        TEST_IDS.layout.quickSwitcher.rivalConnectorBattlecardButton
      }
      onNavigate={onNavigate}
    />
  );
};

export default RivalConnector;
