import { createModel } from '@rematch/core';

import { doSearch, getFilters, mergeResults, setFilters } from './search.utils';

import type { RootModel } from '..';
import type { PopulatePayload as PopulateSearchRivalsPayloadType } from '../search-rivals/searchRivals.model';
import type { SearchResultCardType } from 'api/api.types';
import type {
  SearchQueryType,
  UpdatedStartType as LastUpdatedType,
} from 'api/endpoints/search.types';
import type { StoreSelectors } from 'store';

export const SEARCH_RESULTS_PAGE_SIZE = 10;

export type FilterType = { id: number; count: number };

export enum Sort {
  NAME = 'name',
  COUNT = 'count',
}

export type FiltersType = {
  searchTerms: string;
  byId: Map<number, FilterType>;
  selected: Set<number>;
  sort: Sort;
};

export type StateType = {
  error: boolean;
  loading: boolean;
  searchId: number;
  searchTerms: string;
  count: number;
  results: SearchResultCardType[];
  lastUpdatedFilter: LastUpdatedType | null;
  boardFilters: FiltersType;
  tagFilters: FiltersType;
  filters: Set<string>;
  prevSearch: {
    searchTerms: string;
    lastUpdatedFilter: LastUpdatedType | null;
    selectedBoardFilters: Set<number>;
    selectedTagFilters: Set<number>;
  } | null;
};

export type SearchParamsType = {
  searchTerms?: string;
  lastUpdatedFilter?: LastUpdatedType | null;
  selectedBoards?: Set<number>;
  selectedTags?: Set<number>;
};

export type PopulatePayloadType = {
  query: SearchQueryType;
  isNewSearch: boolean;
  count: number;
  cards: SearchResultCardType[];
  boardFilterCounts?: Map<number, FilterType>;
  tagFilterCounts?: Map<number, FilterType>;
};

export type DoSearchActions = {
  setPrevSearch: () => void;
  populate: (payload: PopulatePayloadType) => void;
  populateSearchRivals: (payload: PopulateSearchRivalsPayloadType) => void;
  setError: (error: boolean) => void;
};

export const loadingResults = {
  loading: true,
  error: false,
  offset: 0,
};

export const initialState = {
  searchId: 0,
  searchTerms: '',
  results: [],
  count: 0,
  ...loadingResults,
  lastUpdatedFilter: null,
  boardFilters: {
    searchTerms: '',
    isPopulated: false,
    byId: new Map(),
    selected: new Set(),
    sort: Sort.NAME,
  },
  tagFilters: {
    searchTerms: '',
    isPopulated: false,
    byId: new Map(),
    selected: new Set(),
    sort: Sort.NAME,
  },
  filters: new Set<string>(),
  prevSearch: null,
} as StateType;

export const search = createModel<RootModel>()({
  state: initialState,
  reducers: {
    reset: (_state) => initialState,
    // todo Consider deleting the ...loadingResults and explicitly call these reducers from effects
    setLoading: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    setError: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    populate: (
      state,
      {
        query,
        isNewSearch,
        count,
        cards,
        tagFilterCounts,
        boardFilterCounts,
      }: PopulatePayloadType,
    ) => {
      let tagFilters = state.tagFilters;
      if (tagFilterCounts) {
        tagFilters = {
          ...tagFilters,
          byId: tagFilterCounts,
        };
      }

      let boardFilters = state.boardFilters;
      if (boardFilterCounts) {
        boardFilters = {
          ...boardFilters,
          byId: boardFilterCounts,
        };
      }

      let searchId = state.searchId;
      if (isNewSearch) {
        searchId++;
      }

      return {
        ...state,
        loading: false,
        searchId,
        count,
        results: query.offset
          ? mergeResults({ prevResults: state.results, results: cards })
          : cards,
        tagFilters,
        boardFilters,
      };
    },
    setSearchTerms: (state: StateType, searchTerms: string) => ({
      ...state,
      ...loadingResults,
      searchTerms,
    }),
    setBoardFilters: (state: StateType, boards: Set<number>) =>
      setFilters(state, boards, 'board'),
    setTagFilters: (state: StateType, tags: Set<number>) =>
      setFilters(state, tags, 'tag'),
    setLastUpdatedFilter: (state, lastUpdated: LastUpdatedType | null) => {
      const filters = new Set<string>(state.filters);
      const prevFilter: string =
        [...filters].find((filter) => filter.startsWith('date')) || '';
      filters.delete(prevFilter);
      if (lastUpdated) {
        filters.add(`date-${lastUpdated}`);
      }
      return {
        ...state,
        ...loadingResults,
        lastUpdatedFilter: lastUpdated,
        filters,
      };
    },
    setBoardFilterSearchTerms: (state, searchTerms: string) => ({
      ...state,
      boardFilters: {
        ...state.boardFilters,
        searchTerms,
      },
    }),
    toggleBoardSort: (state) => {
      return {
        ...state,
        boardFilters: {
          ...state.boardFilters,
          sort: state.boardFilters.sort === Sort.NAME ? Sort.COUNT : Sort.NAME,
        },
      };
    },
    setTagFilterSearchTerms: (state, searchTerms: string) => ({
      ...state,
      tagFilters: {
        ...state.tagFilters,
        searchTerms,
      },
    }),
    toggleTagSort: (state) => {
      return {
        ...state,
        tagFilters: {
          ...state.tagFilters,
          sort: state.tagFilters.sort === Sort.NAME ? Sort.COUNT : Sort.NAME,
        },
      };
    },
    setPrevSearch: (state) => ({
      ...state,
      prevSearch: {
        searchTerms: state.searchTerms,
        lastUpdatedFilter: state.lastUpdatedFilter,
        selectedBoardFilters: state.boardFilters.selected,
        selectedTagFilters: state.tagFilters.selected,
      },
    }),
  },
  selectors: (slice, createSelector) => ({
    allFilters() {
      return slice(({ filters }) =>
        [...filters].map((filter) => {
          const [type, id, date] = filter.split('-');
          return {
            type: type as 'board' | 'tag' | 'date',
            id: type === 'date' ? `${id}-${date}` : Number(id),
          };
        }),
      );
    },
    error() {
      return slice(({ error }) => error);
    },
    loading() {
      return slice(({ loading }) => loading);
    },
    count() {
      return slice(({ count }) => count);
    },
    searchId() {
      return slice(({ searchId }) => searchId);
    },
    searchTerms() {
      return slice(({ searchTerms }) => searchTerms);
    },
    results() {
      return slice(({ results }) => results);
    },
    lastUpdated() {
      return slice(({ lastUpdatedFilter }) => lastUpdatedFilter);
    },
    selectedRivalIds() {
      return slice(({ boardFilters: { selected } }) => selected);
    },
    selectedTagIds() {
      return slice(({ tagFilters: { selected } }) => selected);
    },
    _tagFilters() {
      return slice(({ tagFilters }) => tagFilters);
    },
    allTagFilters() {
      return slice(({ tagFilters: { byId } }) => [...byId.keys()]);
    },
    tagFilters(models: StoreSelectors) {
      return createSelector(
        this._tagFilters as any,
        models.tags.all as any,
        (filters: StateType['tagFilters'], allTags) =>
          getFilters(filters, allTags),
      );
    },
    tagSort() {
      return slice(({ tagFilters: { sort } }) => sort);
    },
    _boardFilters() {
      return slice(({ boardFilters }) => boardFilters);
    },
    allBoardFilters() {
      return slice(({ boardFilters: { byId } }) => [...byId.keys()]);
    },
    boardFilters(models: StoreSelectors) {
      return createSelector(
        this._boardFilters as any,
        models.rivals.all as any,
        (filters: StateType['boardFilters'], allRivals) =>
          getFilters(filters, allRivals),
      );
    },
    boardSort() {
      return slice(({ boardFilters: { sort } }) => sort);
    },
    filterCount() {
      return createSelector(
        this.lastUpdated as any,
        this.selectedRivalIds as any,
        this.selectedTagIds as any,
        (
          lastUpdated: string | null,
          rivalIds: Set<number>,
          tagIds: Set<number>,
        ) => (lastUpdated ? 1 : 0) + rivalIds.size + tagIds.size,
      );
    },
    isFiltered() {
      return createSelector(this.filterCount as any, Boolean);
    },
  }),
  effects: (dispatch) => ({
    getResults({
      searchTerms,
      lastUpdatedFilter,
      selectedBoards,
      selectedTags,
    }: SearchParamsType = {}) {
      if (searchTerms !== undefined) {
        this.setSearchTerms(searchTerms);
      }
      if (lastUpdatedFilter !== undefined) {
        this.setLastUpdatedFilter(lastUpdatedFilter);
      }
      if (selectedBoards !== undefined) {
        this.setBoardFilters(selectedBoards);
      }
      if (selectedTags !== undefined) {
        this.setTagFilters(selectedTags);
      }
      this.setLoading();
      return this._doSearch();
    },
    getMoreResults() {
      return this._doSearch();
    },
    setLastUpdatedFilter() {
      return this._doSearch();
    },
    setBoardFilters() {
      return this._doSearch();
    },
    setTagFilters() {
      return this._doSearch();
    },
    _doSearch(_payload, rootState) {
      return doSearch(rootState, {
        setPrevSearch: this.setPrevSearch,
        populate: this.populate,
        populateSearchRivals: dispatch.searchRivals.populate,
        setError: this.setError,
      });
    },
  }),
});
