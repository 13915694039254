type Props = {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
};

function NewspaperIcon({ width = '16', height = '16', style }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M6 7C6 6.86739 6.05268 6.74021 6.14645 6.64645C6.24021 6.55268 6.36739 6.5 6.5 6.5H11.5C11.6326 6.5 11.7598 6.55268 11.8536 6.64645C11.9473 6.74021 12 6.86739 12 7C12 7.13261 11.9473 7.25979 11.8536 7.35355C11.7598 7.44732 11.6326 7.5 11.5 7.5H6.5C6.36739 7.5 6.24021 7.44732 6.14645 7.35355C6.05268 7.25979 6 7.13261 6 7ZM6.5 9.5H11.5C11.6326 9.5 11.7598 9.44732 11.8536 9.35355C11.9473 9.25979 12 9.13261 12 9C12 8.86739 11.9473 8.74021 11.8536 8.64645C11.7598 8.55268 11.6326 8.5 11.5 8.5H6.5C6.36739 8.5 6.24021 8.55268 6.14645 8.64645C6.05268 8.74021 6 8.86739 6 9C6 9.13261 6.05268 9.25979 6.14645 9.35355C6.24021 9.44732 6.36739 9.5 6.5 9.5ZM15 4V11.5C15 11.8978 14.842 12.2794 14.5607 12.5607C14.2794 12.842 13.8978 13 13.5 13H2.5C2.10336 13 1.72287 12.8429 1.44176 12.5631C1.16066 12.2833 1.00182 11.9035 1 11.5069V5.5C1 5.36739 1.05268 5.24021 1.14645 5.14645C1.24021 5.05268 1.36739 5 1.5 5C1.63261 5 1.75979 5.05268 1.85355 5.14645C1.94732 5.24021 2 5.36739 2 5.5V11.5C2 11.6326 2.05268 11.7598 2.14645 11.8536C2.24021 11.9473 2.36739 12 2.5 12C2.63261 12 2.75979 11.9473 2.85355 11.8536C2.94732 11.7598 3 11.6326 3 11.5V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4ZM14 4H4V11.5C4.00025 11.6703 3.97129 11.8395 3.91438 12H13.5C13.6326 12 13.7598 11.9473 13.8536 11.8536C13.9473 11.7598 14 11.6326 14 11.5V4Z"
        fill="#343330"
      />
    </svg>
  );
}

export default NewspaperIcon;
