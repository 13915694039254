import { auth } from './auth/auth.model';
import { bookmarks } from './bookmarks/bookmarks.model';
import { dashboard } from './dashboard/dashboard.model';
import { dynamicData } from './dynamic-data/dynamic-data.model';
import { intelCards } from './intel-cards/intelCards.model';
import { intelDigest } from './intel-digest/intelDigest.model';
import { intelFilters } from './intel-filters/intelFilters.model';
import { battlecards } from './profile/battlecards.model';
import { boards } from './profile/boards.model';
import { cards } from './profile/cards.model';
import { cardsSources } from './profile/cardsSources.model';
import { cardTitles } from './profile/cardTitles.model';
import { profileFilters } from './profile/profile-filters.model';
import { profiles } from './profile/profiles.model';
import { visibilityGroups } from './profile/visibilityGroups.model';
import { rivalGroups } from './rivals/rivalGroups.model';
import { rivals } from './rivals/rivals.model';
import { search } from './search/search.model';
import { searchRivals } from './search-rivals/searchRivals.model';
import { searchSuggestions } from './search-suggestions/searchSuggestions.model';
import { tags } from './tags/tags.model';
import { triage } from './triage/triage.model';
import { users } from './users/users.model';

import type { Models } from '@rematch/core';

export type RootModel = {
  auth: typeof auth;
  battlecards: typeof battlecards;
  boards: typeof boards;
  cardTitles: typeof cardTitles;
  cards: typeof cards;
  dashboard: typeof dashboard;
  intelDigest: typeof intelDigest;
  dynamicData: typeof dynamicData;
  intelFilters: typeof intelFilters;
  intelCards: typeof intelCards;
  cardsSources: typeof cardsSources;
  profiles: typeof profiles;
  profileFilters: typeof profileFilters;
  rivals: typeof rivals;
  rivalGroups: typeof rivalGroups;
  search: typeof search;
  searchSuggestions: typeof searchSuggestions;
  visibilityGroups: typeof visibilityGroups;
  tags: typeof tags;
  users: typeof users;
  triage: typeof triage;
  bookmarks: typeof bookmarks;
  searchRivals: typeof searchRivals;
} & Models<RootModel>;

export const models: RootModel = {
  auth,
  battlecards,
  boards,
  cardTitles,
  cards,
  dashboard,
  intelDigest,
  dynamicData,
  intelFilters,
  intelCards,
  cardsSources,
  profiles,
  profileFilters,
  rivals,
  rivalGroups,
  search,
  searchSuggestions,
  visibilityGroups,
  tags,
  users,
  triage,
  bookmarks,
  searchRivals,
};
