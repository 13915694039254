import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'contexts/auth';
import { useCardClick } from 'hooks/use-card-click';
import { useCardViewer } from 'hooks/use-card-viewer';

import { SingleCardViewer } from './partials/single-card-viewer';

import { CardViewerModal } from '.';

export default function CardViewer() {
  const navigate = useNavigate();
  const { isCardViewerUpdateEnabled } = useAuth();
  const {
    currentCardId,
    currentCardRank,
    targetCard,
    close: closeCardViewer,
    open: openCardViewer,
    targetProfile,
    failed,
    loadCard,
  } = useCardViewer();

  useCardClick((cardId) => {
    openCardViewer(+cardId);
  });

  const handleLaneClick = () => {
    if (!targetCard?.id) return false;

    const searchParams = new URLSearchParams({
      lanes: targetCard.board.id.toString(),
    });

    navigate({
      pathname: `/profile/${targetProfile?.id}`,
      search: searchParams.toString(),
    });
  };

  const handleCardProfileClick = () => {
    if (!targetProfile?.id) return false;
    navigate(`/profile/${targetProfile?.id}`);
  };

  useEffect(() => {
    if (currentCardId) {
      loadCard(currentCardId);
    }
  }, [loadCard, currentCardId]);

  if (currentCardId) {
    return isCardViewerUpdateEnabled ? (
      <SingleCardViewer
        profile={targetProfile}
        card={targetCard}
        onClose={closeCardViewer}
        onLaneClick={handleLaneClick}
        onProfileClick={handleCardProfileClick}
        loadFailed={failed}
        rank={currentCardRank}
      />
    ) : (
      <CardViewerModal
        profile={targetProfile}
        card={targetCard}
        onClose={closeCardViewer}
        onLaneClick={handleLaneClick}
        onProfileClick={handleCardProfileClick}
        loadFailed={failed}
        rank={currentCardRank}
      />
    );
  }

  return null;
}
