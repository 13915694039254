import {
  init,
  type RematchDispatch,
  type RematchRootState,
} from '@rematch/core';
import persistPlugin from '@rematch/persist';
import selectPlugin from '@rematch/select';
import { createDynamicMiddlewares } from 'redux-dynamic-middlewares';
import storage from 'redux-persist/lib/storage';

import { models, type RootModel } from './models';
import { testPlugin } from './test-utils'; // TODO Figure out how to exclude from production build

import type { StoreSelectors as RematchStoreSelectors } from '@rematch/select';

const persistConfig = {
  key: 'root',
  whitelist: ['app'],
  throttle: 5000,
  storage,
};

const { enhancer: dynamicEnhancer, addMiddleware } = createDynamicMiddlewares();

const store = {
  ...init<RootModel>({
    models,
    plugins: [
      persistPlugin(persistConfig),
      selectPlugin(),
      ...(!!import.meta.env.VITEST_WORKER_ID ||
      import.meta.env.STORYBOOK === 'true'
        ? [testPlugin<RootModel>()]
        : []),
    ],
    redux: {
      middlewares: [dynamicEnhancer],
    },
  }),
  addMiddleware,
};
export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
export type StoreSelectors = RematchStoreSelectors<RootModel, never>;

export function getState() {
  return store.getState();
}

export default store;
