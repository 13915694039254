import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';

import { SideActionContentTypes } from 'components/layout/app-side-action/AppSideAction.enums';
import { useAuth } from 'contexts/auth';

type BulkEditProviderPropTypes = {
  children: React.ReactChild | React.ReactNode[];
};

export enum BulkEditOption {
  CardPermissions = 'cardPermissions',
  Tags = 'tags',
  Delete = 'delete',
}

export type BulkEditContextType = {
  isActive: boolean;
  activeOption: BulkEditOption | null;
};
export type BulkEditOptionsContextType = BulkEditContextType & {
  options: Set<BulkEditOption>;
  setActiveOption: (option: BulkEditOption) => void;
};

export const BulkEditOptionsContext = createContext<
  BulkEditOptionsContextType | undefined
>(undefined);
export const BulkEditContext = createContext<BulkEditContextType | undefined>(
  undefined,
);

const useBulkEditOptionsInternal = () => {
  const location = useLocation();
  const match = matchPath(
    {
      path: '/profile/:profileId',
    },
    location.pathname,
  );

  const [{ action }] = useQueryParams(QueryParamConfig);
  const { isCurator } = useAuth();

  const options = useMemo(() => {
    const options = new Set<BulkEditOption>([]);
    if (
      action === SideActionContentTypes.bulkEdit &&
      match?.params.profileId &&
      isCurator
    ) {
      options.add(BulkEditOption.CardPermissions);
      options.add(BulkEditOption.Tags);
      options.add(BulkEditOption.Delete);
    }
    return options;
  }, [action, match?.params.profileId, isCurator]);

  return options;
};

const QueryParamConfig = {
  bulkEditOption: StringParam,
  action: StringParam,
};

export function BulkEditProvider({ children }: BulkEditProviderPropTypes) {
  const options = useBulkEditOptionsInternal();

  const [query, setQuery] = useQueryParams(QueryParamConfig);

  const { bulkEditOption } = query;

  const setActiveOption = useCallback(
    (option: BulkEditOption) => {
      if (options.has(option)) {
        setQuery({ bulkEditOption: option }, 'replaceIn');
      }
    },
    [options, setQuery],
  );

  let activeOption: BulkEditOption | null = null;
  if (options.size) {
    activeOption = Array.from(options)[0];
    if (bulkEditOption && (options as Set<string>).has(bulkEditOption)) {
      activeOption = bulkEditOption as BulkEditOption;
    }
  }

  const bulkEdit = useMemo(
    () => ({
      isActive: Boolean(activeOption),
      activeOption,
    }),
    [activeOption],
  );

  const bulkEditOptions = useMemo(
    () => ({
      ...bulkEdit,
      options,
      setActiveOption,
    }),
    [bulkEdit, options, setActiveOption],
  );

  useEffect(() => {
    // Set default option if not defined
    if (activeOption && !(options as Set<string>).has(bulkEditOption || '')) {
      setQuery({ bulkEditOption: activeOption }, 'replaceIn');
    }
  }, [bulkEditOption, activeOption, options, setQuery]);

  return (
    <BulkEditOptionsContext.Provider value={bulkEditOptions}>
      <BulkEditContext.Provider value={bulkEdit} children={children} />
    </BulkEditOptionsContext.Provider>
  );
}

export const useBulkEditOptions = () => {
  const bulkEditor = useContext(BulkEditOptionsContext);

  if (bulkEditor === undefined) {
    throw new Error('useBulkEditOptions must be used within BulkEditProvider');
  }

  return bulkEditor;
};

export const useBulkEdit = () => {
  const activeOption = useContext(BulkEditContext);

  if (activeOption === undefined) {
    throw new Error('useBulkEdit must be used within BulkEditProvider');
  }

  return activeOption;
};
