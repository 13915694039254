type Props = {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
};

function ChartBarIcon({ width = '16', height = '16', style }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M14 13H13.5V3C13.5 2.86739 13.4473 2.74021 13.3536 2.64645C13.2598 2.55268 13.1326 2.5 13 2.5H9.5C9.36739 2.5 9.24021 2.55268 9.14645 2.64645C9.05268 2.74021 9 2.86739 9 3V5.5H6C5.86739 5.5 5.74021 5.55268 5.64645 5.64645C5.55268 5.74021 5.5 5.86739 5.5 6V8.5H3C2.86739 8.5 2.74021 8.55268 2.64645 8.64645C2.55268 8.74021 2.5 8.86739 2.5 9V13H2C1.86739 13 1.74021 13.0527 1.64645 13.1464C1.55268 13.2402 1.5 13.3674 1.5 13.5C1.5 13.6326 1.55268 13.7598 1.64645 13.8536C1.74021 13.9473 1.86739 14 2 14H14C14.1326 14 14.2598 13.9473 14.3536 13.8536C14.4473 13.7598 14.5 13.6326 14.5 13.5C14.5 13.3674 14.4473 13.2402 14.3536 13.1464C14.2598 13.0527 14.1326 13 14 13ZM10 3.5H12.5V13H10V3.5ZM6.5 6.5H9V13H6.5V6.5ZM3.5 9.5H5.5V13H3.5V9.5Z"
        fill="#343330"
      />
    </svg>
  );
}

export default ChartBarIcon;
