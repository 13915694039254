import { Accordion, Box, Logo, Skeleton } from '@kluein/klue-ui';
import truncate from 'lodash/truncate';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth } from 'contexts/auth';
import store, { type Dispatch, type RootState } from 'store';
import TEST_IDS from 'test-ids';

import QuickFinder from './QuickFinder';
import RecentlyViewed from './RecentlyViewed';
import RivalGroup from './RivalGroup';
import Section from './Section';

import type { RivalGroupType, RivalType } from 'api/api.types';

type QuickSwitcherProps = {
  onNavigate: VoidFunction;
};
export default function QuickSwitcher({ onNavigate }: QuickSwitcherProps) {
  const { t } = useTranslation(['Common']);
  const {
    rivalGroups: { fetchRivalGroupsFromApi },
  } = useDispatch<Dispatch>();
  const { company } = useAuth();
  const isLoadingProfile = useSelector<RootState, boolean>((state) => {
    return state.profiles.isLoading;
  });
  const currentProfileRival = useSelector<RootState, RivalType | undefined>(
    (state) => {
      const { currentRivalId } = state.profiles;
      return (state as any).rivals.byId.get((currentRivalId || '').toString());
    },
  );

  const currentProfileId = Number(currentProfileRival?.profile);

  useEffect(() => {
    fetchRivalGroupsFromApi(null);
  }, [fetchRivalGroupsFromApi]);

  const rivalGroups = useSelector<RootState, RivalGroupType[]>(
    store.select.rivalGroups.getSortedRivalGroups,
  );

  return (
    <Box
      fill
      gap="xxlarge"
      data-test-id={TEST_IDS.layout.quickSwitcher.container}
    >
      <Box
        direction="column"
        gap="xlarge"
        pad={{ horizontal: 'large', top: 'large' }}
        flex={false}
      >
        <Box direction="row" justify="between" align="center">
          <Logo
            a11yTitle={company?.name}
            data-test-id={TEST_IDS.layout.quickSwitcher.companyLogo}
            size="xlarge"
            src={company?.iconUrl}
          />
        </Box>
        <Section
          title={t('Common:quickSwitcher.quickFinderLabel')}
          data-test-id={TEST_IDS.layout.quickSwitcher.quickFinderLabel}
        >
          <QuickFinder
            currentProfileId={currentProfileId}
            onNavigate={onNavigate}
          />
        </Section>
      </Box>

      <Box direction="column" overflow={{ vertical: 'auto' }} flex={'shrink'}>
        <Box direction="column" gap="xxlarge" pad={{ horizontal: 'large' }}>
          <Section title={t('Common:quickSwitcher.recentLabel')}>
            <Box border="all" round="small" pad="small">
              <RecentlyViewed
                currentProfileId={currentProfileId}
                onNavigate={onNavigate}
              />
            </Box>
          </Section>

          <Section
            title={t('Common:quickSwitcher.groupsLabel')}
            flex={{ shrink: 1 }}
          >
            <>
              {!!rivalGroups.length && (
                <Accordion pad={{ bottom: 'xlarge' }}>
                  {rivalGroups.map((group) => {
                    return (
                      !!group.rivals.length && (
                        <RivalGroup
                          id={group.id}
                          name={truncate(group.name, { length: 30 })}
                          key={group.id}
                          rivals={group.rivals}
                          currentProfileId={currentProfileId}
                          onNavigate={onNavigate}
                        />
                      )
                    );
                  })}
                </Accordion>
              )}

              {!!isLoadingProfile && !rivalGroups.length && (
                <Skeleton height="50px" />
              )}
            </>
          </Section>
        </Box>
      </Box>
    </Box>
  );
}
