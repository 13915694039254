import { pipe } from 'lib/utils';

import {
  FORMULA_REGEX,
  formulaToKeyMappings,
  COMPLETE_FORMULA_REGEX,
  FORMULA_STATIC_KEYS,
} from './constants';
import {
  NodeType,
  Formulas,
  type FormulaFromApiKeys,
  type TextJson,
} from './formulas.types';

import type { CardType } from 'api/api.types';

const splitByPipe = (text: string) => {
  return text.trim().split('|').flat();
};

/**
 * Decodes (allowlisted) HTML entities (`&copy;` => `©`)
 * See `commonEntities` declaration
 *
 * @param {string} text - Input string.
 * @returns {string} - Decoded string.
 *
 */

export const commonEntities = Object.freeze({
  '&amp;': '&',
  '&apos;': "'",
  '&copy;': '©',
  '&gt;': '>',
  '&lt;': '<',
  '&nbsp;': ' ',
  '&quot;': '"',
  '&reg;': '®',
  '&trade;': '™',
});

export const commonEntitiesRegExp = new RegExp(
  `(${Object.keys(commonEntities).join('|')})`,
  'gi',
);

export const decodeCommonEntities = (text: string) =>
  text.replace(
    commonEntitiesRegExp,
    (...matchArgs) => (commonEntities as any)[matchArgs[1].toLowerCase()],
  );

const getFormulaType = (formulas: Array<Array<string>>): Formulas => {
  const firstIndex = 0;
  const formulaName = formulas[firstIndex].filter(
    (it) => formulaToKeyMappings[it as FormulaFromApiKeys],
  )[firstIndex] as FormulaFromApiKeys;

  if (formulas[firstIndex].includes('COMPARE_TIMESERIES')) {
    const compareFormulaName = `COMPARE_${formulaName}` as FormulaFromApiKeys;
    return formulaToKeyMappings[compareFormulaName] as Formulas;
  }

  return formulaToKeyMappings[formulaName] as Formulas;
};

const getNameFromFormulaKey = (formulaKey: string) => {
  const defaultFormulas = ['SFDC_LATEST_OPPORTUNITIES', 'SFDC_WINS'];

  if (defaultFormulas.includes(formulaKey)) {
    return formulaKey;
  }

  return (formulaKey || '').replace(/_[a-fA-F\d]{2}/g, (match) =>
    String.fromCharCode(parseInt(match.slice(1), 16)),
  );
};

export const getFormulaKeyType = (value: string) => {
  if (/^-?\d+$/.test(value)) return 'rival';

  if (FORMULA_STATIC_KEYS.includes(value)) return 'dynamic';

  return 'domain';
};

export function getFormulaMetadata(formula: string, card: CardType) {
  const cardId = card.id;
  const formulaCopy = `${formula.replace(/{{\s*([^}]+)\s*}}/, '$1')}`;
  const companies: Array<number | string> = [];
  const extra = {};
  const returnTypeRegex = /".+"/;
  const currentRival = 'currentRival';
  const currentCompany = 'currentCompany';

  const extractKeyValueFromFormula = (text: string) => {
    const formulaValue = text.split('|').pop();

    if (!formulaValue) {
      return [];
    }

    const matchNumber = formulaValue.match(/\d+/);

    if (matchNumber?.length) {
      const rivalId = matchNumber[0].replace(/['"]+/g, '');
      companies.push(parseInt(rivalId));

      return text.replace(returnTypeRegex, '');
    }

    if (formulaValue.includes(currentRival)) {
      companies.push(currentRival);
      return text.replace(returnTypeRegex, '');
    }

    if (formulaValue.includes(currentCompany)) {
      companies.push(currentCompany);
      return text.replace(returnTypeRegex, '');
    }

    companies.push(formulaValue.replace(/"(.+)"/, '$1'));

    return text.replace(returnTypeRegex, '');
  };

  // the data will arrive at this point as:
  // Array<string>
  // where strings will be equal:
  // INCOME_STATEMENTS|EMPLOYEE_COUNT|ETC|"company.com"
  // so the first thing is push the company url found to the companies array
  // and replace it by nothing.
  // Then we split the string by pipe which will make the structure be Array<Array<string>>
  // that's why we flat it to keep the structure simple as Array<string>
  const handleItem = pipe<any>([extractKeyValueFromFormula, splitByPipe]);

  const formulas = formulaCopy
    .replace(/\(/g, '|')
    .replace(/\)/g, '')
    //we can have multiple formulas specially charts comparing two or more companies
    //they arrived separated by comma
    .split(',')
    .map(handleItem) as Array<Array<string>>;

  const formulaType = getFormulaType(formulas);

  if (
    [Formulas.sfdcLatestOpportunities, Formulas.sfdcWins].includes(formulaType)
  ) {
    // When Salesforce content, translate the second formula paramater (using v1 as reference)
    // The second formula parameter is url-encoded with `%` translated to `_`
    // CLOSED_20OPPORTUNITIES_20_3E_20_241M -> CLOSED OPPORTUNITIES > $1M
    let formulaQuery = formulas[0][1];

    if (formulaQuery === 'COMPANY') {
      formulaQuery = formulas[0][0];
    }

    const parsedQueryType = getNameFromFormulaKey(formulaQuery).toUpperCase();

    Object.assign(extra, {
      sfdcQueryType: parsedQueryType,
    });
  }

  // TODO: [Cards Parser] Fix card type to accept either search type or profile
  const castCard = card as any;

  if (castCard?.profile?.id) {
    Object.assign(extra, {
      profileId: castCard.profile.id,
    });
  } else {
    Object.assign(extra, {
      profileId: card.board.profileId,
    });
  }

  return {
    formulaType,
    companies,
    cardId,
    extra,
  };
}

type parseTextHtmlToJsonType = {
  textHtml?: string;
  card: CardType;
};
/**
 * receives a html string and process manipulations and formulas extraction
 * @param textHtml string
 */
export function parseTextHtmlToJson({
  textHtml,
  card,
}: parseTextHtmlToJsonType): Array<TextJson> {
  return (textHtml ?? '')
    .split(FORMULA_REGEX)
    .reduce((prev: Array<TextJson>, item: string) => {
      if (!item) return prev;

      if (item.match(COMPLETE_FORMULA_REGEX)) {
        return [
          ...prev,
          {
            type: NodeType.formula,
            data: getFormulaMetadata(item, card) as any,
          },
        ];
      }

      return [
        ...prev,
        {
          type: NodeType.html,
          data: item,
        },
      ];
    }, []);
}
