import {
  Banner,
  Box,
  Button,
  Icon,
  Text,
  TextArea,
  tokens,
} from '@kluein/klue-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { KlueCardSentimentEnum } from 'api/api.types';
import { AnimatedCardSentimentButton } from 'components/common/animated-card-sentiment-button';
import { useAuth } from 'contexts/auth';
import { useCardSentiment } from 'hooks/profile/use-card-sentiment';
import { useCreateCardCommentMutation } from 'store/api/comments';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import type { KlueCardSentimentProps } from './KlueCardSentiment.types';

const textAreaStyles = {
  style: {
    border: `1px solid ${tokens.color.neutral.lightgrey.main}`,
    fontWeight: 'lighter',
    backgroundColor: `${tokens.color.neutral.white.main}`,
    minHeight: '100px',
    borderRadius: '5px',
  },
  size: 'medium',
};

const BANNER_FEEDBACK_INTERVAL = 3000;

function KlueCardSentiment({ cardId, rank }: KlueCardSentimentProps) {
  const { t } = useTranslation();
  const { isCardFeedbackDisabled } = useAuth();
  const [createCardComment, { isLoading, isSuccess, isError, reset }] =
    useCreateCardCommentMutation();
  const [feedbackContent, setFeedbackContent] = useState('');
  const [shareFeedback, setShareFeedback] = useState(false);
  const handleShareFeedback = async () => {
    if (feedbackContent) {
      await createCardComment({ cardId, body: feedbackContent });
    }
  };
  const {
    isSentimentButtonLoading,
    isSentimentActive,
    onSentimentButtonClick,
  } = useCardSentiment({
    cardId,
    interactionLabel: 'card-viewer',
    rank,
  });

  const handleScrollIntoView = useCallback(
    (node: Element | null) => node?.scrollIntoView(),
    [],
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isSuccess) {
      setShareFeedback(false);
      setFeedbackContent('');
      intervalId = setInterval(() => {
        reset();
      }, BANNER_FEEDBACK_INTERVAL);
    }

    return () => clearInterval(intervalId);
  }, [isSuccess, reset]);

  return (
    <Box
      data-test-id={TEST_IDS.klueCard.klueCardSentiment.container}
      margin={{ bottom: 'xxlarge' }}
      pad={{ horizontal: 'large', vertical: 'medium' }}
      background={tokens.color.neutral.rosegrey.main}
      border={{ color: tokens.color.neutral.lightgrey.main }}
      round="5px"
      justify="between"
      align="center"
      gap="medium"
    >
      <Text size="large" fontWeight="semibold">
        {t('Card:sentiment.description')}
      </Text>
      <Box direction="row" gap="small" align="center">
        <Box direction="row" gap="xsmall">
          <AnimatedCardSentimentButton
            tipContent={t('Card:sentiment.button.positive')}
            tipAlign={{ align: { top: 'bottom' } }}
            active={isSentimentActive(KlueCardSentimentEnum.POSITIVE)}
            onClick={() =>
              !isSentimentButtonLoading[KlueCardSentimentEnum.POSITIVE] &&
              onSentimentButtonClick(KlueCardSentimentEnum.POSITIVE)
            }
            icon={<Icon.ThumbsUp size="small" />}
            iconActive={<Icon.ThumbsUpFilled size="small" />}
            a11ytitle={t('Card:sentiment.button.a11ytitlePositive')}
            data-test-id={TEST_IDS.klueCard.klueCardSentiment.button.positive}
            data-tracking-id={TRACKING_IDS.global.card.sentiment.positive}
          />
          <AnimatedCardSentimentButton
            tipContent={t('Card:sentiment.button.negative')}
            tipAlign={{ align: { top: 'bottom' } }}
            active={isSentimentActive(KlueCardSentimentEnum.NEGATIVE)}
            onClick={() =>
              !isSentimentButtonLoading[KlueCardSentimentEnum.NEGATIVE] &&
              onSentimentButtonClick(KlueCardSentimentEnum.NEGATIVE)
            }
            icon={<Icon.ThumbsDown size="small" />}
            iconActive={<Icon.ThumbsDownFilled size="small" />}
            a11ytitle={t('Card:sentiment.button.a11ytitleNegative')}
            data-test-id={TEST_IDS.klueCard.klueCardSentiment.button.negative}
            data-tracking-id={TRACKING_IDS.global.card.sentiment.negative}
          />
        </Box>
        {!isCardFeedbackDisabled && !shareFeedback && !isSuccess && (
          <Button
            variant="flat-outlined"
            size="small"
            label={t('Card:sentiment.button.shareFeedback')}
            onClick={() => setShareFeedback(true)}
            a11yTitle={t('Card:sentiment.button.a11ytitleFeedback')}
            data-test-id={
              TEST_IDS.klueCard.klueCardSentiment.button.shareFeedback
            }
            data-tracking-id={TRACKING_IDS.global.card.shareFeedback.share}
          />
        )}
      </Box>
      {!isCardFeedbackDisabled && shareFeedback && (
        <Box
          gap="small"
          fill
          ref={!feedbackContent ? handleScrollIntoView : undefined}
        >
          <Banner
            message={t('Common:fatalErrorFeedback.title')}
            variant="inline"
            status="error"
            show={isError}
            size="small"
            fontWeight="normal"
            showIcon
          />
          <TextArea
            {...textAreaStyles}
            resize={false}
            onChange={(e) => setFeedbackContent(e.target.value)}
            placeholder={t('Card:sentiment.feedbackPlaceholder')}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <Box direction="row" gap="small" justify="end">
            <Button
              variant="plain"
              label={t('Common:actions.cancel')}
              onClick={() => setShareFeedback(false)}
              data-tracking-id={TRACKING_IDS.global.card.shareFeedback.cancel}
            />
            <Button
              variant="flat-outlined-form"
              label={t('Card:sentiment.button.shareFeedback')}
              size="small"
              disabled={!feedbackContent}
              loading={isLoading}
              onClick={handleShareFeedback}
              data-tracking-id={TRACKING_IDS.global.card.shareFeedback.send}
            />
          </Box>
        </Box>
      )}
      <Banner
        message={t('Card:sentiment.thankYouForSharingFeedback')}
        variant="inline"
        status="success"
        show={isSuccess}
        size="small"
        fontWeight="normal"
        showIcon
      />
    </Box>
  );
}

export default KlueCardSentiment;
