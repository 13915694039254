import {
  Box,
  Button,
  CheckBox,
  RadioButton,
  Text,
  TextInput,
  tokens,
} from '@kluein/klue-ui';
import { type ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useQueryParams, createEnumParam } from 'use-query-params';

import { useDebounce } from 'hooks/use-debounce';
import TEST_IDS from 'test-ids';

import OptionLayout from '../components/OptionLayout';

import type { TagType } from 'api/api.types';
import type { Dispatch, RootState } from 'store';
import type { StateType as TagsStateType } from 'store/models/tags/tags.model';

const RadioLabel = styled(Text)`
  font-weight: 600;
`;

const Radio = ({
  testId,
  name,
  label,
  checked,
  onChange,
}: {
  testId: string;
  name: string;
  label: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}) => (
  <Box pad={{ top: 'xsmall' }}>
    <RadioButton
      name={name}
      label={
        <RadioLabel data-test-id={testId} size="medium">
          {label}
        </RadioLabel>
      }
      checked={checked}
      onChange={onChange}
    />
  </Box>
);

const QueryParamConfig = {
  tagsOp: createEnumParam(['add', 'remove']),
};

const useTagsOp = () => {
  const [{ tagsOp }, setQuery] = useQueryParams(QueryParamConfig);

  useEffect(
    // removes tagsOp when switching from editing tags
    () => () => setQuery({ tagsOp: undefined }, 'replaceIn'),
    [setQuery],
  );

  return {
    isAdding: tagsOp !== 'remove',
    setAdding: (isAdding: boolean) =>
      setQuery({ tagsOp: isAdding ? 'add' : 'remove' }, 'replaceIn'),
  };
};

const AddOrRemoveRadioGroup = () => {
  const { t } = useTranslation(['Card']);
  const { isAdding, setAdding } = useTagsOp();

  return (
    <Box
      data-test-id={TEST_IDS.bulkEdit.cardTags.addOrRemoveRadioGroup}
      flex={{ shrink: 0 }}
    >
      <Radio
        testId={TEST_IDS.bulkEdit.cardTags.addRadio}
        name={t('Card:tags.addOrRemoveTags.add.name')}
        label={t('Card:tags.addOrRemoveTags.add.label')}
        checked={isAdding}
        onChange={() => setAdding(true)}
      />
      <Radio
        testId={TEST_IDS.bulkEdit.cardTags.removeRadio}
        name={t('Card:tags.addOrRemoveTags.remove.name')}
        label={t('Card:tags.addOrRemoveTags.remove.label')}
        checked={!isAdding}
        onChange={() => setAdding(false)}
      />
      <Box pad={{ top: 'large', bottom: 'small' }}>
        <Text fontWeight="medium" color={tokens.color.neutral.darkgrey.main}>
          {isAdding
            ? t('Card:tags.addOrRemoveTags.add.instruction')
            : t('Card:tags.addOrRemoveTags.remove.instruction')}
        </Text>
      </Box>
    </Box>
  );
};

const TagCheckbox = ({ tag: { id, name } }: { tag: TagType }) => {
  const dispatch = useDispatch<Dispatch>();
  const checked = useSelector<RootState, boolean>((state) =>
    state.tags.selectedTags.has(id),
  );
  return (
    <Box
      data-test-id={TEST_IDS.bulkEdit.cardTags.checkboxContainer}
      pad="xxsmall"
      flex={{ shrink: 0 }}
    >
      <CheckBox
        checked={checked}
        onChange={() => dispatch.tags.toggleSelectedTag({ id })}
        label={<Text>{name}</Text>}
      />
    </Box>
  );
};

const TagCheckboxes = () => {
  const { t } = useTranslation(['Card']);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [filteredTagIds, setFilteredTagIds] = useState<Array<number>>([]);
  const dispatch = useDispatch<Dispatch>();
  const debouncedSearchInputValue = useDebounce(searchInputValue, 500);
  const sanitizedDebouncedInput = debouncedSearchInputValue
    .toLowerCase()
    .trim();
  const { byId, allIds } = useSelector<RootState, TagsStateType>(
    (state) => state.tags,
  );

  const isTagSelected = useSelector<RootState, boolean>(
    (state) => !!state.tags.selectedTags.size,
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = event.target;
    setSearchInputValue(newValue);
  };

  useEffect(() => {
    if (allIds.size) {
      if (debouncedSearchInputValue.length) {
        const filtered = Array.from(allIds).filter((tagId) => {
          return byId
            .get(tagId)
            ?.name.toLowerCase()
            .includes(sanitizedDebouncedInput);
        });

        return setFilteredTagIds(filtered);
      }

      setFilteredTagIds(Array.from(allIds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIds, byId, debouncedSearchInputValue]);

  return (
    <Box
      data-test-id={TEST_IDS.bulkEdit.cardTags.checkboxesContainer}
      flex={{ shrink: 0 }}
    >
      <Box pad={{ horizontal: 'xxsmall' }} margin={{ bottom: 'medium' }}>
        <TextInput
          type="search"
          value={searchInputValue}
          placeholder={t('Card:tags.searchFilter.placeholder')}
          title={t('Card:tags.searchFilter.placeholder')}
          size="medium"
          aria-label={t('Card:tags.searchFilter.label')}
          onChange={onChange}
          data-test-id={TEST_IDS.bulkEdit.cardTags.searchInput}
        />
      </Box>

      {!!isTagSelected && (
        <Button
          data-test-id={TEST_IDS.bulkEdit.cardTags.deselectAllButton}
          size="small"
          margin={{ bottom: 'large' }}
          title={t('Common:actions.deselectAll')}
          label={t('Common:actions.deselectAll')}
          onClick={dispatch.tags.deselectAll}
        />
      )}

      {filteredTagIds.map((tagId) => {
        const tag = byId.get(tagId);

        return tag && <TagCheckbox key={tagId} tag={tag} />;
      })}

      {Boolean(debouncedSearchInputValue.length && !filteredTagIds.length) && (
        <Text data-test-id={TEST_IDS.bulkEdit.cardTags.noResultText}>
          {t('Card:tags.searchFilter.noResult')}:{' '}
          <b>{debouncedSearchInputValue}</b>
        </Text>
      )}
    </Box>
  );
};

export default function CardTags() {
  const { t } = useTranslation(['Card']);

  return (
    <OptionLayout
      testId={TEST_IDS.bulkEdit.cardTags.container}
      title={t('Card:tags.header.title')}
      body={
        <Box overflow={{ vertical: 'scroll' }}>
          <AddOrRemoveRadioGroup />
          <TagCheckboxes />
        </Box>
      }
    />
  );
}
