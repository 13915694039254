/* eslint-disable max-lines */

import {
  Box,
  Button,
  Icon,
  Text,
  useResponsive,
  tokens,
} from '@kluein/klue-ui';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { StringParam, useQueryParams } from 'use-query-params';

import { UserMenu } from 'components/layout/user-menu';
import { useAuth } from 'contexts/auth';
import { useEmbedded } from 'contexts/ui';
import { SIDEBAR_SHORTCUT } from 'lib/hotkeys';
import { APP_V1_BASEURL } from 'lib/urls';
import store from 'store';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import { APP_NAV_BAR_BORDER_WIDTH, APP_NAV_BAR_HEIGHT } from './constants';
import { AppNavBarMenu } from './partials/app-nav-bar-menu';

import { AppSideMenu } from '../app-side-menu';
import {
  NavBar,
  NavBarAvatar,
  NavBarGlobalSearch,
  NavBarLink,
} from '../nav-bar';
import { QuickSwitcher } from '../quick-switcher';

const StyledAvatarBox = styled(Box)<{ isImpersonating?: boolean }>`
  &:hover {
    ${({ isImpersonating }) =>
      `
      background-color: ${
        isImpersonating
          ? tokens.color.primaryintel.dark
          : tokens.color.neutral.lightgrey.main
      }
    `}
  }
`;

const QuickSwitcherLink = styled(Box)<{ isActive?: boolean }>`
  position: relative;

  svg {
    fill: ${tokens.color.neutral.black.main};
    stroke: ${tokens.color.neutral.black.main};
  }

  &:hover {
    background-color: ${tokens.color.neutral.lightgrey.main};
  }

  &:focus,
  &:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
  }
  &:focus-visible > div {
    border-radius: 2px;
    box-shadow: 0 0 2px 2px ${tokens.color.primary.main};
  }

  ${({ isActive }) =>
    isActive &&
    `
    background-color: ${tokens.color.neutral.lightgrey.main};
  `}
`;

const QueryParamConfig = {
  nav: StringParam,
  action: StringParam,
};

type linkProps = {
  to: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const ActiveAppNavBar = () => {
  const [{ nav }, setQuery] = useQueryParams(QueryParamConfig);

  const { pathname } = useLocation();
  const hasQuickSwitcherAccess = matchRoutes(
    [
      { path: '/' },
      { path: '/dashboard' },
      { path: '/profile/:id/*' },
      { path: '/search/*' },
    ],
    pathname,
  );

  const {
    homeRoute,
    isCurator,
    isAdmin,
    isImpersonating,
    user,
    isResearchEnabled,
    isWinLossEnabled,
    isWinLossExclusive,
    isNewCuratorNavEnabled,
    isFeedHiddenInConsumerNavEnabled,
  } = useAuth();
  const { t } = useTranslation(['Common', 'Alerts']);
  const dispatch = useDispatch();
  const { maxWidth } = useResponsive();

  const [isQuickSwitcherOpen, setQuickSwitcherOpen] = useState(false);
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);

  const visibilityGroupId = useSelector(store.select.auth.visibilityGroupId);

  const feedLinkProps: linkProps = {
    to: `${APP_V1_BASEURL}/feed`,
  };
  const digestLinkProps: linkProps = {
    to: `${APP_V1_BASEURL}/digest/overview`,
  };
  if (visibilityGroupId !== null) {
    feedLinkProps.onClick = (e) => {
      dispatch.auth.setPreviewExitInfoDialog({
        destination: 'news feed',
        redirectUrl: feedLinkProps.to,
      });
      e.preventDefault();
    };
    digestLinkProps.onClick = (e) => {
      dispatch.auth.setPreviewExitInfoDialog({
        destination: 'digest',
        redirectUrl: digestLinkProps.to,
      });
      e.preventDefault();
    };
  }

  useHotkeys(
    SIDEBAR_SHORTCUT,
    (event) => {
      event.preventDefault();
      setQuickSwitcherOpen(true);
    },
    [nav],
  );

  const renderNavbarLinks = () => {
    if (isNewCuratorNavEnabled && isCurator) {
      return (
        <Box
          key="appNavBar.navBarLink.container"
          data-test-id={TEST_IDS.appNavBar.newCuratorNavBarLink}
        >
          <AppNavBarMenu />
        </Box>
      );
    }

    if (maxWidth.medium) return null;

    return (
      <Box direction="row" key="appNavBar.navBarLink.container">
        {isCurator && (
          <NavBarLink
            label={t('Common:alerts')}
            title={t('Common:alerts')}
            to="/alerts"
          />
        )}

        {isCurator && (
          <NavBarLink
            label={t('Common:monitors')}
            title={t('Common:monitors')}
            to="/monitors"
          />
        )}

        {isWinLossEnabled && (
          <NavBarLink
            label={t('Common:winLoss')}
            title={t('Common:winLoss')}
            to="/win-loss"
            data-tracking-id={TRACKING_IDS.winLoss.globalNav}
          />
        )}

        {isFeedHiddenInConsumerNavEnabled && !isCurator ? null : (
          <NavBarLink
            label={t('Common:feed')}
            title={t('Common:feed')}
            data-test-id={TEST_IDS.appNavBar.navBarLinks.feed}
            data-tracking-id={TRACKING_IDS.global.appNavBar.feed}
            {...feedLinkProps}
          />
        )}

        <NavBarLink
          label={t('Common:digest')}
          title={t('Common:digest')}
          data-test-id={TEST_IDS.appNavBar.navBarLinks.digest}
          data-tracking-id={TRACKING_IDS.global.appNavBar.digest}
          {...digestLinkProps}
        />
      </Box>
    );
  };

  const navItems = [
    [
      hasQuickSwitcherAccess ? (
        <QuickSwitcherLink
          isActive={isQuickSwitcherOpen}
          data-test-id={TEST_IDS.layout.appNavBar.hamburgerMenuButton}
          data-tracking-id={TRACKING_IDS.global.appNavBar.quickSwitcher}
          key="appNavBar.hamburgerMenuButton"
          onClick={(event: React.MouseEvent) => {
            event && event.preventDefault();
            setQuickSwitcherOpen(true);
          }}
          title={t('Common:navBar.hamburgerMenu.title')}
        >
          <Box
            align="center"
            direction="row"
            fill="vertical"
            justify="center"
            width="xsmall"
            margin="xxsmall"
          >
            <Icon.Menu
              color={
                isQuickSwitcherOpen
                  ? tokens.color.neutral.white.main
                  : tokens.color.neutral.lightgrey.main
              }
              size="small"
            />
          </Box>
        </QuickSwitcherLink>
      ) : null,
      <NavBarLink
        className={maxWidth.medium ? 'navBar-home-icon' : 'navBar-home'}
        data-tracking-id={TRACKING_IDS.global.appNavBar.home}
        key="appNavBar.navBarLink.homeButton"
        title={t('Common:navBar.home')}
        to={homeRoute}
      >
        <Box width={{ min: 'xsmall' }} align="center">
          <Text size="large">
            {maxWidth.medium ? (
              <Icon.Home color={tokens.color.neutral.black.main} />
            ) : (
              t('Common:navBar.home', {
                instance: isImpersonating ? `(${user?.companyName})` : '',
              })
            )}
          </Text>
        </Box>
      </NavBarLink>,
      isResearchEnabled ? (
        <NavBarLink
          className={
            maxWidth.medium ? 'navBar-research-icon' : 'navBar-research'
          }
          data-tracking-id={TRACKING_IDS.global.appNavBar.research}
          key="appNavBar.navBarLink.researchButton"
          title={t('Common:navBar.research')}
          to="/research"
        >
          <Box width={{ min: 'xsmall' }} align="center">
            <Text size="large">
              {maxWidth.medium ? (
                <Icon.DocumentTest color={tokens.color.neutral.black.main} />
              ) : (
                t('Common:navBar.research')
              )}
            </Text>
          </Box>
        </NavBarLink>
      ) : null,
    ],
    [
      !isWinLossExclusive && (
        <NavBarGlobalSearch key="appNavBar.navBarGlobalSearch" />
      ),
    ],
    [
      !isWinLossExclusive ? renderNavbarLinks() : null,
      !isCurator && !isAdmin && (
        <Box justify="center" key="appNavBar.navBarLink.submitIntelButton">
          <Button
            variant="primary-action"
            size="small"
            leftSlot={<Icon.Send />}
            onClick={() => {
              setQuery({ action: 'shareIntel' });
            }}
            label={
              !maxWidth.medium ? t('Common:navBar.submitIntel') : undefined
            }
            data-tracking-id={TRACKING_IDS.global.appNavBar.submitIntel}
          />
        </Box>
      ),

      <StyledAvatarBox
        direction="row"
        justify="center"
        align="center"
        pad={{ horizontal: 'small' }}
        gap="small"
        key="appNavBar.navBarAvatar"
        background={isImpersonating ? tokens.color.primaryintel.main : 'none'}
        isImpersonating={isImpersonating}
      >
        {(!maxWidth.medium || isNewCuratorNavEnabled) && (
          <NavBarAvatar
            borderColor={
              isImpersonating
                ? tokens.color.primary.contrastmain
                : tokens.color.primary.contrastlight
            }
          />
        )}
        <Button
          variant="plain"
          focusIndicator={false}
          onClick={() => setUserMenuOpen(true)}
          data-tracking-id={TRACKING_IDS.global.appNavBar.userMenu}
          title="user-menu"
        >
          <Icon.MoreVertical
            size="18px"
            color={
              isImpersonating
                ? tokens.color.primary.contrastmain
                : tokens.color.primary.contrastlight
            }
          />
        </Button>
      </StyledAvatarBox>,
    ],
  ].map((items) => items.filter(Boolean));

  return (
    <NavBar
      border={{
        side: 'bottom',
        size: APP_NAV_BAR_BORDER_WIDTH,
        color: tokens.color.neutral.lightgrey.main,
      }}
      background={isImpersonating ? tokens.color.primaryintel.light : undefined}
    >
      <Box
        direction="row"
        gridArea="col1"
        justify="start"
        height={APP_NAV_BAR_HEIGHT}
      >
        {navItems[0]}
      </Box>
      <Box
        alignSelf="center"
        gridArea="col2"
        pad={{
          horizontal: maxWidth.medium ? 'xlarge' : 'xsmall',
          vertical: 'xxsmall',
        }}
      >
        {navItems[1]}
      </Box>
      <Box direction="row" gridArea="col3" justify="end">
        {navItems[2]}
      </Box>
      <AppSideMenu
        position="left"
        isOpen={isQuickSwitcherOpen}
        onClose={() => setQuickSwitcherOpen(false)}
      >
        <QuickSwitcher onNavigate={() => setQuickSwitcherOpen(false)} />
      </AppSideMenu>
      <AppSideMenu
        position="right"
        isOpen={isUserMenuOpen}
        onClose={() => setUserMenuOpen(false)}
      >
        <UserMenu onClose={() => setUserMenuOpen(false)} />
      </AppSideMenu>
    </NavBar>
  );
};

const AppNavBar = () => {
  const isEmbedded = useEmbedded();

  if (isEmbedded) {
    return null;
  }

  return <ActiveAppNavBar />;
};

export default AppNavBar;
