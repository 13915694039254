import { Box, Text, RadioButton, CheckBox } from '@kluein/klue-ui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  VISIBILITY_GROUPS_COLORS,
  permissionIds,
} from 'components/common/klueCard/partials/klue-card-visibility-groups/helpers';
import useVisibilityGroups from 'hooks/use-visibility-groups/useVisibilityGroups';
import TEST_IDS from 'test-ids';

import OptionLayout from '../components/OptionLayout';

import type { Dispatch } from 'store';

const fullAccessUserId = 0;

export const curatorsOnlyRadioButton = {
  id: permissionIds.curatorsOnly,
  name: 'curators-only',
  testId: TEST_IDS.bulkEdit.cardPermissions.curatorsOnlyRadioButton,
  localizationLabel: 'Common:visibilityTypeTitles.curatorsOnly',
  ...VISIBILITY_GROUPS_COLORS.curatorsOnly,
};

export const everyoneRadioButton = {
  id: permissionIds.everyone,
  testId: TEST_IDS.bulkEdit.cardPermissions.everyOneRadioButton,
  name: 'everyone',
  localizationLabel: 'Common:visibilityTypeTitles.everyone',
  ...VISIBILITY_GROUPS_COLORS.everyone,
};

export const groupsRadioButton = {
  id: permissionIds.curatorsAnd,
  testId: TEST_IDS.bulkEdit.cardPermissions.groupsRadioButton,
  name: 'groups',
  localizationLabel: 'Common:visibilityTypeTitles.curatorsAnd',
  ...VISIBILITY_GROUPS_COLORS.fullAccessUser,
};

export const radioButtons = [
  curatorsOnlyRadioButton,
  everyoneRadioButton,
  groupsRadioButton,
];

const CardPermissions = ({
  visibleToDefault,
  showTitle = true,
}: {
  visibleToDefault?: number;
  showTitle?: boolean;
}) => {
  const { visibilityGroups, isSelectedGroup, visibleTo, setVisibleTo } =
    useVisibilityGroups();
  const dispatch = useDispatch<Dispatch>();
  const { t } = useTranslation(['Card']);
  const groupSelectedIndex = radioButtons.length - 1;

  useEffect(() => {
    setVisibleTo(visibleToDefault ?? permissionIds.curatorsOnly);
  }, [setVisibleTo, visibleToDefault]);

  const renderLabel = ({
    localizationLabel,
    background,
    border,
    color,
  }: {
    localizationLabel: string;
    border: string;
    background: string;
    color: string;
  }) => {
    return (
      <Box direction="row">
        <Box
          pad={{ horizontal: 'small', vertical: 'xxsmall' }}
          round="xsmall"
          background={background}
          border={{ color: border }}
          margin={{ left: 'xsmall' }}
        >
          <Text color={color} fontWeight="medium">{`${t(
            localizationLabel,
          )}`}</Text>
        </Box>
      </Box>
    );
  };

  const renderCheckBox = ({
    id,
    name,
    checked,
    enabled = true,
  }: {
    id: number;
    name: string;
    checked: boolean;
    enabled: boolean;
  }) => {
    return !Number.isInteger(id) || id < 0 || !name ? null : (
      <Box key={id} margin={{ top: 'small' }} wrap={true}>
        <CheckBox
          name={name}
          label={
            <Text data-test-id={TEST_IDS.bulkEdit.cardPermissions.checkBoxText}>
              {name}
            </Text>
          }
          checked={checked}
          disabled={!enabled}
          onChange={() => dispatch.visibilityGroups.toggleSelectedGroup({ id })}
        />
      </Box>
    );
  };

  return (
    <OptionLayout
      testId={TEST_IDS.bulkEdit.cardPermissions.container}
      title={showTitle ? t('Card:permissions.header.title') : ''}
      body={
        <Box overflow={{ vertical: 'auto' }}>
          {radioButtons.map((rb, index) => (
            <Box key={rb.id} margin={{ bottom: 'xsmall' }} flex={{ shrink: 0 }}>
              <RadioButton
                name={rb.name}
                data-test-id={rb.testId}
                label={renderLabel(rb)}
                checked={index === visibleTo}
                onChange={() => setVisibleTo(index)}
              />
            </Box>
          ))}
          <Box margin={{ top: 'xsmall' }} flex={{ shrink: 0 }}>
            {visibilityGroups.map(({ id, name }) =>
              renderCheckBox({
                id,
                name,
                checked: isSelectedGroup(id),
                enabled: groupSelectedIndex === visibleTo,
              }),
            )}
            {renderCheckBox({
              id: fullAccessUserId,
              name: t('Common:visibilityTypeTitles.fullAccessUsers'),
              checked: true,
              enabled: false,
            })}
          </Box>
        </Box>
      }
    />
  );
};

export default CardPermissions;
