import { APP_BASEURL } from 'lib/urls';

import { api } from '../api';

import type {
  getBlocklistedDomainsResponse,
  updateBlocklistedDomainsParams,
  deleteBlocklistedDomainParams,
} from 'api/endpoints';

export const {
  useGetBlocklistedDomainsQuery,
  useUpdateBlocklistedDomainsMutation,
  useDeleteBlocklistedDomainMutation,
} = api.injectEndpoints({
  endpoints: (build) => ({
    getBlocklistedDomains: build.query<getBlocklistedDomainsResponse, any>({
      query: () => ({
        url: `${APP_BASEURL}/api/source_preference/blocklist.json`,
      }),
    }),
    updateBlocklistedDomains: build.mutation<
      any,
      updateBlocklistedDomainsParams
    >({
      query: (domain) => ({
        url: `${APP_BASEURL}/api/source_preference/blocklist.json`,
        method: 'POST',
        body: domain,
      }),
    }),
    deleteBlocklistedDomain: build.mutation<any, deleteBlocklistedDomainParams>(
      {
        query: ({ domain }) => ({
          url: `${APP_BASEURL}/api/source_preference/blocklist.json?domain=${domain}`,
          method: 'DELETE',
        }),
      },
    ),
  }),
});
