import { setEndpoint } from '../../../configure';

import type {
  SummaryParamsType,
  SummaryQueryType,
  SummaryReturnType,
} from './summary.type';

export const [fetchSummary] = setEndpoint<
  any,
  SummaryParamsType,
  SummaryQueryType,
  SummaryReturnType
>({
  path: '/review_insights/:supportedRivalId/summary/:aspectId',
  id: 'fetchSummary',
  method: 'GET',
  namespace: 'summary',
});
