import { useState, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import type { CardType, ProfileType } from 'api/api.types';
import type { Dispatch, RootState } from 'store';

export default function useCard(cardId: string | undefined | null) {
  const [failed, setFailed] = useState(false);
  const {
    cards: { loadOrFetchCardById },
  } = useDispatch<Dispatch>();

  useEffect(() => {
    setFailed(false);
  }, [cardId]);

  const card = useSelector<RootState, CardType | undefined>((state) => {
    if (!cardId) return undefined;

    return state.cards.byId.get(cardId.toString());
  }, shallowEqual);

  const profile = useSelector<RootState, ProfileType | undefined>((state) => {
    if (!card?.board.profileId) return undefined;

    return state.profiles.byId.get(card?.board.profileId.toString());
  }, shallowEqual);

  const loadCard = useCallback(
    (cardId: number) => {
      const load = async () => {
        try {
          setFailed(false);
          if (!(await loadOrFetchCardById(cardId))) {
            setFailed(true);
          }
        } catch (error) {
          setFailed(true);
        }
      };

      load();
    },
    [loadOrFetchCardById],
  );

  return {
    card,
    profile,
    loadCard,
    failed,
  };
}
