import { Box, Anchor, Text, Paragraph, Icon, tokens } from '@kluein/klue-ui';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import truncate from 'lodash/truncate';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Source } from 'api/endpoints/intel/intel.enums';
import sanitize from 'klue-html/sanitizer';
import { APP_V1_BASEURL } from 'lib/urls';
import TEST_IDS from 'test-ids';

import { getCardSourcesProps } from '../../KlueCard.utils';

import type { KlueCardSourceItemType } from '.';

const StyledQuote = styled(Text)`
  font-style: unset;
  background-color: ${tokens.color.highlight.main};
  font-size: ${(props) => props.theme.text.medium.size};
`;

export default function KlueCardSourceItem(props: KlueCardSourceItemType) {
  const { source, isCardViewerContent = false } = props;
  const { t } = useTranslation();
  const metadataSource = source.metadata.referableMetadata?.name?.toLowerCase();
  const metadataPostTitle = source.metadata.container?.postTitle?.toLowerCase();
  const isSlack =
    metadataSource === Source.Slack || metadataPostTitle === 'slack post';
  const isTeams =
    metadataSource === Source.Teams || metadataPostTitle === 'teams post';

  const isComment = source.referableType === 'Comment';
  const QuoteComponent = isSlack || isTeams || isComment ? StyledQuote : Text;
  const { href, title } = getCardSourcesProps({
    source,
    t,
  });

  const anchorTitle = title ?? t('Card:sources.fallbackSourceTitle');

  if (isCardViewerContent) {
    return (
      <Box
        margin={{ top: 'small' }}
        wrap={true}
        key={source.id}
        background={tokens.color.primaryintel.light}
        pad="medium"
        border={{
          side: 'left',
          size: 'small',
          color: tokens.color['secondary-2'].main,
        }}
        style={{
          borderRadius: '0 3px 3px 0',
        }}
        data-test-id={
          TEST_IDS.klueCard.klueCardSources.item.containerViewerContent
        }
      >
        <Box direction="row" align="center" flex={{ grow: 1 }} gap="small">
          <Text
            size="xsmall"
            className="chromatic-ignore"
            fontWeight="semibold"
          >
            {formatDistanceToNow(
              new Date(source.createdAt),
            ).toLocaleUpperCase()}{' '}
            {t('Common:ago').toLocaleUpperCase()}
          </Text>
          <Box
            width="hair"
            height="xxsmall"
            background={tokens.color.neutral.mediumgrey.main}
          />
          <Anchor
            href={`${APP_V1_BASEURL}/users/${source.userId}`}
            size="small"
            label={source.prettyName}
          />
        </Box>

        <Box margin={{ top: 'small' }}>
          <Box direction="row" align="center" gap="small">
            {isSlack ? (
              <Icon.Slack size="small" color="plain" />
            ) : (
              <Icon.Article
                size="small"
                color={tokens.color.primaryintel.dark}
              />
            )}
            <Anchor
              href={href ?? '#'}
              weight="normal"
              style={{ wordBreak: 'break-word' }}
              title={anchorTitle}
              external
              hideExternalIcon
              label={truncate(anchorTitle, { length: 60 })}
            />
          </Box>
          {!!source.quote && (
            <Box
              pad={{
                top: 'small',
                left: '27px',
                right: 'small',
              }}
            >
              <Paragraph>
                <Text
                  wordBreak={'break-word'}
                  size="medium"
                  dangerouslySetInnerHTML={{
                    __html: truncate(
                      sanitize(source.quote, { FORBID_TAGS: ['div', 'p'] }),
                      {
                        length: 300,
                      },
                    ),
                  }}
                />
              </Paragraph>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box gap="small" pad={{ bottom: 'small' }} wrap={true} key={source.id}>
      <Anchor
        href={href ?? '#'}
        size="large"
        style={{ wordBreak: 'break-word' }}
        title={t('Card:sources.item.titleTooltip')}
        external
        hideExternalIcon
        color={tokens.color.primary.main}
        label={title ?? t('Card:sources.fallbackSourceTitle')}
      />

      {!!source.quote && (
        <Paragraph margin="none" lineHeight="sparse">
          <QuoteComponent
            wordBreak={'break-word'}
            size="medium"
            dangerouslySetInnerHTML={{
              __html: truncate(
                sanitize(source.quote, { FORBID_TAGS: ['div', 'p'] }),
                {
                  length: 300,
                },
              ),
            }}
            as={'em' as any}
          />
        </Paragraph>
      )}

      <Box direction="row" align="center" flex={{ grow: 1 }} gap="xxsmall">
        <Anchor
          href={`${APP_V1_BASEURL}/users/${source.userId}`}
          color={tokens.color.primary.main}
          label={source.prettyName}
        />
        <Text size="small" className="chromatic-ignore">
          {formatDistanceToNow(new Date(source.createdAt))}{' '}
          {t('Common:ago').toLowerCase()}
        </Text>
      </Box>
    </Box>
  );
}
