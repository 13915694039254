import { tokens, theme } from '@kluein/klue-ui';
import { useSelector } from 'react-redux';

import type { CSSProperties } from 'react';
import type { RootState } from 'store';

const hoverBorderMap: Record<string, string> = {
  important: tokens.color.highlight.main,
  interesting: tokens.color.status.success.main,
  archived: tokens.color.primaryintel.main,
  deleted: tokens.color.status.error.main,
};

const boxShadowMap: Record<string, string> = {
  important: '0px 4px 12px 0px rgba(255, 245, 0, 0.25)', // highlight-main
  interesting: '0px 4px 12px 0px rgba(0, 135, 74, 0.25)', // status-success-main
  archived: '0px 4px 12px 0px rgba(55, 81, 255, 0.25)', // primaryintel-main
  deleted: '0px 4px 12px 0px rgba(235, 17, 28, 0.25)', // status-error-main
};

export default function useTriageHover() {
  const triageHover = useSelector<RootState, string>(
    (state) => state.triage.hoverSet,
  );

  const border = !triageHover
    ? ''
    : `${theme.global?.borderSize?.small} solid ${hoverBorderMap[triageHover]}`;
  const margin = !triageHover ? `${theme.global?.borderSize?.small}` : '';
  const boxShadow = !triageHover ? '' : boxShadowMap[triageHover];

  const getHoverStyle = (index: number) => {
    return index > 0
      ? ({
          opacity: '0.4',
          pointerEvents: 'none',
          transition: 'opacity 0.3s ease-in-out',
        } as CSSProperties)
      : ({
          border,
          margin,
          boxShadow,
          transition: 'opacity 0.3s ease-in-out',
        } as CSSProperties);
  };

  return {
    getHoverStyle,
  };
}
