import { Anchor, Box, Image, Text, Tip, tokens } from '@kluein/klue-ui';
import truncate from 'lodash/truncate';
import { useTranslation } from 'react-i18next';

import { useAppUIContext } from 'contexts/ui';
import {
  AnalyticsAction,
  AnalyticsCategory,
  useAnalytics,
} from 'lib/analytics';
import { KLUE_CARD_ZOOM_IMG, KLUE_CARD_NO_ZOOM_IMG } from 'lib/constants';
import TEST_IDS from 'test-ids';

import {
  StyledZoomInIcon,
  StyledOverlay,
  StyledBox,
} from './ImageContent.styles';

import { useCardInteractionLog } from '../../hooks';

import type { ImageContentProps } from './ImageContent.types';

const URL_MAX_LENGTH = 350;

export default function ImageContent({
  children,
  src: imageSrc,
  externalLink,
  zoom,
  logEventLabel,
  cardId,
  rank,
  ...imageProps
}: ImageContentProps) {
  const { t } = useTranslation();
  const { modal } = useAppUIContext();
  const { logEvent } = useAnalytics();
  const logCardInteraction = useCardInteractionLog();

  const { href: linkHref, target: linkTarget = '_blank' } = externalLink || {};

  const handleZoomImage = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.preventDefault();
    event.stopPropagation();

    let zoomId = 'img0';
    const target = event.target as HTMLElement;
    const parent = target?.parentElement?.parentElement;
    const img = parent?.querySelector('img') as HTMLImageElement;
    const index = Array.from(
      img
        ?.closest('[data-card-id]')
        ?.querySelectorAll(`img:not(.${KLUE_CARD_NO_ZOOM_IMG}`) || [],
    ).indexOf(img);

    if (index >= 0) {
      zoomId = `img${index}`;
    }

    modal.open({
      image: imageSrc,
      link: linkHref,
      showCloseButton: true,
      className: `klue-image-modal ${
        linkHref ? 'klue-modal-bottom-link' : undefined
      }`, // TODO: https://github.com/kluein/klue-ui/issues/100
    });

    logEvent({
      event: {
        category: AnalyticsCategory.zoom,
        action: AnalyticsAction.view,
        label: `${logEventLabel} : ${zoomId}`,
      },
    });

    if (cardId) {
      logCardInteraction({
        action: AnalyticsAction.image,
        label: zoomId,
        cardId,
        rank,
      });
    }

    return false;
  };

  const zoomProps = zoom
    ? {
        tabIndex: 0,
        role: 'button',
        onClick: handleZoomImage,
        onKeyDown: (event: React.KeyboardEvent) => {
          if (event.key === 'Enter' || event.key === ' ') {
            return handleZoomImage(event);
          }
        },
      }
    : {};

  return (
    <StyledBox
      as="span"
      data-test-id={TEST_IDS.klueCard.transform.image.container}
      {...zoomProps}
    >
      {linkHref ? (
        children
      ) : (
        <Image
          data-test-id={TEST_IDS.klueCard.transform.image.image}
          {...imageProps}
          src={imageSrc}
        />
      )}
      <StyledOverlay
        as="span"
        className={zoom ? KLUE_CARD_ZOOM_IMG : undefined}
      >
        {zoom && (
          <StyledZoomInIcon
            size="xlarge"
            color={tokens.color.neutral.white.main}
            data-test-id={TEST_IDS.klueCard.transform.image.zoom}
          />
        )}
        {linkHref && (
          <Tip
            variant="small"
            content={
              <Text
                textAlign="start"
                style={{
                  display: 'block',
                  wordBreak: 'break-all',
                }}
              >
                {/* TODO truncation should reveal filename / extension */}
                {truncate(linkHref, { length: URL_MAX_LENGTH })}
              </Text>
            }
            dropProps={{ align: { top: 'bottom' } }}
          >
            <Box
              as="span"
              background={tokens.color.neutral.black.main}
              pad="xsmall"
              round="medium"
              direction="row"
              align="center"
              justify="center"
              style={{
                position: 'absolute',
                bottom: '8px',
              }}
            >
              <Anchor
                variant="neutral-inverted"
                href={linkHref}
                onClick={(event) => event.stopPropagation()}
                external={linkTarget === '_blank'}
                target={linkTarget}
                label={t('Card:followLink')}
                data-test-id={TEST_IDS.klueCard.transform.image.link}
              />
            </Box>
          </Tip>
        )}
      </StyledOverlay>
    </StyledBox>
  );
}
