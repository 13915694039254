import {
  Box,
  Button,
  Text,
  Heading,
  Select,
  Skeleton,
  tokens,
  type SelectItemProps,
} from '@kluein/klue-ui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BulkEditOption } from 'contexts/ui';
import { useRedirect } from 'hooks/use-redirect';
import TEST_IDS from 'test-ids';

import BulkEditCompletion from './BulkEditCompletion';
import CardPermissions from './cardPermissions/CardPermissions';
import CardDelete from './delete/CardDelete';
import useBulkEditor from './hooks/useBulkEditor';
import CardTags from './tags/CardTags';

const BULK_EDIT_OPTION_TRANSLATION_KEYS = {
  [BulkEditOption.CardPermissions]: {
    label: 'Card:permissions.actionTitle',
    successMessage: 'Card:permissions.permissionsUpdated',
    failureMessage: 'Card:permissions.permissionsFailureMessage',
  },
  [BulkEditOption.Tags]: {
    label: 'Card:tags.actionTitle',
    successMessage: 'Card:tags.tagsUpdated',
    failureMessage: 'Card:tags.tagsFailureMessage',
  },
  [BulkEditOption.Delete]: {
    label: 'Card:delete.label',
    successMessage: 'Card:delete.success',
    failureMessage: 'Card:delete.failure',
  },
};

const BulkEdit = () => {
  const { t } = useTranslation(['Common', 'Card']);
  const {
    disabled,
    loading,
    processing,
    success,
    error,
    options,
    option,
    count,
    setOption,
    apply,
    deselectAllCards,
    reset,
  } = useBulkEditor();

  const failureMessage =
    option &&
    error &&
    t(BULK_EDIT_OPTION_TRANSLATION_KEYS[option].failureMessage);

  const parsedOptions = options.map((item) => {
    return {
      label: t(BULK_EDIT_OPTION_TRANSLATION_KEYS[item].label),
      value: item,
    };
  });

  const handleSelectOption = (option: BulkEditOption) => setOption(option);
  const { redirectToV1Profile } = useRedirect();

  useEffect(() => {
    if (failureMessage) {
      if (
        // eslint-disable-next-line no-restricted-globals
        confirm(failureMessage)
      ) {
        window.location.reload();
      }
    }
  }, [failureMessage]);

  const applyLabel = t('Common:actions.apply');
  const cancelLabel = t('Common:actions.cancel');

  if (!option) {
    return null;
  }

  if (loading) {
    return (
      <Box
        pad="large"
        gap="xlarge"
        data-test-id={TEST_IDS.bulkEdit.loadingSkeleton}
      >
        <Box
          height="50px"
          fill="horizontal"
          background={tokens.color.neutral.rosegrey.main}
        />
        <Skeleton height="650px" animated={true} />
      </Box>
    );
  }

  return (
    <>
      <Box
        pad={tokens.spacing.large}
        fill
        direction="column"
        data-test-id={TEST_IDS.bulkEdit.container}
      >
        <Heading
          margin={{ bottom: 'none' }}
          data-test-id={TEST_IDS.bulkEdit.headerTitle}
          level="2"
        >
          {t('Card:permissions.bulkEditTitle')}
        </Heading>
        <Box
          direction="row"
          justify="between"
          align="center"
          margin={{ bottom: 'small' }}
        >
          <Text data-test-id={TEST_IDS.bulkEdit.selectedCards}>
            {t('Card:cardsSelectedCount', { count })}
          </Text>
          <Button
            size="small"
            disabled={!count}
            title={t('Common:actions.deselectAll')}
            label={t('Common:actions.deselectAll')}
            variant="secondary-form"
            onClick={deselectAllCards}
            data-test-id={TEST_IDS.bulkEdit.deselectAllButton}
          />
        </Box>
        <Box width="100%" data-test-id={TEST_IDS.bulkEdit.select}>
          <Select
            options={parsedOptions}
            size="small"
            onSelected={(item) =>
              handleSelectOption(
                (item as SelectItemProps).value as BulkEditOption,
              )
            }
            labelKey={option}
            value={option}
            plain
          />
        </Box>
        {option === BulkEditOption.CardPermissions && <CardPermissions />}
        {option === BulkEditOption.Tags && <CardTags />}
        {option === BulkEditOption.Delete && <CardDelete />}
        <Box direction="row" margin={{ top: 'large' }}>
          <Button
            data-test-id={TEST_IDS.bulkEdit.cancelButton}
            variant="secondary-form"
            title={cancelLabel}
            label={cancelLabel}
            disabled={success || processing}
            onClick={() => redirectToV1Profile()}
          />
          <Button
            variant="secondary-form"
            data-test-id={TEST_IDS.bulkEdit.completeButton}
            margin={{ left: 'small' }}
            title={applyLabel}
            label={applyLabel}
            loading={processing}
            // disable the Complete button if nothing selected or we're processing.
            // We may still decide if we want to send bulk edits to the backend in smaller jobs
            // but for now we'll let a job complete before allowing a subsequent bulk edit.
            // This prevents the need to handle async job ordering etc.for the time being.
            disabled={disabled}
            onClick={apply}
          />
        </Box>
      </Box>
      {success && (
        <BulkEditCompletion
          title={t(BULK_EDIT_OPTION_TRANSLATION_KEYS[option].successMessage, {
            count,
          })}
          onAllDone={() => redirectToV1Profile()}
          onContinueEditing={reset}
        />
      )}
    </>
  );
};

export default BulkEdit;
