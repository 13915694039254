import { useDispatch } from 'react-redux';

import { deleteCardsWithParams } from '../BulkEdit.utils';

import type { Dispatch } from 'store';

const useBulkEditDelete = (_selectedCards: Set<number>) => {
  const dispatch = useDispatch<Dispatch>();

  return {
    handleApply: async () => {
      await deleteCardsWithParams({
        cardIds: [..._selectedCards],
        onCardDeleted: () => {
          dispatch.cards.bulkDeleteCards(null);
        },
      });
    },
    disabled: false,
  };
};

export default useBulkEditDelete;
