import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams, createEnumParam } from 'use-query-params';

import type { Dispatch, RootState } from 'store';

type TagsOpType = (typeof TAGS_OP_VALUES)[number];

const TAGS_OP_VALUES = ['add', 'remove'] as const;
const QueryParamConfig = {
  tagsOp: createEnumParam([...TAGS_OP_VALUES]),
};

const useBulkEditTags = (_selectedCards: Set<number>) => {
  const dispatch = useDispatch<Dispatch>();
  const disabled = useSelector<RootState, boolean>(
    (state) => !state.tags.selectedTags.size,
  );
  const [{ tagsOp }] = useQueryParams(QueryParamConfig);

  const dispatchAction = async (action: TagsOpType) => {
    if (action === 'remove') {
      return await dispatch.cards.bulkRemoveTags(null);
    }
    return await dispatch.cards.bulkAddTags(null);
  };

  return {
    handleApply: async () => {
      if (disabled) return;

      const result = await dispatchAction(tagsOp as TagsOpType);

      if (!result) {
        throw new Error('Bulk tagging failed');
      }

      // TODO: Should we handle cases if the param is not present and somehow the user click to apply
    },
    disabled,
    reset: dispatch.tags.deselectAll,
  };
};

export default useBulkEditTags;
