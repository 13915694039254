import { createModel } from '@rematch/core';

import { mergeArrayValues } from 'store/utils';

import type {
  SectionContainerType,
  SectionType,
  PriorityMapType,
} from './triage.model.types';
import type { RootModel } from '..';
import type {
  IntelListItemType,
  UpdateIntelBodyType,
  Topic,
  State,
} from 'api/endpoints';

export type StateType = {
  hoverSet: string;
  byId: Map<string, IntelListItemType>;
  priorities: {
    interesting: Set<string>;
    important: Set<string>;
  };
  states: {
    new: Set<string>;
    unprocessed: Set<string>;
    working: Set<string>;
    archived: Set<string>;
    deleted: Set<string>;
  };
  triageCardIds: string[];
  actionedCardIds: string[];
};

export const initialState = {
  hoverSet: '',
  byId: new Map(),
  priorities: {
    interesting: new Set(),
    important: new Set(),
  },
  states: {
    new: new Set(),
    unprocessed: new Set(),
    working: new Set(),
    archived: new Set(),
    deleted: new Set(),
  },
  triageCardIds: [],
  actionedCardIds: [],
} as StateType;

export const triage = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updateHoverSet: (state: StateType, triageHover: string) => {
      return {
        ...state,
        hoverSet: triageHover,
      };
    },
    addToPriorities: (
      rootState: StateType,
      {
        card,
        priority,
      }: { card?: IntelListItemType; priority: PriorityMapType },
    ) => {
      return {
        ...rootState,
        byId: mergeArrayValues(rootState.byId, [card], 'id'),
        priorities: {
          ...rootState.priorities,
          [priority]: new Set([...rootState.priorities[priority], card?.id]),
        },
      };
    },
    addToStates: (
      rootState: StateType,
      { card, state }: { card?: IntelListItemType; state: State },
    ) => {
      return {
        ...rootState,
        byId: mergeArrayValues(rootState.byId, [card], 'id'),

        states: {
          ...rootState.states,
          [state]: new Set([...rootState.states[state], card?.id]),
        },
      };
    },
    clearSection: (
      state: StateType,
      {
        type,
        section,
      }: {
        type: SectionContainerType;
        section: SectionType;
      },
    ) => {
      return {
        ...state,
        [type]: {
          ...state[type],
          [section]: new Map(),
        },
      };
    },
    resetTriageMode: () => {
      return initialState;
    },
    setTriageCardIds: (state, cardIds: string[]) => {
      return {
        ...state,
        triageCardIds: cardIds.filter(
          (cardId) => !state.actionedCardIds.includes(cardId),
        ),
      };
    },
    removeCardFromStateList: (state, cardId: string) => {
      const currentState = state.byId.get(cardId)?.state;

      if (!currentState) return state;

      const newStateList = new Set(state.states[currentState]);
      newStateList.delete(cardId);

      return {
        ...state,
        states: {
          ...state.states,
          [currentState]: newStateList,
        },
      };
    },
    removeCardFromPriorityList: (state, cardId: string) => {
      const currentPriority = state.byId.get(cardId)?.priority;

      if (!currentPriority) return state;

      const newPriorityList = new Set(state.priorities[currentPriority]);
      newPriorityList.delete(cardId);

      return {
        ...state,
        priorities: {
          ...state.priorities,
          [currentPriority]: newPriorityList,
        },
      };
    },
    removeTriageCard: (state, cardId: string) => {
      const cards = state.triageCardIds.filter((id) => id !== cardId);
      return {
        ...state,
        triageCardIds: cards,
        actionedCardIds: state.actionedCardIds.includes(cardId)
          ? state.actionedCardIds
          : [...state.actionedCardIds, cardId],
      };
    },
    retrievePreviousCard: (state) => {
      const previousCardId = state.actionedCardIds.pop();

      return {
        ...state,
        triageCardIds: previousCardId
          ? [previousCardId, ...state.triageCardIds]
          : state.triageCardIds,
        actionedCardIds: state.actionedCardIds,
      };
    },
    updateTriageCard: (
      rootState: StateType,
      intelId: string,
      data: Partial<UpdateIntelBodyType>,
    ) => {
      const card = rootState.byId.get(intelId);
      const updatedCard = { ...card, ...data };

      return {
        ...rootState,
        byId: mergeArrayValues(rootState.byId, [updatedCard], 'id'),
      };
    },
    populateTags: (state, { topics }: { topics: Topic[] }) => {
      return {
        ...state,
        topics: {
          byId: topics,
          allIds: new Set([...topics.keys()]),
        },
      };
    },
  },
  selectors: (slice, createSelector) => ({
    interesting() {
      return slice(({ priorities: { interesting } }) => interesting);
    },
    getInterestingCount() {
      return createSelector(
        this.interesting as any,
        (interesting: StateType['priorities']['interesting']) =>
          interesting.size,
      );
    },
    important() {
      return slice(({ priorities: { important } }) => important);
    },
    getImportantCount() {
      return createSelector(
        this.important as any,
        (important: StateType['priorities']['important']) => important.size,
      );
    },
    deleted() {
      return slice(({ states: { deleted } }) => deleted);
    },
    getDeletedCount() {
      return createSelector(
        this.deleted as any,
        (deleted: StateType['states']['deleted']) => deleted.size,
      );
    },
    getActionedIntelCount() {
      return slice(({ actionedCardIds }) => actionedCardIds.length);
    },
    getActionedCardsIds() {
      return slice(({ actionedCardIds }) => actionedCardIds);
    },
    archived() {
      return slice(({ states: { archived } }) => archived);
    },
    getArchivedCount() {
      return createSelector(
        this.archived as any,
        (archived: StateType['states']['archived']) => archived.size,
      );
    },
    cardIds() {
      return slice(({ triageCardIds }) => triageCardIds);
    },
  }),
  effects: () => ({}), // prevent TS error
});
