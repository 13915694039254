import type { formulaToKeyMappings } from './constants';

export enum NodeType {
  html = 'html',
  formula = 'formula',
}

export enum Formulas {
  keyPeople = 'keyPeople',
  balanceSheetStatements = 'balanceSheetStatements',
  balanceSheetStatementsQuarterly = 'balanceSheetStatementsQuarterly',
  cashFlowStatements = 'cashFlowStatements',
  cashFlowStatementsQuarterly = 'cashFlowStatementsQuarterly',
  compareEmployeeCount = 'compareEmployeeCount',
  employeeCount = 'employeeCount',
  employeeGrowth = 'employeeCount',
  homepageScreenshot = 'homepageScreenshot',
  operatingMetric = 'operatingMetric',
  incomeStatements = 'incomeStatements',
  incomeStatementsQuarterly = 'incomeStatementsQuarterly',
  jobCategories = 'jobCategories',
  jobPostings = 'jobPostings',
  jobPostingsByRegion = 'jobPostingsByRegion',
  officeLocations = 'officeLocations',
  operatingMetricsSummary = 'operatingMetricsSummary',
  revenues = 'revenues',
  compareRevenues = 'compareRevenues',
  similarWebsites = 'similarWebsites',
  engagementSummary = 'engagementSummary',
  websiteTrafficOrganicKeywords = 'websiteTrafficOrganicKeywords',
  trafficPageViews = 'trafficPageViews',
  websiteTrafficPaidKeywords = 'websiteTrafficPaidKeywords',
  trafficSources = 'trafficSources',
  twitterFollowers = 'twitterFollowers',
  recentPressReleases = 'recentPressReleases',
  recentBlogPosts = 'recentBlogPosts',
  recentSocialUpdates = 'recentSocialUpdates',
  recentVideos = 'recentVideos',
  recentCaseStudies = 'recentCaseStudies',
  recentPublications = 'recentPublications',
  companyOverview = 'companyOverview',
  sfdcWins = 'sfdcWins',
  sfdcLatestOpportunities = 'sfdcLatestOpportunities',
}

export type FormulaFromApiKeys = keyof typeof formulaToKeyMappings;

export type Formula = {
  formulaType: Formulas;
  companies: Array<string>;
  cardId?: number;
  type?: 'static' | 'rival' | 'domain';
  extra?: {
    sfdcQueryType?: string;
    profileId: number;
  };
};

export type TextJson = {
  type: NodeType;
  data: string | Formula;
};
