/**
 * Script adapted from Intercom dashboard
 * @param {string} appId
 */
export default function loadIntercom(appId) {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${appId}`;
    var x = d.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }
}
