import {
  Icon,
  type ListItemType,
  Dialog,
  DialogType,
  NotifierType,
  useNotifier,
  ProgressBarState,
} from '@kluein/klue-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useAuth } from 'contexts/auth';
import { useCardViewer } from 'hooks/use-card-viewer';
import { useRedirect } from 'hooks/use-redirect';
import { AnalyticsAction, type AnalyticsActionType } from 'lib/analytics';
import { useDeleteCardMutation } from 'store/api/cards/cards.api';

import { useCardInteractionLog } from '../../hooks';

import type {
  KlueCardMenuItemProps,
  KlueCardMenuProps,
  KlueCardSnowPlowLabelType,
} from './KlueCardMenu.types';

function KlueCardMenu(props: KlueCardMenuProps) {
  const {
    sourcesCount,
    cardId,
    profileId,
    children,
    trackingIds,
    isEditable = false,
    hideOpenCard = false,
    hideCardSources = false,
    rank,
  } = props;
  const { redirectToCardEditor } = useRedirect();
  const { t } = useTranslation();
  const { open } = useCardViewer();
  const { isCurator, isNewCardEditorEnabled } = useAuth();
  const [isDeletingCard, setIsDeletingCard] = useState(false);
  const hasCardSources = !!sourcesCount;

  const menuItemsObj: {
    openCard: KlueCardMenuItemProps;
    sources: KlueCardMenuItemProps;
    edit: KlueCardMenuItemProps;
    delete: KlueCardMenuItemProps;
  } = {
    openCard: {
      key: 'open-card',
      label: t('Card:header.menu.openCard'),
      icon: <Icon.OpenAction />,
    },
    sources: {
      key: 'sources',
      label: t('Card:header.menu.sources'),
      icon: <Icon.CircleInformation />,
    },
    edit: {
      key: 'edit',
      label: t('Common:actions.edit'),
      icon: <Icon.Edit />,
    },
    delete: {
      key: 'delete',
      label: t('Common:actions.delete'),
      icon: <Icon.Trash />,
    },
  };
  if (trackingIds) {
    menuItemsObj.openCard.trackingId = trackingIds.openCard;
    menuItemsObj.sources.trackingId = trackingIds.sources;
    menuItemsObj.edit.trackingId = trackingIds.edit;
  }

  const menuItems = [];
  if (!hideOpenCard) {
    menuItems.push(menuItemsObj.openCard);
  }

  if (!hideCardSources && hasCardSources) {
    menuItems.push(menuItemsObj.sources);
  }

  if (isEditable && isCurator) {
    menuItems.push(menuItemsObj.edit);
  }

  if (isEditable && isCurator && isNewCardEditorEnabled) {
    menuItems.push(menuItemsObj.delete);
  }

  const logCardInteraction = useCardInteractionLog();
  const handleClickMenuItem = (
    item: ListItemType,
    snowplowLabel: KlueCardSnowPlowLabelType,
  ) => {
    if (cardId) {
      const options: { scrollToSources?: boolean; rank?: number | null } = {
        rank,
      };
      let action: AnalyticsActionType = AnalyticsAction.expand;
      if (item.key === 'sources') {
        options.scrollToSources = true;
        action = AnalyticsAction.viewSources;
      }
      if (item.key === 'edit') {
        action = AnalyticsAction.edit;
        logCardInteraction({
          cardId,
          action,
          label: snowplowLabel,
          rank,
        });
        return profileId && redirectToCardEditor({ profileId, cardId });
      }

      if (item.key === 'delete') {
        logCardInteraction({
          cardId,
          action: AnalyticsAction.removeCard,
          label: snowplowLabel,
          rank,
        });

        setIsDeletingCard(true);

        return;
      }

      open(cardId, options);
      logCardInteraction({
        cardId,
        action,
        label: snowplowLabel,
        rank,
      });
    }
  };

  return (
    <>
      {isDeletingCard && cardId && (
        <CardDeleteConfirmation
          cardId={cardId}
          onClose={() => setIsDeletingCard(false)}
        />
      )}
      {children({ menuItems, handleClickMenuItem })}
    </>
  );
}

type CardDeleteConfirmationProps = {
  cardId: number;
  onClose: () => void;
};

const CardDeleteConfirmation = ({
  cardId,
  onClose,
}: CardDeleteConfirmationProps) => {
  const { t } = useTranslation(['Card']);
  const [deleteCard, deleteState] = useDeleteCardMutation();
  const isDeleting = deleteState.isLoading;
  const { notify } = useNotifier();
  const dispatch = useDispatch();

  function handleSubmit() {
    deleteCard(cardId)
      .unwrap()
      .then(() => {
        dispatch.boards.deleteCards({ cardIds: [cardId] });
        dispatch.cards.delete({ cardIds: [cardId] });
        return notify({
          type: NotifierType.SUCCESS,
          message: t('Card:deleteCard.success'),
          icon: <Icon.CircleAlert />,
        });
      })
      .catch((error) => {
        return notify({
          type: NotifierType.ERROR,
          message: t('Card:deleteCard.failure'),
          icon: <Icon.CircleAlert />,
        });
      })
      .finally(() => {
        onClose();
      });
  }

  return (
    <Dialog
      title={t('Card:deleteCard.confirmation.heading')}
      description={t(
        `Card:deleteCard.confirmation.${isDeleting ? 'deleting' : 'body'}`,
      )}
      showCancelButton={!isDeleting}
      show
      progressBarProps={
        isDeleting
          ? {
              hideLabel: false,
              min: 0,
              max: 100,
              value: 50,
              state: ProgressBarState.ACTIVE,
              a11yTitle: '',
            }
          : undefined
      }
      showActionButton={!isDeleting}
      action={handleSubmit}
      actionLabel={t('Card:deleteCard.confirmation.confirmButton')}
      position="center"
      onClose={onClose}
      type={DialogType.WARNING}
    />
  );
};

export default KlueCardMenu;
